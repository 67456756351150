var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isWelcome
        ? _c("on-boarding-welcome", {
            on: { next: _vm.handleNextClickFromWelcome },
          })
        : _vm._e(),
      _vm.isAboutThem
        ? _c("on-boarding-about-them", {
            on: { next: _vm.handleNextClickFromAboutThem },
          })
        : _vm._e(),
      _vm.isTimeZone
        ? _c("time-zone-component", {
            on: { next: _vm.handleNextClickFromTimeZone },
          })
        : _vm._e(),
      _vm.isScheduleVideo
        ? _c("first-event-registration-video-component", {
            on: { next: _vm.handleNextClickFromFirstScheduleVideo },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }