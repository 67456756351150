import { getModule } from 'vuex-module-decorators';
import { Vendor, VendorItemResponse, VendorListResponse } from '@fgl/funfangle-sdk/dist/rest/v3/profile/vendor';
import { ApiEndpoints } from '@/constants';
import { ApiService } from '@/shared/auth/auth-cognito';
import { ProfileModule } from '@/store';
import { store } from '../../store/Store';
import { ApiClient } from '.';

const apiSvc = new ApiService();

export class ProfileVendorApiClient extends ApiClient {
  public profileStore: ProfileModule = getModule(ProfileModule, store);

  //
  //
  //

  async getVendor(organizationId: string | undefined, vendorId: string | undefined): Promise<VendorItemResponse> {
    const orgId = organizationId || this.profileStore.currOrganization.organizationId;
    if (orgId === undefined) return Promise.reject(new Error('No organization ID provided'));
    let url = `${ApiEndpoints.apiEndpoint}/v3/profile/vendor?orgid=${encodeURIComponent(orgId)}`;
    if (vendorId) url = `${url}&purchase_id=${encodeURIComponent(vendorId)}`;
    let res: VendorItemResponse | undefined;
    // this.context.dispatch
    await apiSvc
      .httpGet(url)
      .then((response: VendorItemResponse) => {
        res = response;
      })
      .catch((err: Error) => {
        return Promise.reject(err);
      });
    if (res === undefined) {
      return Promise.reject(new Error('No results returned.'));
    }
    return Promise.resolve(res);
  }

  async listVendors(organizationId?: string): Promise<VendorListResponse> {
    const orgId = organizationId || this.profileStore.currOrganization.organizationId;
    if (orgId === undefined) return Promise.reject(new Error('No organization ID provided'));
    const url = `${ApiEndpoints.apiEndpoint}/v3/profile/vendors?orgid=${encodeURIComponent(orgId)}`;

    let res: VendorListResponse | undefined;
    await apiSvc
      .httpGet(url)
      .then((response: VendorListResponse) => {
        res = response;
      })
      .catch((err: Error) => {
        return Promise.reject(err);
      });
    if (res === undefined) {
      return Promise.reject(new Error('No results returned.'));
    }
    return Promise.resolve(res);
  }

  async insertVendor(vendor: Vendor): Promise<VendorItemResponse> {
    return this.upsertVendor(vendor);
  }

  async updateVendor(vendor: Vendor): Promise<VendorItemResponse> {
    return this.upsertVendor(vendor);
  }

  async upsertVendor(vendor: Vendor): Promise<VendorItemResponse> {
    let res: VendorItemResponse | undefined;
    await apiSvc
      .httpPost(`${ApiEndpoints.apiEndpoint}/v3/profile/vendor`, vendor)
      .then((response) => {
        res = response;
      })
      .catch((err: Error) => {
        return Promise.reject(err);
      });
    if (res === undefined) {
      return Promise.reject(new Error('No results returned.'));
    }
    return Promise.resolve(res);
  }
}
