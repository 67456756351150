import { getModule } from 'vuex-module-decorators';
import { TransactionSearchParams, TransactionsResponse } from '@fgl/funfangle-sdk/dist/rest/bank';
import { ApiEndpoints } from '@/constants';
import { store, ProfileModule } from '../../store';
import { ApiClient } from '.';
// import { InvoiceResponse, InvoicesResponse } from '@fgl/funfangle-sdk/dist/rest/bank';
// import { InvoicePaymentRequest } from '@fgl/funfangle-sdk/dist/rest/bank/payment';
// import { UserRequest } from '@fgl/funfangle-sdk/dist/rest/profile';
export class BankTransactionApiClient extends ApiClient {
  public profileStore: ProfileModule = getModule(ProfileModule, store);

  async getTransactions(params?: TransactionSearchParams): Promise<TransactionsResponse> {
    let url = `${ApiEndpoints.apiEndpoint}/v2/bank/transaction/list?organizationId=${params?.organizationId || this.profileStore.currOrganization.organizationId}`;
    if (params?.loweriso !== undefined) url = `${url}&loweriso=${params?.loweriso}`;
    if (params?.upperiso !== undefined) url = `${url}&upperiso=${params?.upperiso}`;
    const apiSvc = new ApiClient();
    return apiSvc.httpGet(url);
  }

  async searchTransactions(params?: TransactionSearchParams): Promise<TransactionsResponse> {
    let url = `${ApiEndpoints.apiEndpoint}/v2/bank/transaction/search?organizationId=${params?.organizationId || this.profileStore.currOrganization.organizationId}`;
    if (params?.amount !== undefined) url = `${url}&amount=${params?.amount}`;
    if (params?.email !== undefined) url = `${url}&email=${params?.email}`;
    if (params?.last4 !== undefined) url = `${url}&last4=${params?.last4}`;
    if (params?.loweriso !== undefined) url = `${url}&loweriso=${params?.loweriso}`;
    if (params?.name !== undefined) url = `${url}&name=${params?.name}`;
    if (params?.upperiso !== undefined) url = `${url}&upperiso=${params?.upperiso}`;
    const apiSvc = new ApiClient();
    return apiSvc.httpGet(url);
  }
}
