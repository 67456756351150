// import { ApiEndpoints } from '../constants';
import { store } from '@/store/Store';
// import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Module, VuexModule } from 'vuex-module-decorators';
// import { Contributor, User, UserWaiverScopeType } from '@fgl/funfangle-sdk/dist/rest/profile';
// import { ApiService } from '@/shared/auth/auth-cognito';
import { getModule } from 'vuex-module-decorators';
import { NavModule } from './NavModule';
import { ProfileModule } from './ProfileModule';
// import { RouterUtils } from '@/router/RouterUtils';

// const apiSvc = new ApiService();

@Module({ dynamic: true, store, name: 'lingo' })
export class LingoModule extends VuexModule {
  profileStore: ProfileModule = getModule(ProfileModule);
  navStore: NavModule = getModule(NavModule);

  get add_renter_stay(): string {
    if (this.profileStore.organizationId === 'prod-dup-34a2-4361-9a62-27db40b8e0f0' || this.profileStore.organizationId === '0b9c4c63-34a2-4361-9a62-27db40b8e0f0') {
      return 'Add Tenant Stay';
    }
    return 'Add Renter Stay';
  }

  get edit_renter_stay(): string {
    if (this.profileStore.organizationId === 'prod-dup-34a2-4361-9a62-27db40b8e0f0' || this.profileStore.organizationId === '0b9c4c63-34a2-4361-9a62-27db40b8e0f0') {
      return 'Edit Tenant Stay';
    }
    return 'Edit Renter Stay';
  }

  get renter(): string {
    if (this.profileStore.organizationId === 'prod-dup-34a2-4361-9a62-27db40b8e0f0' || this.profileStore.organizationId === '0b9c4c63-34a2-4361-9a62-27db40b8e0f0') {
      return 'Tenant';
    }

    return 'Renter';
  }

  get renter_email(): string {
    if (this.profileStore.organizationId === 'prod-dup-34a2-4361-9a62-27db40b8e0f0' || this.profileStore.organizationId === '0b9c4c63-34a2-4361-9a62-27db40b8e0f0') {
      return 'Tenant Email';
    }
    return 'Renter Email';
  }

  get renters(): string {
    if (this.profileStore.organizationId === 'prod-dup-34a2-4361-9a62-27db40b8e0f0' || this.profileStore.organizationId === '0b9c4c63-34a2-4361-9a62-27db40b8e0f0') {
      return 'Tenants';
    }

    return 'Renters';
  }

  get renter_stays(): string {
    if (this.profileStore.organizationId === 'prod-dup-34a2-4361-9a62-27db40b8e0f0' || this.profileStore.organizationId === '0b9c4c63-34a2-4361-9a62-27db40b8e0f0') {
      return 'Tenant Stays';
    }
    return 'Renter Stays';
  }
}
