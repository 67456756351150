// src/plugins/vuetify.js

import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import { Iconfont } from 'vuetify/types/services/icons';
// import { Intersect } from 'vuetify/es5/directives';

Vue.use(Vuetify);

const iconfont: Iconfont = 'md';
const opts = {
  // directives: {
  //   Intersect,
  // },
  icons: {
    iconfont,
  },
};

export default new Vuetify(opts);
