import { ApiEndpoints } from '@/constants';
import { ApiService } from '@/shared/auth/auth-cognito';
import { ProfileModule } from '@/store';
import { store } from '../../store/Store';
import {
  Inventory,
  InventoryCodeListResponse,
  InventoryDetailListResponse,
  InventoryItemResponse,
  InventoryListResponse,
  InventoryLot,
  InventoryLotItemResponse,
  InventoryLotListResponse,
  InventoryPurchaseDetail,
  InventoryPurchaseDetailItemResponse,
  InventoryPurchaseDetailListResponse,
  InventoryPurchaseListResponse,
  InventoryPurchaseOrder,
  InventoryPurchaseOrderItemResponse,
} from '@fgl/funfangle-sdk/dist/rest/v3/inventory';
import { getModule } from 'vuex-module-decorators';
import { ApiClient } from '.';

const apiSvc = new ApiService();

export class InventoryApiClient extends ApiClient {
  public profileStore: ProfileModule = getModule(ProfileModule, store);

  //
  // Codes
  //

  async listCodes(organizationId?: string, itemId?: string | null, modifierKey?: string | null): Promise<InventoryCodeListResponse> {
    const orgId = organizationId || this.profileStore.currOrganization.organizationId;
    if (orgId === undefined) return Promise.reject(new Error('No organization ID provided'));
    let url = `${ApiEndpoints.apiEndpoint}/v3/inventory/codes?orgid=${encodeURIComponent(orgId)}`;
    if (itemId !== undefined) url = url + '&item_id=' + itemId;
    if (modifierKey !== undefined) url = url + '&modifier_key=' + modifierKey;

    let res: InventoryCodeListResponse | undefined;
    // this.context.dispatch
    await apiSvc
      .httpGet(url)
      .then((response: InventoryCodeListResponse) => {
        res = response;
      })
      .catch((err: Error) => {
        return Promise.reject(err);
      });
    if (res === undefined) {
      return Promise.reject(new Error('No results returned.'));
    }
    return Promise.resolve(res);
  }

  //
  //
  //

  async listDetails(organizationId?: string, itemId?: string | null, locationId?: string | null): Promise<InventoryDetailListResponse> {
    const orgId = organizationId || this.profileStore.currOrganization.organizationId;
    if (orgId === undefined) return Promise.reject(new Error('No organization ID provided'));
    let url = `${ApiEndpoints.apiEndpoint}/v3/inventory/details?orgid=${encodeURIComponent(orgId)}`;
    if (itemId) url = `${url}&item_id=${encodeURIComponent(itemId)}`;
    if (locationId) url = `${url}&location_id=${encodeURIComponent(locationId)}`;

    let res: InventoryDetailListResponse | undefined;
    await apiSvc
      .httpGet(url)
      .then((response: InventoryDetailListResponse) => {
        res = response;
      })
      .catch((err: Error) => {
        return Promise.reject(err);
      });
    if (res === undefined) {
      return Promise.reject(new Error('No results returned.'));
    }
    return Promise.resolve(res);
  }

  //
  //
  //

  async listHistories(organizationId?: string, itemId?: string | null, modifierKey?: string | null, timestamp?: number | string | null): Promise<InventoryLotListResponse> {
    const orgId = organizationId || this.profileStore.currOrganization.organizationId;
    if (orgId === undefined) return Promise.reject(new Error('No organization ID provided'));
    let url = `${ApiEndpoints.apiEndpoint}/v3/inventory/histories?orgid=${encodeURIComponent(orgId)}`;
    if (itemId) url = `${url}&item_id=${encodeURIComponent(itemId)}`;
    if (modifierKey) url = `${url}&modifier_key=${encodeURIComponent(modifierKey)}`;
    if (timestamp) url = `${url}&timestamp=${encodeURIComponent(timestamp)}`;
    // if (purchaseId) url = `${url}&purchase_id=${purchaseId}`;
    let res: InventoryLotListResponse | undefined;
    // this.context.dispatch
    await apiSvc
      .httpGet(url)
      .then((response: InventoryLotListResponse) => {
        res = response;
      })
      .catch((err: Error) => {
        return Promise.reject(err);
      });
    if (res === undefined) {
      return Promise.reject(new Error('No results returned.'));
    }
    return Promise.resolve(res);
  }

  //
  //
  //

  async insertItem(item: Inventory): Promise<InventoryItemResponse> {
    let res: InventoryItemResponse | undefined;
    await apiSvc
      .httpPost(`${ApiEndpoints.apiEndpoint}/v3/inventory/item/insert`, item)
      .then((response) => {
        res = response;
      })
      .catch((err: Error) => {
        return Promise.reject(err);
      });
    if (res === undefined) {
      return Promise.reject(new Error('No results returned.'));
    }
    return Promise.resolve(res);
  }

  async getItem(organizationId: string | null | undefined, itemId: string | null | undefined): Promise<InventoryItemResponse> {
    const orgId = organizationId || this.profileStore.currOrganization.organizationId;
    if (orgId === undefined) return Promise.reject(new Error('No organization ID provided'));
    let url = `${ApiEndpoints.apiEndpoint}/v3/inventory/item?orgid=${encodeURIComponent(orgId)}`;
    if (itemId) url = `${url}&item_id=${encodeURIComponent(itemId)}`;
    // if (modifierKey) url = `${url}&modifier_key=${encodeURIComponent(modifierKey)}`;
    let res: InventoryItemResponse | undefined;
    // this.context.dispatch
    await apiSvc
      .httpGet(url)
      .then((response: InventoryItemResponse) => {
        res = response;
      })
      .catch((err: Error) => {
        return Promise.reject(err);
      });
    if (res === undefined) {
      return Promise.reject(new Error('No results returned.'));
    }
    return Promise.resolve(res);
  }

  async listItems(organizationId?: string): Promise<InventoryListResponse> {
    const orgId = organizationId || this.profileStore.currOrganization.organizationId;
    if (orgId === undefined) return Promise.reject(new Error('No organization ID provided'));
    const url = `${ApiEndpoints.apiEndpoint}/v3/inventory/items?orgid=${encodeURIComponent(orgId)}`;
    // if (itemId) url = `${url}&item_id=${encodeURIComponent(itemId)}`;
    // if (modifierKey) url = `${url}&modifier_key=${encodeURIComponent(modifierKey)}`;
    // if (timestamp) url = `${url}&timestamp=${encodeURIComponent(timestamp)}`;
    // if (purchaseId) url = `${url}&purchase_id=${purchaseId}`;
    let res: InventoryListResponse | undefined;
    // this.context.dispatch
    await apiSvc
      .httpGet(url)
      .then((response: InventoryListResponse) => {
        res = response;
      })
      .catch((err: Error) => {
        return Promise.reject(err);
      });
    if (res === undefined) {
      return Promise.reject(new Error('No results returned.'));
    }
    return Promise.resolve(res);
  }

  async updateItem(item: Inventory): Promise<InventoryItemResponse> {
    let res: InventoryItemResponse | undefined;
    await apiSvc
      .httpPost(`${ApiEndpoints.apiEndpoint}/v3/inventory/item/update`, item)
      .then((response) => {
        res = response;
      })
      .catch((err: Error) => {
        return Promise.reject(err);
      });
    if (res === undefined) {
      return Promise.reject(new Error('No results returned.'));
    }
    return Promise.resolve(res);
  }

  //
  //
  //

  async addLocationToItemSettings(organizationId: string, itemId: string, locationId: string, tab: string | undefined): Promise<any> {
    const data = {
      itemId,
      organizationId,
      locationId,
      tab,
    };

    //
    let res: InventoryItemResponse | undefined;
    await apiSvc
      .httpPost(`${ApiEndpoints.apiEndpoint}/v3/inventory/item/location/insert`, data)
      .then((response) => {
        res = response;
      })
      .catch((err: Error) => {
        return Promise.reject(err);
      });
    if (res === undefined) {
      return Promise.reject(new Error('No results returned.'));
    }
    return Promise.resolve(res);
  }

  //
  // Lots
  //

  async getLot(organizationId: string | undefined, itemId: string | undefined, modifierKey: string | undefined, timestamp: number | undefined): Promise<InventoryLotListResponse> {
    const orgId = organizationId || this.profileStore.currOrganization.organizationId;
    if (orgId === undefined) return Promise.reject(new Error('No organization ID provided'));
    let url = `${ApiEndpoints.apiEndpoint}/v3/inventory/lot?orgid=${encodeURIComponent(orgId)}`;
    if (itemId) url = `${url}&item_id=${encodeURIComponent(itemId)}`;
    if (modifierKey) url = `${url}&modifier_key=${encodeURIComponent(modifierKey)}`;
    if (timestamp) url = `${url}&timestamp=${encodeURIComponent(timestamp)}`;
    let res: InventoryLotListResponse | undefined;
    // this.context.dispatch
    await apiSvc
      .httpGet(url)
      .then((response: InventoryLotListResponse) => {
        res = response;
      })
      .catch((err: Error) => {
        return Promise.reject(err);
      });
    if (res === undefined) {
      return Promise.reject(new Error('No results returned.'));
    }
    return Promise.resolve(res);
  }

  async listLots(organizationId?: string, itemId?: string | null, modifierKey?: string | null, timestamp?: number | string | null): Promise<InventoryLotListResponse> {
    const orgId = organizationId || this.profileStore.currOrganization.organizationId;
    if (orgId === undefined) return Promise.reject(new Error('No organization ID provided'));
    let url = `${ApiEndpoints.apiEndpoint}/v3/inventory/lots?orgid=${encodeURIComponent(orgId)}`;
    if (itemId) url = `${url}&item_id=${encodeURIComponent(itemId)}`;
    if (modifierKey) url = `${url}&modifier_key=${encodeURIComponent(modifierKey)}`;
    if (timestamp) url = `${url}&timestamp=${encodeURIComponent(timestamp)}`;
    // if (purchaseId) url = `${url}&purchase_id=${purchaseId}`;
    let res: InventoryLotListResponse | undefined;
    await apiSvc
      .httpGet(url)
      .then((response: InventoryLotListResponse) => {
        res = response;
      })
      .catch((err: Error) => {
        return Promise.reject(err);
      });
    if (res === undefined) {
      return Promise.reject(new Error('No results returned.'));
    }
    return Promise.resolve(res);
  }

  async updateLot(item: InventoryLot): Promise<InventoryLotItemResponse> {
    let res: InventoryLotItemResponse | undefined;
    await apiSvc
      .httpPost(`${ApiEndpoints.apiEndpoint}/v3/inventory/lot/update`, item)
      .then((response) => {
        res = response;
      })
      .catch((err: Error) => {
        return Promise.reject(err);
      });
    if (res === undefined) {
      return Promise.reject(new Error('No results returned.'));
    }
    return Promise.resolve(res);
  }

  //
  // Purchase Details
  //

  async insertPurchaseDetail(order: InventoryPurchaseDetail): Promise<InventoryPurchaseDetailItemResponse> {
    let res: InventoryPurchaseDetailItemResponse | undefined;
    await apiSvc
      .httpPost(`${ApiEndpoints.apiEndpoint}/v3/inventory/purchase/detail/insert`, order)
      .then((response) => {
        res = response;
      })
      .catch((err: Error) => {
        return Promise.reject(err);
      });
    if (res === undefined) {
      return Promise.reject(new Error('No results returned.'));
    }
    return Promise.resolve(res);
  }

  async listPurchaseDetails(
    organizationId: string | undefined,
    purchaseId: string | undefined,
    itemId: string | undefined,
    modifierKey: string | undefined
  ): Promise<InventoryPurchaseDetailListResponse> {
    const orgId = organizationId || this.profileStore.currOrganization.organizationId;
    if (orgId === undefined) return Promise.reject(new Error('No organization ID provided'));
    let url = `${ApiEndpoints.apiEndpoint}/v3/inventory/purchase/details?orgid=${encodeURIComponent(orgId)}`;
    if (purchaseId) url = `${url}&purchase_id=${encodeURIComponent(purchaseId)}`;
    if (itemId) url = `${url}&item_id=${encodeURIComponent(itemId)}`;
    if (modifierKey) url = `${url}&modifier_key=${encodeURIComponent(modifierKey)}`;
    let res: InventoryPurchaseDetailListResponse | undefined;
    // this.context.dispatch
    await apiSvc
      .httpGet(url)
      .then((response: InventoryPurchaseDetailListResponse) => {
        res = response;
      })
      .catch((err: Error) => {
        return Promise.reject(err);
      });
    if (res === undefined) {
      return Promise.reject(new Error('No results returned.'));
    }
    return Promise.resolve(res);
  }

  async updatePurchaseDetail(order: InventoryPurchaseDetail): Promise<InventoryPurchaseDetailItemResponse> {
    let res: InventoryPurchaseDetailItemResponse | undefined;
    await apiSvc
      .httpPost(`${ApiEndpoints.apiEndpoint}/v3/inventory/purchase/detail/update`, order)
      .then((response) => {
        res = response;
      })
      .catch((err: Error) => {
        return Promise.reject(err);
      });
    if (res === undefined) {
      return Promise.reject(new Error('No results returned.'));
    }
    return Promise.resolve(res);
  }

  //
  // Purchase Orders
  //

  async getPurchaseOrder(organizationId: string | undefined, purchaseId: string | undefined): Promise<InventoryPurchaseOrderItemResponse> {
    const orgId = organizationId || this.profileStore.currOrganization.organizationId;
    if (orgId === undefined) return Promise.reject(new Error('No organization ID provided'));
    let url = `${ApiEndpoints.apiEndpoint}/v3/inventory/purchase/order?orgid=${encodeURIComponent(orgId)}`;
    if (purchaseId) url = `${url}&purchase_id=${encodeURIComponent(purchaseId)}`;
    let res: InventoryPurchaseOrderItemResponse | undefined;
    // this.context.dispatch
    await apiSvc
      .httpGet(url)
      .then((response: InventoryPurchaseOrderItemResponse) => {
        res = response;
      })
      .catch((err: Error) => {
        return Promise.reject(err);
      });
    if (res === undefined) {
      return Promise.reject(new Error('No results returned.'));
    }
    return Promise.resolve(res);
  }

  async insertPurchaseOrder(order: InventoryPurchaseOrder): Promise<InventoryPurchaseOrderItemResponse> {
    let res: InventoryPurchaseOrderItemResponse | undefined;
    await apiSvc
      .httpPost(`${ApiEndpoints.apiEndpoint}/v3/inventory/purchase/order/insert`, order)
      .then((response) => {
        res = response;
      })
      .catch((err: Error) => {
        return Promise.reject(err);
      });
    if (res === undefined) {
      return Promise.reject(new Error('No results returned.'));
    }
    return Promise.resolve(res);
  }

  async listPurchaseOrders(organizationId?: string): Promise<InventoryPurchaseListResponse | undefined> {
    const orgId = organizationId || this.profileStore.currOrganization.organizationId;
    if (orgId === undefined) return Promise.reject(new Error('No organization ID provided'));
    let res: InventoryPurchaseListResponse | undefined;
    // this.context.dispatch
    await apiSvc
      .httpGet(`${ApiEndpoints.apiEndpoint}/v3/inventory/purchase/orders?orgid=${encodeURIComponent(orgId)}`)
      .then((response: InventoryPurchaseListResponse) => {
        res = response;
      })
      .catch((err: Error) => {
        return Promise.reject(err);
      });
    if (res === undefined) {
      return Promise.reject(new Error('No results returned.'));
    }
    return Promise.resolve(res);
  }

  async updatePurchaseOrder(order: InventoryPurchaseOrder): Promise<InventoryPurchaseOrderItemResponse> {
    let res: InventoryPurchaseOrderItemResponse | undefined;
    await apiSvc
      .httpPost(`${ApiEndpoints.apiEndpoint}/v3/inventory/purchase/order/update`, order)
      .then((response) => {
        res = response;
      })
      .catch((err: Error) => {
        return Promise.reject(err);
      });
    if (res === undefined) {
      return Promise.reject(new Error('No results returned.'));
    }
    return Promise.resolve(res);
  }
}
