<template>
  <b-container fluid>
    <!-- <mq-layout mq="sm"> -->
    <mq-layout mq="md+">
      <b-row class="my-4"><b-col>&nbsp;</b-col></b-row>
    </mq-layout>
    <b-row>
      <b-col class="text-center">
        <b-img width="250px" src="/dynamic/logos/main-logo.png" class="m-2" />
      </b-col>
    </b-row>
    <b-row>
      <div class="col-md-4 col-md-offset-4 my-4 mx-auto">
        <div class="panel panel-default">
          <b-alert variant="info" show> Welcome to your new account! </b-alert>
        </div>
      </div>
    </b-row>
    <b-row>
      <div class="col-md-4 col-md-offset-4 my-4 mx-auto">
        <div class="panel panel-default text-center">Let's get you setup...</div>
      </div>
    </b-row>
    <b-row>
      <div class="col-md-4 col-md-offset-4 my-4 mx-auto">
        <div class="text-center">
          <mdb-btn :block="$mq === 'sm'" color="amber" @click="handleContinueClick()"> Continue </mdb-btn>
        </div>
      </div>
    </b-row>
  </b-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import axios from 'axios';
import { mdbAlert, mdbBtn } from 'mdbvue';
// import { Constants } from '@/constants';
// import * as RouterIcons from '@/router/RouterIcons';

@Component({
  components: {
    mdbAlert,
    mdbBtn,
  },
})
export default class OnBoardingWelcome extends Vue {
  errorMessage: string | null = null;
  successMessage: string | null = null;
  showContinueScreen = false;
  infoMessage: string | null = null;
  disableAllInputs = false;
  disableUsername = false;
  username = '';
  firstName = '';
  lastName = '';
  organizationName = '';

  // async mounted() {}

  // see if the form elements are valid
  get formIsValid() {
    if (this.guideMessage !== 'Click the button ...') {
      return false;
    }
    return true;
  }

  get guideMessage() {
    // if (!/[\w]+/.test(this.organizationName)) {
    //   return 'Please enter your organization\'s name';
    // }
    // if (!/[\w]+/.test(this.firstName)) {
    //   return 'Please enter your first name';
    // }
    // if (!/[\w]+/.test(this.lastName)) {
    //   return 'Please enter your last name';
    // }
    // if (
    //   !/[\w]+/.test(this.username) ||
    //   // eslint-disable-next-line
    //   !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.username)) {
    //   return 'Please enter a valid email address';
    // }
    return 'Click the button ...';
  }

  handleContinueClick() {
    // Remove alert boxes
    this.successMessage = null;
    this.errorMessage = null;
    this.infoMessage = null;
    // redirect to login page
    // this.$router.push({ name: this.getRedirectAfterLogin() })
    this.$emit('next');
  }

  // handleSkipClick() {
  //   this.$emit('skip');
  // }

  handleRequestClick() {
    // Remove alert boxes
    this.successMessage = null;
    this.errorMessage = null;
    // // initiate forgot password
    // this.disableAllInputs = true;
    // // info
    // this.infoMessage = "Creating your organization's account ...";
    // // cleanse
    // this.username = this.username.toLowerCase().replace(/^\s+|\s+$/gm, '');
    // // data
    // const data = {
    //   email: this.username,
    //   firstName: this.firstName,
    //   lastName: this.lastName,
    //   organizationName: this.organizationName,
    //   organizationType: 'rec-club'
    // };
    // console.log('data ', data);
    // this.createAndInviteUser(data)
    //   .then(() => {
    //     this.disableAllInputs = false;
    //     this.errorMessage = null;
    //     this.infoMessage = null;
    //     this.successMessage = 'Look for a verification email in your inbox (or spam folder)';
    //   })
    //   .catch((err: any) => {
    //     // console.log(err);
    //     // console.log(JSON.stringify(err));
    //     let errorMessage = err.message;
    //     try {
    //       errorMessage = err.response.data.message;
    //     } catch (err) {
    //       // console.log(err);
    //     }
    //     if (!errorMessage || errorMessage === '')
    //       errorMessage = 'Could not create account. Please verify you are at the correct domain';
    //     this.errorMessage = errorMessage;
    //     this.infoMessage = null;
    //     this.disableAllInputs = false;
    //   });
  }
}
</script>

<style></style>
