import { getModule } from 'vuex-module-decorators';
import { OrganizationRequest, OrganizationResponse, OrganizationsResponse } from '@fgl/funfangle-sdk/dist/rest/profile';
import { ApiEndpoints } from '@/constants';
import { ProfileModule } from '@/store';
import { store } from '../../store/Store';
import { ApiClient } from '.';

export class ProfileOrganizationApiClient extends ApiClient {
  public profileStore: ProfileModule = getModule(ProfileModule, store);

  async getOrganization(organizationId?: string): Promise<OrganizationResponse> {
    if (organizationId === undefined) return Promise.reject(new Error('Invalid organizationId'));
    const url = `${ApiEndpoints.apiEndpoint}/v2/profile/organization?organizationId=${organizationId}`;
    return new ApiClient().httpGet(url);
  }

  async getOrganizations(type?: string): Promise<OrganizationsResponse> {
    let url = `${ApiEndpoints.apiEndpoint}/v2/profile/organizations?`;
    if (type != null && type !== undefined) {
      url = `${url}&type=${type}`;
    }
    return new ApiClient().httpGet(url);
  }

  async getOrganizationsByEmail(email: string) {
    const apiSvc = new ApiClient();
    return apiSvc.httpGet(`${ApiEndpoints.apiEndpoint}/v2/profile/organizations?email=${email}`);
  }

  async getOrganizationsByType(type: string) {
    const apiSvc = new ApiClient();
    return apiSvc.httpGet(`${ApiEndpoints.apiEndpoint}/v2/profile/organizations?type=${type}`);
  }

  async postOrganization(request: OrganizationRequest) {
    const url = `${ApiEndpoints.apiEndpoint}/v2/profile/organization`;
    const apiSvc = new ApiClient();
    return apiSvc.httpPost(url, request);
  }
}
