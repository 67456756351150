import { ApiEndpoints } from '../constants';
import { store } from '@/store/Store';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { LodgingIdentifier, LodgingIdentifiersResponse } from '@fgl/funfangle-sdk/dist/rest/lodging';
import { ApiService } from '@/shared/auth/auth-cognito';
import { getModule } from 'vuex-module-decorators';
import { ProfileModule } from './ProfileModule';

const apiSvc = new ApiService();

@Module({ dynamic: true, store, name: 'identifiers' })
export class IdentifiersModule extends VuexModule {
  profileStore: ProfileModule = getModule(ProfileModule);
  //
  identifiersResponse: LodgingIdentifiersResponse | undefined;

  get identifiers(): LodgingIdentifier[] {
    if (this.identifiersResponse === undefined) return [];
    return this.identifiersResponse.getBodyObj().items;
  }

  @Action
  public async fetchIdentifiers(): Promise<LodgingIdentifiersResponse | undefined> {
    let res: LodgingIdentifiersResponse | undefined;
    // this.context.dispatch
    await apiSvc
      .httpGet(`${ApiEndpoints.apiEndpoint}/v2/lodging/identifiers?organizationId=${this.profileStore.currOrganization.organizationId}`)
      .then((response: LodgingIdentifiersResponse) => {
        res = response;
        this.setIdentifiersResponse(response);
      })
      .catch((err: Error) => {
        return Promise.reject(err);
      });
    if (res === undefined) {
      return Promise.reject(new Error('No identifiers returned.'));
    }
    return Promise.resolve(res);
  }

  // /**
  //  *
  //  */
  // get orgUsersItems(): User[] {
  //   if (this.orgUsers === undefined || this.orgUsers.data === undefined || this.orgUsers.data.message === undefined || this.orgUsers.data.message.Items === undefined) {
  //     return [];
  //   }
  //   return this.orgUsers.data.message.Items;
  // }

  // get orgUsersTable(): TableBvResponseBody<User> {
  //   if (this.orgUsers === undefined || this.orgUsers.data === undefined) {
  //     return new TableBvResponseBody<User>();
  //   }
  //   return this.orgUsers.data;
  // }

  @Mutation
  public setIdentifiersResponse(response: LodgingIdentifiersResponse): void {
    this.identifiersResponse = response;
  }
}
