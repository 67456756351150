<template>
  <b-container fluid>
    <!-- <mq-layout mq="sm"> -->
    <b-row>
      <div class="col-md-6 col-md-offset-4 my-4 mx-auto">
        <div class="panel panel-default">
          <b-alert variant="info" show> We're curious ... </b-alert>
        </div>
      </div>
    </b-row>
    <b-row>
      <div class="col-md-6 col-md-offset-4 mt-4 mb-2 mx-auto">
        <div class="panel panel-default text-left">
          <div class="form-group">
            <label for="org-description">In two words, describe your organization (i.e. swim club, summer camp)</label>
            <input id="org-description" v-model="organizationDescription" type="text" class="form-control" />
          </div>
        </div>
      </div>
    </b-row>
    <b-row>
      <div class="col-md-6 col-md-offset-4 my-2 mx-auto">
        <div class="panel panel-default text-left">
          <div class="form-group">
            <label for="user-role">What is your role</label>
            <input id="user-role" v-model="userRole" type="text" class="form-control" />
          </div>
        </div>
      </div>
    </b-row>
    <b-row>
      <div class="col-md-6 col-md-offset-4 mt-1 mb-2 mx-auto">
        <div class="panel panel-default text-left">
          <div class="form-group">
            <label for="main-problem">Please share the main problem you need solved</label>
            <mdb-input id="main-problem" ref="problem" v-model="problemDescription" type="textarea" outline />
          </div>
        </div>
      </div>
    </b-row>
    <b-row>
      <div class="col-md-4 col-md-offset-4 mt-3 mb-2 mx-auto">
        <div class="text-center">
          <mdb-btn :block="$mq === 'sm'" color="amber" @click="handleContinueClick()"> Continue </mdb-btn>
        </div>
      </div>
    </b-row>
    <b-row v-if="organizationDescription !== '' && userRole !== '' && problemDescription !== ''">
      <div class="col-md-4 col-md-offset-4 my-4 mx-auto">
        <div class="panel panel-default">
          <b-alert variant="success" show> Thanks for humoring us! Your answers helps us serve you better. </b-alert>
        </div>
      </div>
    </b-row>
  </b-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { ApiEndpoints } from './../../constants';
import { ApiService } from '@/shared/auth/auth-cognito';
import axios from 'axios';
import { getModule } from 'vuex-module-decorators';
import { ProfileModule } from '@/store/ProfileModule';
import { SessionModule } from '@/store/SessionModule';
import { mdbAlert, mdbBtn, mdbInput } from 'mdbvue';
// import { Constants } from '@/constants';
// import * as RouterIcons from '@/router/RouterIcons';

@Component({
  components: {
    mdbAlert,
    mdbBtn,
    mdbInput,
  },
})
export default class OnBoardingWelcome extends Vue {
  errorMessage: string | null = null;
  successMessage: string | null = null;
  profileStore: ProfileModule = getModule(ProfileModule, this.$store);
  sessionStore: SessionModule = getModule(SessionModule, this.$store);
  showContinueScreen = false;
  infoMessage: string | null = null;
  disableAllInputs = false;
  disableUsername = false;
  username = '';
  firstName = '';
  lastName = '';
  organizationName = '';
  organizationDescription = '';
  userRole = '';
  problemDescription = '';

  // async mounted() {}

  // see if the form elements are valid
  get formIsValid() {
    if (this.guideMessage !== 'Click the button ...') {
      return false;
    }
    return true;
  }

  get guideMessage() {
    return 'Click the button ...';
  }

  async handleContinueClick() {
    // Remove alert boxes
    this.successMessage = null;
    this.errorMessage = null;
    this.infoMessage = null;
    // redirect to login page
    const req: any = {};
    req.organizationId = this.profileStore.currOrganization.organizationId;
    req.userId = this.profileStore.currUser.userId;
    req.organizationDescription = this.organizationDescription;
    req.userRole = this.userRole;
    req.problemDescription = this.problemDescription;
    const url = `${ApiEndpoints.apiEndpoint}/v2/profile/insights/about-them`;
    const apiSvc = new ApiService();
    await apiSvc
      .httpPost(url, req)
      .then(() => {
        // nothing
      })
      .catch((err: Error) => {
        this.errorMessage = err.message;
      });
    this.$emit('next');
  }

  // handleSkipClick() {
  //   this.$emit('skip');
  // }
}
</script>

<style></style>
