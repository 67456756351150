import { getModule } from 'vuex-module-decorators';
import { LocationsResponse } from '@fgl/funfangle-sdk/dist/rest/profile';
import { ApiEndpoints } from '@/constants';
import { ApiService } from '@/shared/auth/auth-cognito';
import { LocationModule, ProfileModule } from '@/store';
import { store } from '../../store/Store';
import { ApiClient } from '.';

const apiSvc = new ApiService();

export class ProfileLocationApiClient extends ApiClient {
  public profileStore: ProfileModule = getModule(ProfileModule, store);
  public locationStore: LocationModule = getModule(LocationModule, store);

  async listLocations(organizationId?: string): Promise<LocationsResponse> {
    const orgId = organizationId || this.profileStore.currOrganization.organizationId;
    // console.log('listLots orgId:', orgId);
    // console.log('listLots itemId:', itemId);
    const url = `${ApiEndpoints.apiEndpoint}/v2/profile/location/list?organizationId=${orgId}`;
    // if (itemId) url = `${url}&item_id=${encodeURIComponent(itemId)}`;
    // if (modifierKey) url = `${url}&modifier_key=${encodeURIComponent(modifierKey)}`;
    // if (timestamp) url = `${url}&timestamp=${encodeURIComponent(timestamp)}`;
    // if (purchaseId) url = `${url}&purchase_id=${purchaseId}`;
    // console.log('listLots url:', url);
    let res: LocationsResponse | undefined;
    // this.context.dispatch
    await apiSvc
      .httpGet(url)
      .then((response: LocationsResponse) => {
        res = response;
      })
      .catch((err: Error) => {
        return Promise.reject(err);
      });
    if (res === undefined) {
      return Promise.reject(new Error('No results returned.'));
    }
    return Promise.resolve(res);
  }
}
