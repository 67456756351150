var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      !_vm.hasOnboardingRequired && _vm.enableTopMenu
        ? _c("console-top-menu", {
            class: _vm.classTopMenu,
            on: { toggleSideMenu: _vm.toggleSideMenu },
          })
        : _vm._e(),
      !_vm.hasOnboardingRequired && _vm.enableSideMenu
        ? _c("console-side-menu", { attrs: { "show-menu": _vm.showSideMenu } })
        : _vm._e(),
      !_vm.hasOnboardingRequired
        ? _c("router-view", { class: _vm.classRouterView })
        : _vm._e(),
      _vm.hasOnboardingRequired ? _c("router-view") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }