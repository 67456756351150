import Vue from 'vue';
import VueI18n from 'vue-i18n';
import localeMessages from './locale_messages.json';

// register i18n module
Vue.use(VueI18n);

const en = {
  message: {
    hello: 'hello world',
  },
};

const i18n = new VueI18n({
  locale: 'en-US', //if you need get the browser language use following "window.navigator.language"
  fallbackLocale: 'en',
  messages: localeMessages,
  numberFormats: {
    'en-US': {
      currency: {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'symbol',
      },
    },
    'en-CA': {
      currency: {
        style: 'currency',
        currency: 'CAN',
      },
    },
    'fr-CA': {
      currency: {
        style: 'currency',
        currency: 'CAN',
      },
    },
    'ja-JP': {
      currency: {
        style: 'currency',
        currency: 'JPY',
        currencyDisplay: 'symbol',
      },
    },
  },
  silentTranslationWarn: true,
});

const translate = (key: string) => {
  if (!key) {
    return '';
  }
  return i18n.t(key);
};

export { i18n, translate }; //export above method
