// import { ApiEndpoints } from '@/constants';
import { getModule } from 'vuex-module-decorators';
import { TransactionsResponse } from '@fgl/funfangle-sdk/dist/rest/bank';
import { store, ProfileModule } from '../../store';
import { ApiClient } from '.';
import { ApiEndpoints } from '@/constants';
// import { UserRequest } from '@fgl/funfangle-sdk/dist/rest/profile';

export class BankApiClient extends ApiClient {
  public profileStore: ProfileModule = getModule(ProfileModule, store);

  async getTransactions(lowerEpoch?: number | string, upperEpoch?: number | string): Promise<TransactionsResponse> {
    const apiSvc = new ApiClient();
    let url = `${ApiEndpoints.apiEndpoint}/v2/bank/transaction/list?organizationId=${this.profileStore.currOrganization.organizationId}`;
    if (lowerEpoch !== undefined) url = `${url}&lowerEpoch=${lowerEpoch}`;
    if (upperEpoch !== undefined) url = `${url}&upperEpoch=${upperEpoch}`;
    return apiSvc.httpGet(url);
  }

  // async postRemoveOwner(request: LodgingUnitUpdateRequest) {
  //   const url = `${ApiEndpoints.apiEndpoint}/v2/lodging/unit/remove/owner`;
  //   const apiSvc = new ApiClient();
  //   return apiSvc.httpPost(url, request);
  // }
}
