import axios from 'axios';
import { getModule } from 'vuex-module-decorators';
import { store } from '../../store/Store';
import { AuthModule } from '../auth/auth-cognito';

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (err) {
    // const storeAuth: AuthModule = getModule(AuthModule, store);
    // storeAuth.setLastCallbackErr(err);
    // if error is ...
    const error = err.response;
    if (error !== undefined && error.status === 401) {
      // reload ...
      // eslint-disable-next-line no-self-assign
      // window.location.href = window.location.href;
    }
    // if (error.status === 401 && error.config && !error.config.__isRetryRequest) {
    //   // // console.log('ERROR on AXIOS 401');
    //   // // request for a new token
    //   // let storeAuth: AuthModule = getModule(AuthModule, store);
    //   // return storeAuth.refreshToken().then(() => {
    //   //   // console.log('ERROR on AXIOS Refreshed Token ');
    //   //   // update the error config with new token
    //   //   error.config.__isRetryRequest = true;
    //   //   error.config.headers.token = storeAuth.userIdToken;
    //   //   axios.defaults.headers.common['Authorization'] = storeAuth.userIdToken;
    //   //   return axios(error.config);
    //   // });
    // }
  }
);

export class ApiClient {
  public storeAuth: AuthModule = getModule(AuthModule, store);

  public async checkToken(): Promise<void> {
    if (this.storeAuth.idTokenExpiresAt > Date.now()) {
      await this.storeAuth.refreshToken().catch(() => {});
    }
    return Promise.resolve();
  }

  public async httpPost(url: string, data: any): Promise<any> {
    await this.checkToken().catch(() => {});
    if (this.storeAuth.userIdToken) axios.defaults.headers.common['Authorization'] = this.storeAuth.userIdToken;
    return axios.post(url, data);
  }

  public async httpGet(url: string): Promise<any> {
    await this.checkToken().catch(() => {});
    if (this.storeAuth.userIdToken) axios.defaults.headers.common['Authorization'] = this.storeAuth.userIdToken;
    return axios.get(url);
  }
}
