import { EventReservationSettings } from '@fgl/funfangle-sdk/dist/rest/event';
import { Session, SessionRequest } from '@fgl/funfangle-sdk/dist/rest/session';
import { SessionApiClient } from '.';

export class SessionConfigClient {
  async updateEventReservationSettingCutoffAtHoursPrior(session?: Session, cutoffAtHoursPrior?: number) {
    if (session === undefined) return;
    if (session.organizationId === undefined) return;
    if (session.sessionId === undefined) return;
    if (cutoffAtHoursPrior === undefined) return;
    const req = new SessionRequest(session.organizationId);
    req.sessionId = session.sessionId;
    const eventReservationSettings = session.eventReservationSettings || new EventReservationSettings();
    eventReservationSettings.cutoffAtHoursPrior = cutoffAtHoursPrior;
    req.eventReservationSettings = eventReservationSettings;
    const apiSvc = new SessionApiClient();
    return apiSvc.postSession(req);
  }

  async updateEventReservationSettingExtraRequired(session?: Session, extraRequired?: boolean) {
    if (session === undefined) return;
    if (session.organizationId === undefined) return;
    if (session.sessionId === undefined) return;
    const req = new SessionRequest(session.organizationId);
    req.sessionId = session.sessionId;
    const eventReservationSettings = session.eventReservationSettings || new EventReservationSettings();
    eventReservationSettings.extraRequired = extraRequired || false;
    req.eventReservationSettings = eventReservationSettings;
    const apiSvc = new SessionApiClient();
    return apiSvc.postSession(req);
  }

  async updateSessionName(session?: Session, sessionName?: string | null) {
    if (session === undefined) return;
    if (session.organizationId === undefined) return;
    if (session.sessionId === undefined) return;
    if (sessionName === null || sessionName === undefined) return;
    const req = new SessionRequest(session.organizationId);
    req.sessionId = session.sessionId;
    req.sessionName = sessionName;
    const apiSvc = new SessionApiClient();
    return apiSvc.postSession(req);
  }
}
