import { getModule } from 'vuex-module-decorators';
import { InvoiceResponse, InvoicesResponse } from '@fgl/funfangle-sdk/dist/rest/bank';
import { InvoicePaymentRequest } from '@fgl/funfangle-sdk/dist/rest/bank/payment';
import { ApiEndpoints } from '@/constants';
import { store, ProfileModule } from '../../store';
import { ApiClient } from '.';
// import { UserRequest } from '@fgl/funfangle-sdk/dist/rest/profile';

export class BankInvoiceApiClient extends ApiClient {
  public profileStore: ProfileModule = getModule(ProfileModule, store);

  async getAccountInvoicesAll(householdId?: string): Promise<InvoicesResponse> {
    let url = `${ApiEndpoints.apiEndpoint}/v2/bank/invoices/account/all?organizationId=${this.profileStore.currOrganization.organizationId}`;
    url = `${url}&householdId=${householdId || this.profileStore.householdId || ''}`;
    const apiSvc = new ApiClient();
    return apiSvc.httpGet(url);
  }

  async getAccountInvoicesDue(householdId?: string): Promise<InvoicesResponse> {
    let url = `${ApiEndpoints.apiEndpoint}/v2/bank/invoices/account/due?organizationId=${this.profileStore.currOrganization.organizationId}`;
    url = `${url}&householdId=${householdId || this.profileStore.householdId || ''}`;
    const apiSvc = new ApiClient();
    return apiSvc.httpGet(url);
  }

  async getInvoiceDetail(invoiceId?: string): Promise<InvoiceResponse> {
    let url = `${ApiEndpoints.apiEndpoint}/v2/bank/invoice/detail/?`;
    if (invoiceId !== undefined) url = `${url}&invoiceId=${invoiceId}`;
    if (this.profileStore.organizationId !== undefined) url = `${url}&organizationId=${this.profileStore.organizationId}`;
    const apiSvc = new ApiClient();
    return apiSvc.httpGet(url);
  }

  async postInvoiceCheckPayment(request: InvoicePaymentRequest): Promise<InvoiceResponse> {
    const url = `${ApiEndpoints.apiEndpoint}/v2/bank/payment/invoice/check`;
    const apiSvc = new ApiClient();
    return apiSvc.httpPost(url, request);
  }

  async postInvoiceMethodPayment(request: InvoicePaymentRequest): Promise<InvoiceResponse> {
    const url = `${ApiEndpoints.apiEndpoint}/v2/bank/payment/invoice/method`;
    const apiSvc = new ApiClient();
    return apiSvc.httpPost(url, request);
  }

  async postInvoiceSquarePayment(request: InvoicePaymentRequest) {
    const url = `${ApiEndpoints.apiEndpoint}/v2/bank/payment/invoice/square`;
    const apiSvc = new ApiClient();
    return apiSvc.httpPost(url, request);
  }
}
