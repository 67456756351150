import { getModule } from 'vuex-module-decorators';
import { LocationModule, store } from '@/store';
import { Location } from '@fgl/funfangle-sdk/dist/rest/profile';
import { ProfileLocationApiClient } from '@/shared/api';

export class LocationService {
  async getLocation(locationId?: string): Promise<Location | null> {
    if (locationId == null || locationId === undefined) return Promise.reject(new Error('No location ID provided'));
    const locationStore: LocationModule = getModule(LocationModule, store);
    let location: Location | null = null;

    // if (locationStore.location && locationId === locationStore.location.locationId) {
    //   location = locationStore.location;
    //   if (location != null && location !== undefined) return Promise.resolve(location);
    // }

    if (locationStore.locations) {
      for (const i of locationStore.locations) {
        if (i && i.locationId === locationId) {
          location = i;
          if (location != null && location !== undefined) return Promise.resolve(location);
        }
      }
    }

    const client = new ProfileLocationApiClient();
    await client
      .listLocations(undefined)
      .then((response) => {
        // console.log('getLocations response', response);
        if (response && response.data && response.data.items) {
          locationStore.setLocations(response.data.items);
          for (const i of response.data.items) {
            if (i && i.locationId === locationId) {
              location = i;
              if (location != null && location !== undefined) return Promise.resolve(location);
            }
          }
        }
        // if (response) item = response;
        // item = response.item;
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });

    return Promise.resolve(location);
  }

  async listLocations(): Promise<Location[] | null> {
    const locationStore: LocationModule = getModule(LocationModule, store);
    let locations: Location[] | null = null;

    // if (locationStore.location && locationId === locationStore.location.locationId) {
    //   location = locationStore.location;
    //   if (location != null && location !== undefined) return Promise.resolve(location);
    // }

    if (locationStore.locations) {
      return Promise.resolve(locationStore.locations);
    }

    const client = new ProfileLocationApiClient();
    await client
      .listLocations(undefined)
      .then((response) => {
        // console.log('getLocations response', response);
        if (response && response.data && response.data.items) {
          // console.log('getLocations response.data.items', response.data.items);
          locationStore.setLocations(response.data.items);
          locations = response.data.items;
        }
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });

    return Promise.resolve(locations);
  }
}
