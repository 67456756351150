var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "mq-layout",
        { attrs: { mq: "md+" } },
        [_c("b-row", { staticClass: "my-4" }, [_c("b-col", [_vm._v(" ")])], 1)],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "text-center" },
            [
              _c("b-img", {
                staticClass: "m-2",
                attrs: { width: "250px", src: "/dynamic/logos/main-logo.png" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("b-row", [
        _c("div", { staticClass: "col-md-4 col-md-offset-4 my-4 mx-auto" }, [
          _c(
            "div",
            { staticClass: "panel panel-default" },
            [
              _c("b-alert", { attrs: { variant: "info", show: "" } }, [
                _vm._v(" Welcome to your new account! "),
              ]),
            ],
            1
          ),
        ]),
      ]),
      _c("b-row", [
        _c("div", { staticClass: "col-md-4 col-md-offset-4 my-4 mx-auto" }, [
          _c("div", { staticClass: "panel panel-default text-center" }, [
            _vm._v("Let's get you setup..."),
          ]),
        ]),
      ]),
      _c("b-row", [
        _c("div", { staticClass: "col-md-4 col-md-offset-4 my-4 mx-auto" }, [
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                "mdb-btn",
                {
                  attrs: { block: _vm.$mq === "sm", color: "amber" },
                  on: {
                    click: function ($event) {
                      return _vm.handleContinueClick()
                    },
                  },
                },
                [_vm._v(" Continue ")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }