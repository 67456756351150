export const env = 'Dev';

export class ApiEndpoints {
  public static apiEndpoint = 'https://api.dev.funfangle.com';
  public static authEndpoint = ApiEndpoints.apiEndpoint + '/cognito';
  public static bankEndpoint = ApiEndpoints.apiEndpoint + '/bank';
  public static carePackagesEndpoint = ApiEndpoints.apiEndpoint + '/misc/care-packages';
  public static emailEndpoint = ApiEndpoints.apiEndpoint + '/emails';
  public static generalEndpoint = ApiEndpoints.apiEndpoint + '/general';
  public static logEndpoint = ApiEndpoints.apiEndpoint + '/log';
  public static noAuthEndpoint = ApiEndpoints.apiEndpoint + '/noauth';
  public static organizationEndpoint = ApiEndpoints.apiEndpoint + '/organizations';
  public static profileEndpoint = ApiEndpoints.apiEndpoint + '/profile';
  public static squareApplicationId = atob('c2FuZGJveC1zcTBpZHAtLXQxOE5oMEI0bFB0cDl0QUVoak1UZw==');
  public static squareEndpoint = ApiEndpoints.apiEndpoint + '/square';
  public static tutorialEndpoint = ApiEndpoints.apiEndpoint + '/tutorial';
  public static bamboraAPI = 'https://api.na.bambora.com';
  public static tempImagesBucket = {
    name: 'funfangle-temp-images-dev',
    region: 'us-east-1',
  };
  // AWSConfig = region => {
  //   return {
  //     region
  //   };
  // };
}

export class Constants {
  public static apiRegion = 'us-east-1';
  public static idpRegion = 'us-east-1';
  public static importBucket = 'protected.funfangle-import-dev';
  public static storageRegion = 'us-east-1';
}
