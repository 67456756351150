<template>
  <b-container fluid>
    <!-- <mq-layout mq="sm"> -->
    <mq-layout mq="md+">
      <b-row class="my-4"><b-col>&nbsp;</b-col></b-row>
    </mq-layout>
    <b-row>
      <b-col class="text-center">
        <b-img width="250px" src="/dynamic/logos/main-logo.png" class="m-2" />
      </b-col>
    </b-row>
    <b-row>
      <div class="col-md-4 col-md-offset-4 my-4 mx-auto">
        <div class="panel panel-default">
          <b-alert variant="info" show> Please enter your time zone </b-alert>
        </div>
      </div>
    </b-row>
    <b-row>
      <div class="col-md-4 col-md-offset-4 my-0 mx-auto">
        <div class="panel panel-default">
          <mdb-autocomplete v-model="timeZone" :data="timeZones" label="Your time zone" />
          <!-- <mdb-select
            @change="updateTimeZone"
            v-model="timeZones"
            placeholder="Select your time zone."
            label="Time Zone"
          /> -->
        </div>
      </div>
    </b-row>
    <b-row>
      <div class="col-md-4 col-md-offset-4 my-4 mx-auto">
        <div class="panel panel-default">
          <b-alert :variant="guideMessage !== 'Looks good ...' ? 'warning' : 'success'" show>
            {{ guideMessage }}
          </b-alert>
        </div>
      </div>
    </b-row>
    <b-row>
      <div class="col-md-4 col-md-offset-4 my-3 mx-auto">
        <div class="text-center">
          <mdb-btn :disabled="!formIsValid" @click="handleContinueClick()"> Continue </mdb-btn>
        </div>
      </div>
    </b-row>
  </b-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import { ProfileModule } from '@/store/ProfileModule';
import { SessionModule } from '@/store/SessionModule';
import { OrganizationRequest } from '@fgl/funfangle-sdk/dist/rest/profile';
import { mdbAlert, mdbAutocomplete, mdbBtn, mdbSelect } from 'mdbvue';
import { OrganizationProfileClient, TimeZoneElements } from '.';

@Component({
  components: {
    mdbAlert,
    mdbAutocomplete,
    mdbBtn,
    mdbSelect,
  },
})
export default class TimeZoneComponent extends Vue {
  errorMessage: string | null = null;
  successMessage: string | null = null;
  showContinueScreen = false;
  infoMessage: string | null = null;
  profileStore: ProfileModule = getModule(ProfileModule, this.$store);
  sessionStore: SessionModule = getModule(SessionModule, this.$store);
  disableAllInputs = false;
  timeZones: string[] = [];
  timeZoneSet: Set<string> = new Set();
  timeZone = '';

  async mounted() {
    // populate time zones
    this.timeZones = new TimeZoneElements().getTimeZones();
    this.timeZoneSet = new Set(this.timeZones);
  }

  // see if the form elements are valid
  get formIsValid(): boolean {
    if (this.guideMessage !== 'Looks good ...') return false;
    return true;
  }

  get defaultTimeZone(): string {
    return new TimeZoneElements().getDefaultTimezone();
  }

  get guideMessage(): string {
    if (!this.timeZoneSet.has(this.timeZone)) return `Enter a standard time zone. ${this.defaultTimeZone}`;
    return 'Looks good ...';
  }

  async handleContinueClick(): Promise<void> {
    // reset messages
    this.successMessage = null;
    this.errorMessage = null;
    this.infoMessage = null;
    // formulate request
    const orgRequest = new OrganizationRequest(this.profileStore.currOrganization.organizationId || 'UNSET');
    orgRequest.timeZone = new TimeZoneElements().getOfficialTimezone(this.timeZone);

    const client = new OrganizationProfileClient();
    await client
      .performUpdate(orgRequest)
      .then(() => {
        this.errorMessage = null;
        this.$emit('next');
      })
      .catch((err: Error) => {
        this.errorMessage = err.message;
      });
  }

  handleSkipClick() {
    this.$emit('skip');
  }
}
</script>

<style></style>
