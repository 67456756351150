var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c("b-row", [
        _c("div", { staticClass: "col-md-6 col-md-offset-4 my-4 mx-auto" }, [
          _c(
            "div",
            { staticClass: "panel panel-default" },
            [
              _c("b-alert", { attrs: { variant: "info", show: "" } }, [
                _vm._v(" We're curious ... "),
              ]),
            ],
            1
          ),
        ]),
      ]),
      _c("b-row", [
        _c(
          "div",
          { staticClass: "col-md-6 col-md-offset-4 mt-4 mb-2 mx-auto" },
          [
            _c("div", { staticClass: "panel panel-default text-left" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "org-description" } }, [
                  _vm._v(
                    "In two words, describe your organization (i.e. swim club, summer camp)"
                  ),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.organizationDescription,
                      expression: "organizationDescription",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "org-description", type: "text" },
                  domProps: { value: _vm.organizationDescription },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.organizationDescription = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
          ]
        ),
      ]),
      _c("b-row", [
        _c("div", { staticClass: "col-md-6 col-md-offset-4 my-2 mx-auto" }, [
          _c("div", { staticClass: "panel panel-default text-left" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "user-role" } }, [
                _vm._v("What is your role"),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.userRole,
                    expression: "userRole",
                  },
                ],
                staticClass: "form-control",
                attrs: { id: "user-role", type: "text" },
                domProps: { value: _vm.userRole },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.userRole = $event.target.value
                  },
                },
              }),
            ]),
          ]),
        ]),
      ]),
      _c("b-row", [
        _c(
          "div",
          { staticClass: "col-md-6 col-md-offset-4 mt-1 mb-2 mx-auto" },
          [
            _c("div", { staticClass: "panel panel-default text-left" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { attrs: { for: "main-problem" } }, [
                    _vm._v("Please share the main problem you need solved"),
                  ]),
                  _c("mdb-input", {
                    ref: "problem",
                    attrs: {
                      id: "main-problem",
                      type: "textarea",
                      outline: "",
                    },
                    model: {
                      value: _vm.problemDescription,
                      callback: function ($$v) {
                        _vm.problemDescription = $$v
                      },
                      expression: "problemDescription",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _c("b-row", [
        _c(
          "div",
          { staticClass: "col-md-4 col-md-offset-4 mt-3 mb-2 mx-auto" },
          [
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c(
                  "mdb-btn",
                  {
                    attrs: { block: _vm.$mq === "sm", color: "amber" },
                    on: {
                      click: function ($event) {
                        return _vm.handleContinueClick()
                      },
                    },
                  },
                  [_vm._v(" Continue ")]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _vm.organizationDescription !== "" &&
      _vm.userRole !== "" &&
      _vm.problemDescription !== ""
        ? _c("b-row", [
            _c(
              "div",
              { staticClass: "col-md-4 col-md-offset-4 my-4 mx-auto" },
              [
                _c(
                  "div",
                  { staticClass: "panel panel-default" },
                  [
                    _c("b-alert", { attrs: { variant: "success", show: "" } }, [
                      _vm._v(
                        " Thanks for humoring us! Your answers helps us serve you better. "
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }