import { CognitoUserSession } from 'amazon-cognito-identity-js';
import Vue from 'vue';

export class AuthCookies extends Vue {
  public static ACCESS_TOKEN = 'fgl-access-token';
  public static ID_TIMEOUT = 'fgl-id-timeout';
  public static ID_TOKEN = 'fgl-id-token';
  public static REFRESH_TOKEN = 'fgl-refresh-token';
  public static ORG_ID = 'fgl-org-id';
  public static USER_ID = 'fgl-user-id';
  public static USERNAME = 'fgl-username';

  public static getAccessToken() {
    return this.$cookies.get(AuthCookies.ACCESS_TOKEN);
  }

  public static getIdToken() {
    return this.$cookies.get(AuthCookies.ID_TOKEN);
  }

  public static getRefreshToken() {
    return this.$cookies.get(AuthCookies.REFRESH_TOKEN);
  }

  public static getOrganizationId() {
    return this.$cookies.get(AuthCookies.ORG_ID);
  }

  public static getUserId() {
    return this.$cookies.get(AuthCookies.USER_ID);
  }

  public static getUsername() {
    return this.$cookies.get(AuthCookies.USERNAME);
  }

  public static resetCookies() {
    let hostname = window.location.hostname;
    hostname = hostname.substring(hostname.indexOf('.'));

    const allCookies = document.cookie;
    const splitCookies = allCookies.split(';');
    for (const cookie of splitCookies || []) {
      const cookieName = cookie.split('=')[0].trim();
      // console.log('allCookies x', cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=' + hostname + '; path=/; Secure; SameSite=Strict');
      document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=' + hostname + '; path=/; Secure;';
      document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=' + window.location.hostname + '; path=/; Secure;';
    }

    for (let c = 0; c < splitCookies.length; c++) {
      const d = window.location.hostname.split('.');
      while (d.length > 0) {
        const cookieBase = encodeURIComponent(splitCookies[c].split(';')[0].split('=')[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
        const p = location.pathname.split('/');
        document.cookie = cookieBase + '/';
        while (p.length > 0) {
          document.cookie = cookieBase + p.join('/');
          p.pop();
        }
        d.shift();
      }
    }

    window.localStorage.clear();

    // const expiry = 1;
    // const secure = location.protocol === 'http:' && (location.hostname === 'localhost' || /lvh\.me$/.test(location.hostname)) ? false : true;
    // this.$cookies.set(AuthCookies.ACCESS_TOKEN, '', expiry, '/', hostname, secure);
    // this.$cookies.set(AuthCookies.ID_TOKEN, '', expiry, '/', hostname, secure);
    // this.$cookies.set(AuthCookies.ID_TIMEOUT, '', expiry, '/', hostname, secure);
    // this.$cookies.set(AuthCookies.REFRESH_TOKEN, '', expiry, '/', hostname, secure);
    // this.$cookies.set(AuthCookies.ORG_ID, '', expiry, '/', hostname, secure);
    // this.$cookies.set(AuthCookies.USER_ID, '', expiry, '/', hostname, secure);
    // this.$cookies.set(AuthCookies.USERNAME, '', expiry, '/', hostname, secure);

    // console.log(AuthCookies.ID_TIMEOUT + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=' + hostname + '; path=/');
    // document.cookie = AuthCookies.ACCESS_TOKEN + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=' + hostname + '; path=/';
    // document.cookie = AuthCookies.ID_TIMEOUT + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=' + hostname + '; path=/';
    // document.cookie = AuthCookies.ID_TOKEN + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=' + hostname + '; path=/';
  }

  public static setAccessTokenCookie(token: string, expiration = 59 * 60): void {
    let hostname = window.location.hostname;
    hostname = hostname.substring(hostname.indexOf('.'));
    this.$cookies.set(AuthCookies.ACCESS_TOKEN, token, expiration, '/', hostname, true, 'Strict');
  }

  public static setSessionCookie(session: CognitoUserSession, expiration = '14d'): void {
    let hostname = window.location.hostname;
    hostname = hostname.substring(hostname.indexOf('.'));

    const epoch = Math.floor(new Date().getTime() / 1000);
    const accessTimeout = session.getAccessToken().getExpiration() - epoch > 60 * 59 ? 60 * 59 : session.getAccessToken().getExpiration() - epoch;
    const idTimeout = session.getIdToken().getExpiration() - epoch > 60 * 59 ? 60 * 59 : session.getIdToken().getExpiration() - epoch;

    this.$cookies.set(AuthCookies.ACCESS_TOKEN, session.getAccessToken().getJwtToken(), accessTimeout, '/', hostname, true, 'Strict');
    this.$cookies.set(AuthCookies.ID_TIMEOUT, String(epoch + idTimeout), idTimeout + 600, '/', hostname, true, 'Strict');
    this.$cookies.set(AuthCookies.ID_TOKEN, session.getIdToken().getJwtToken(), idTimeout, '/', hostname, true, 'Strict');
    this.$cookies.set(AuthCookies.REFRESH_TOKEN, session.getRefreshToken().getToken(), expiration, '/', hostname, true, 'Strict');
  }

  public static setIdTokenCookie(token: string, expiration = 59 * 60): void {
    let hostname = window.location.hostname;
    hostname = hostname.substring(hostname.indexOf('.'));
    this.$cookies.set(AuthCookies.ID_TOKEN, token, expiration, '/', hostname, true, 'Strict');
  }

  public static setRefreshTokenCookie(token: string, expiration = '14d'): void {
    let hostname = window.location.hostname;
    hostname = hostname.substring(hostname.indexOf('.'));
    this.$cookies.set(AuthCookies.REFRESH_TOKEN, token, expiration, '/', hostname, true, 'Strict');
  }

  public static setOrganizationIdCookie(organizationId: string): void {
    let hostname = window.location.hostname;
    hostname = hostname.substring(hostname.indexOf('.'));
    this.$cookies.set(AuthCookies.ORG_ID, organizationId, '90d', '/', hostname, true, 'Strict');
  }

  public static setUserIdCookie(userId: string): void {
    let hostname = window.location.hostname;
    hostname = hostname.substring(hostname.indexOf('.'));
    this.$cookies.set(AuthCookies.USER_ID, userId, '90d', '/', hostname, true, 'Strict');
  }

  public static setUsernameCookie(username: string): void {
    let hostname = window.location.hostname;
    hostname = hostname.substring(hostname.indexOf('.'));
    this.$cookies.set(AuthCookies.USERNAME, username, '30d', '/', hostname, true, 'Strict');
  }
}
