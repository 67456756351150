<template>
  <v-app-bar color="light-blue">
    <v-app-bar-nav-icon class="grey--text white-text" @click="toggleHamburgerIcon"></v-app-bar-nav-icon>

    <!-- <slot name="title">
      <v-toolbar-title class="grey--text">
        <span>{{ titleStr }}</span>
      </v-toolbar-title>
    </slot> -->
    <!-- <slot name="menu">
      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on">
            <v-icon left>expand_more</v-icon>
            <span>Menu</span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="link in links" :key="link.text" router :to="link.route">
            <v-list-item-title>{{ link.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </slot> -->

    <v-spacer></v-spacer>

    <v-icon class="white-text" style="cursor: pointer" @click.prevent.native="handleClickedSettings">{{ mdiCog }}</v-icon>
  </v-app-bar>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import {
  mdbDropdown,
  mdbDropdownItem,
  mdbDropdownMenu,
  mdbDropdownToggle,
  mdbIcon,
  mdbInput,
  mdbNavbar,
  // mdbNavbarBrand,
  // mdbNavbarToggler,
  mdbNavbarNav,
  // mdbNavItem,
} from 'mdbvue';
import { VAppBar, VAppBarNavIcon, VIcon, VSpacer } from 'vuetify/lib';
import { mdiCog } from '@mdi/js';
// import OverviewVideo from './videos/OverviewVideo.vue';
import { getModule } from 'vuex-module-decorators';
// import { AuthModule } from '@/shared/auth/auth-cognito/AuthModule';
import { ProfileModule } from '@/store/ProfileModule';
// import { Organization } from '@fgl/funfangle-sdk/dist/rest/profile';
// import { LoginUtils } from './../../shared/auth/LoginUtils';
import { RouterNames } from '@/router/RouterNames';

@Component({
  components: {
    mdbIcon,
    mdbDropdown,
    mdbDropdownMenu,
    mdbDropdownToggle,
    mdbDropdownItem,
    mdbInput,
    mdbNavbar,
    // mdbNavbarBrand,
    // mdbNavbarToggler,
    mdbNavbarNav,
    // mdbNavItem,
    VAppBar,
    VAppBarNavIcon,
    VIcon,
    VSpacer,
  },
})
export default class TopMenu extends Vue {
  // public authStore: AuthModule = getModule(AuthModule, this.$store);
  public profileStore: ProfileModule = getModule(ProfileModule, this.$store);

  mdiCog = mdiCog;

  // public orgSelected: any = null;

  // public utils = new LoginUtils(this.profileStore);

  // get currentOrganizationNameShort() {
  //   const name = this.profileStore.currOrganization.organizationName;
  //   if (name === undefined) {
  //     return '';
  //   }
  //   if (name.length > 10) {
  //     return `${name.substring(0, 10)}...`;
  //   }
  //   return name;
  // }

  // get organizations(): Organization[] {
  //   if (this.profileStore === undefined || this.profileStore.organizations === undefined) return [];
  //   return this.profileStore.organizations;
  // }

  // pickOrg(index: number, org: Organization) {
  //   this.profileStore.setCurrentByUsersIndex(index);
  //   if (this.profileStore.userIndex >= 0) {
  //     this.authStore.setUserId(this.profileStore.currUser.userId || 'UNSET');
  //     this.authStore.setOrganizationId(this.profileStore.currOrganization.organizationId || 'UNSET');
  //   }
  //   const utils = new LoginUtils(this.profileStore);
  //   // const url = utils.getDomainHref(this.profileStore.currOrganization.adminUrl, 'https://console.funfangle.com');
  //   // window.location.href = url;
  //   window.location.href = utils.getDomainHref('https://console.funfangle.com', 'https://console.funfangle.com');
  // }

  // showGreeting: boolean = true;
  // handleClickedNavbar(): void {
  //   // console.log('handleClickedNavbar');
  // }
  handleClickedSettings(): void {
    // console.log('handleClickedSettings');
    this.$router.push({ name: RouterNames.SETTINGS });
  }
  toggleHamburgerIcon(): void {
    // console.log('toggleHamburgerIcon');
    this.$emit('toggleSideMenu');
  }
}
</script>
