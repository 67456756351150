var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isConsole
    ? _c("app-console", { attrs: { "data-app": "" } })
    : !_vm.isConsole
    ? _c("app-portal", { attrs: { "data-app": "" } })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }