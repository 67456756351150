import { getModule } from 'vuex-module-decorators';
import { InventoryModule, store } from '@/store';
import { Inventory, InventoryCode, InventoryPurchaseOrder } from '@fgl/funfangle-sdk/dist/rest/v3/inventory';
import { InventoryApiClient } from '@/shared/api';

export class InventoryService {
  //
  // Codes
  //

  // async listCodes(): Promise<InventoryCode[] | null> {
  //   const inventoryStore: InventoryModule = getModule(InventoryModule, store);

  //   // TODO: Implement pagination
  //   // if (this.itemFetchCount > 0 && (this.itemFetchLastKey == null || this.itemFetchLastKey === undefined) && inventoryStore._items) {
  //   //   return Promise.resolve(inventoryStore._items);
  //   // }

  //   // if (inventoryStore._items && inventoryStore.countItems > 0) {
  //   //   return Promise.resolve(inventoryStore._items);
  //   // }

  //   let items: Inventory[] | null = null;
  //   const client = new InventoryApiClient();
  //   await client
  //     .listCodes()
  //     .then((response) => {
  //       if (response && response !== undefined) {
  //         items = [];
  //         // this.tableData = response.data.items || [];
  //         this.itemFetchLastKey = response.data.lastKey;
  //         items.push(...response.data.items);
  //         inventoryStore.setItems(items);
  //         return Promise.resolve(items);
  //       }
  //     })
  //     .catch((error) => {
  //       return Promise.reject(error);
  //     });

  //   return Promise.resolve(items);
  // }

  //
  // Inventory Items
  //

  async getItem(itemId?: string): Promise<Inventory | null> {
    if (itemId == null || itemId === undefined) return Promise.reject(new Error('No item ID provided'));
    const inventoryStore: InventoryModule = getModule(InventoryModule, store);
    let item: Inventory | null = null;

    if (inventoryStore.item && itemId === inventoryStore.item.itemId) {
      item = inventoryStore.item;
      if (item != null && item !== undefined) return Promise.resolve(item);
    }

    if (inventoryStore.items) {
      for (const i of inventoryStore.items) {
        if (i && i.itemId === itemId) {
          item = i;
          if (item != null && item !== undefined) return Promise.resolve(item);
        }
      }
    }

    const client = new InventoryApiClient();
    await client
      .getItem(undefined, itemId)
      .then((response) => {
        // console.log('getItem response', response);
        if (response && response.data && response.data.item) item = response.data.item;
        // item = response.item;
      })
      .catch((error: Error) => {
        return Promise.reject(error);
      });

    return Promise.resolve(item);
  }

  itemFetchCount = 0;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  itemFetchLastKey: any = null;

  async listItems(): Promise<Inventory[] | null> {
    const inventoryStore: InventoryModule = getModule(InventoryModule, store);

    // TODO: Implement pagination
    if (this.itemFetchCount > 0 && (this.itemFetchLastKey == null || this.itemFetchLastKey === undefined) && inventoryStore._items) {
      return Promise.resolve(inventoryStore._items);
    }

    if (inventoryStore._items && inventoryStore.countItems > 0) {
      return Promise.resolve(inventoryStore._items);
    }

    let items: Inventory[] | null = null;
    const client = new InventoryApiClient();
    await client
      .listItems()
      .then((response) => {
        if (response && response !== undefined) {
          items = [];
          // this.tableData = response.data.items || [];
          this.itemFetchLastKey = response.data.lastKey;
          items.push(...response.data.items);
          inventoryStore.setItems(items);
          return Promise.resolve(items);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    return Promise.resolve(items);
  }

  //
  // Purchase Orders
  //

  async getPurchaseOrder(orderId?: string): Promise<InventoryPurchaseOrder | null> {
    if (orderId == null || orderId === undefined) return Promise.reject(new Error('No purchase order ID provided'));
    const inventoryStore: InventoryModule = getModule(InventoryModule, store);
    let order: InventoryPurchaseOrder | null = null;

    if (inventoryStore.purchaseOrders) {
      for (const i of inventoryStore.purchaseOrders) {
        if (i && i.purchaseId === orderId) {
          order = i;
          if (order != null && order !== undefined) return Promise.resolve(order);
        }
      }
    }

    const client = new InventoryApiClient();
    await client
      .listPurchaseOrders()
      .then((response) => {
        // console.log(response);
        if (response && response !== undefined) {
          // this.tableData = response.data.items || [];
          // this.fetchLastKey = response.data.lastKey;
          inventoryStore.setPurchaseOrders(response.data.items);
          for (const i of response.data.items) {
            if (i && i.purchaseId === orderId) {
              order = i;
              if (order != null && order !== undefined) return Promise.resolve(order);
            }
          }
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    return Promise.resolve(order);
  }

  async listPurchaseOrders(): Promise<InventoryPurchaseOrder[] | null> {
    const inventoryStore: InventoryModule = getModule(InventoryModule, store);
    let orders: InventoryPurchaseOrder[] | null = null;

    if (inventoryStore.purchaseOrders) {
      return Promise.resolve(inventoryStore.purchaseOrders);
    }

    const client = new InventoryApiClient();
    await client
      .listPurchaseOrders()
      .then((response) => {
        // console.log(response);
        if (response && response !== undefined) {
          // this.tableData = response.data.items || [];
          // this.fetchLastKey = response.data.lastKey;
          inventoryStore.setPurchaseOrders(response.data.items);
          orders = response.data.items;
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    return Promise.resolve(orders);
  }
}
