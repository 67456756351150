var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app-bar",
    { attrs: { color: "light-blue" } },
    [
      _c("v-app-bar-nav-icon", {
        staticClass: "grey--text white-text",
        on: { click: _vm.toggleHamburgerIcon },
      }),
      _c("v-spacer"),
      _c(
        "v-icon",
        {
          staticClass: "white-text",
          staticStyle: { cursor: "pointer" },
          nativeOn: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.handleClickedSettings.apply(null, arguments)
            },
          },
        },
        [_vm._v(_vm._s(_vm.mdiCog))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }