<template>
  <div>
    <!-- header stuff? -->
    <on-boarding-welcome v-if="isWelcome" @next="handleNextClickFromWelcome" />
    <on-boarding-about-them v-if="isAboutThem" @next="handleNextClickFromAboutThem" />
    <time-zone-component v-if="isTimeZone" @next="handleNextClickFromTimeZone" />
    <first-event-registration-video-component v-if="isScheduleVideo" @next="handleNextClickFromFirstScheduleVideo" />
    <!-- footer stuff? -->
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { mdbAlert, mdbBtn, mdbCol, mdbContainer, mdbFormInline, mdbIcon, mdbInput, mdbRow, mdbSelect, mdbSpinner } from 'mdbvue';
import { ApiEndpoints } from './../../constants';
import { ApiService } from '@/shared/auth/auth-cognito';
import { OrganizationRequest } from '@fgl/funfangle-sdk/dist/rest/profile';
import { Session } from '@fgl/funfangle-sdk/dist/rest/session';
import { getModule } from 'vuex-module-decorators';
import { ProfileModule } from '@/store/ProfileModule';
import { SessionModule } from '@/store/SessionModule';
import { Constants } from '@/constants';
import * as RouterIcons from '@/router/RouterIcons';
import * as RouterNames from '@/router/RouterNames';
import OnBoardingAboutThem from '@/views/console/OnBoardingAboutThem.vue';
import OnBoardingWelcome from '@/views/console/OnBoardingWelcome.vue';
import TimeZoneComponent from '@/views/console/setting/config/TimeZoneComponent.vue';
import FirstEventRegistrationVideoComponent from '@/views/console/session/help/FirstEventRegistrationComponent.vue';

@Component({
  components: {
    mdbAlert,
    mdbBtn,
    mdbCol,
    mdbContainer,
    mdbFormInline,
    mdbIcon,
    mdbInput,
    mdbRow,
    mdbSelect,
    mdbSpinner,
    FirstEventRegistrationVideoComponent,
    OnBoardingAboutThem,
    OnBoardingWelcome,
    TimeZoneComponent,
  },
})
export default class OnBoarding extends Vue {
  errorMessage: string | null = null;
  isLoading = true;
  profileStore: ProfileModule = getModule(ProfileModule, this.$store);
  sessionStore: SessionModule = getModule(SessionModule, this.$store);
  public routerNames = require('@/router/RouterNames');
  statusSet: Set<string> = new Set<string>();

  isTimeZone = false;
  isWelcome = false;
  isAboutThem = false;
  isScheduleVideo = false;
  isScheduleSetup = false;
  isUserRequiredFields = false;
  isUserRequiredPhoto = false;
  isUserRequiredWaiver = false;

  handleNextClickFromWelcome() {
    this.removeStatus('WELCOME');
    this.selectComponent();
  }

  handleNextClickFromAboutThem() {
    this.removeStatus('ABOUT_THEM');
    this.selectComponent();
  }

  handleNextClickFromTimeZone() {
    this.removeStatus('TIMEZONE');
    this.selectComponent();
  }

  handleNextClickFromFirstScheduleVideo() {
    this.removeStatus('FIRST_SCHEDULE_VIDEO');
    this.selectComponent();
  }

  handleNextClickFromFirstScheduleSetup() {
    this.removeStatus('FIRST_SCHEDULE_SETUP');
    this.selectComponent();
  }

  handleNextClickFromUserRequredFields() {
    this.removeStatus('USER_REQUIRED_FIELDS');
    this.selectComponent();
  }

  handleSkipClick() {
    this.selectComponent();
  }

  async mounted() {
    const status = this.profileStore.currOrganization.organizationStatus;
    if (status !== undefined) {
      this.statusSet = new Set(status);
    }
    this.reset();
    this.selectComponent();
  }

  async removeStatus(status: string) {
    if (this.statusSet.has(status)) {
      this.statusSet.delete(status);
      // update server record
      const orgRequest = new OrganizationRequest(this.profileStore.currOrganization.organizationId || 'UNSET');
      orgRequest.organizationStatus = Array.from(this.statusSet.keys());
      const url = `${ApiEndpoints.apiEndpoint}/v2/profile/organization`;
      const apiSvc = new ApiService();
      await apiSvc
        .httpPost(url, orgRequest)
        .then(() => {
          // nothing
        })
        .catch((err: Error) => {
          this.errorMessage = err.message;
          // console.error(err, request);
        });
      // post to ...
    }
  }

  reset() {
    this.errorMessage = null;
  }

  selectComponent() {
    this.isTimeZone = false;
    this.isAboutThem = false;
    this.isWelcome = false;
    this.isScheduleVideo = false;
    this.isScheduleSetup = false;
    this.isUserRequiredFields = false;
    this.isUserRequiredPhoto = false;
    this.isUserRequiredWaiver = false;

    if (this.statusSet.has('WELCOME')) {
      this.isWelcome = true;
    } else if (this.statusSet.has('TIMEZONE')) {
      this.isTimeZone = true;
    } else if (this.statusSet.has('ABOUT_THEM')) {
      this.isAboutThem = true;
      // } else if (this.statusSet.has('USER_REQUIRED_FIELDS')) {
      //   this.isUserRequiredFields = true;
      // } else if (this.statusSet.has('USER_REQUIRED_PHOTO')) {
      //   this.isUserRequiredPhoto = true;
      // } else if (this.statusSet.has('USER_REQUIRED_WAIVER')) {
      //   this.isUserRequiredWaiver = true;
    } else if (this.statusSet.has('FIRST_SCHEDULE_VIDEO')) {
      this.isScheduleVideo = true;
    } else if (this.statusSet.has('FIRST_SCHEDULE_SETUP')) {
      this.isScheduleSetup = true;
      // exit onboarding ...
      this.profileStore.setOrgStatus(Array.from(this.statusSet.keys()));
      this.$router.push({ name: 'activities' });
    } else {
      // exit onboarding ...
      this.profileStore.setOrgStatus(Array.from(this.statusSet.keys()));
      this.$router.push({ name: 'home' });
    }
  }
}
</script>

<style></style>
