import { store } from '@/store/Store';
import { Module, VuexModule } from 'vuex-module-decorators';
// import { ApiService } from '@/shared/auth/auth-cognito';
import { getModule } from 'vuex-module-decorators';
import { ProfileModule } from './ProfileModule';

@Module({ dynamic: true, store, name: 'permission' })
export class PermissionModule extends VuexModule {
  profileStore: ProfileModule = getModule(ProfileModule);

  get accountCreateAllowed(): boolean {
    return this.profileStore?.user?.role === 'organization-manager';
  }

  get accountDeletionAllowed(): boolean {
    return this.profileStore?.user?.role === 'organization-manager';
  }

  get assumedUserAllowed(): boolean {
    return this.profileStore?.user?.role === 'organization-manager';
  }

  get participantCreateAllowed(): boolean {
    return this.profileStore?.user?.role === 'organization-manager';
  }
}
