import { DateTime } from 'luxon';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const zoneJSON = require('tzdata');

export class TimeZoneElements {
  commonZones = [
    {
      common: 'US/Eastern',
      zone: 'America/New_York',
    },
    {
      common: 'US/Central',
      zone: 'America/Chicago',
    },
    {
      common: 'US/Mountain',
      zone: 'America/Denver',
    },
    {
      common: 'US/Pacific',
      zone: 'America/Los_Angeles',
    },
    {
      common: 'US/Alaska',
      zone: 'America/Anchorage',
    },
    {
      common: 'US/Hawaii',
      zone: 'Pacific/Honolulu',
    },
    {
      common: 'CA/Pacific',
      zone: 'America/Vancouver',
    },
    {
      common: 'CA/Mountain',
      zone: 'America/Edmonton',
    },
    {
      common: 'CA/Central',
      zone: 'America/Winnipeg',
    },
    {
      common: 'CA/Eastern',
      zone: 'America/Toronto',
    },
    {
      common: 'CA/Atlantic',
      zone: 'America/Halifax',
    },
  ];

  getDefaultTimezone(): string {
    const locale = window.navigator.language;
    if (locale === 'en-US') return 'America/New_York';
    if (locale === 'es-US') return 'America/Los_Angeles';
    if (locale === 'en-CA') return 'America/Toronto';
    if (locale === 'fr-CA') return 'America/Montreal';
    if (locale === 'en-GB') return 'Europe/London';
    return 'America/New_York';
  }

  getOfficialTimezone(timeZone: string): string {
    let tz = timeZone;
    for (const cz of this.commonZones) {
      if (tz === cz.common) tz = cz.zone;
    }
    if (tz === 'America/Hawaii') tz = 'Pacific/Honolulu';
    return tz;
  }

  getTimeZones(): string[] {
    const timeZones: string[] = [];
    if (zoneJSON && zoneJSON.zones) {
      for (const [zoneName, v] of Object.entries(zoneJSON.zones)) {
        if (DateTime.local().setZone(zoneName).isValid) timeZones.push(zoneName);
      }
    }
    for (const cz of this.commonZones) {
      timeZones.push(cz.common);
    }
    timeZones.sort(this.sortZones);
    return timeZones;
  }

  sortZones(a: string, b: string): number {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  }
}
