var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-navigation-drawer",
    {
      staticStyle: { "background-color": "whitesmoke" },
      attrs: { permanent: _vm.menuShown, temporary: !_vm.menuShown, app: "" },
      model: {
        value: _vm.menuShown,
        callback: function ($$v) {
          _vm.menuShown = $$v
        },
        expression: "menuShown",
      },
    },
    [
      _c(
        "v-list-item",
        { staticClass: "active-tile" },
        [
          !_vm.showOrganizationSearch
            ? [
                _c(
                  "v-list-item-avatar",
                  [
                    _c("v-img", {
                      attrs: { src: "/img/icons/android-chrome-192x192.png" },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-list-item-content",
                  [
                    _vm.currentOrganizationNameShort === ""
                      ? _c("v-skeleton-loader", {
                          attrs: { type: "text", height: "15" },
                        })
                      : _vm._e(),
                    _c("v-list-item-title", { staticClass: "text-h6" }, [
                      _vm._v(
                        " " + _vm._s(_vm.currentOrganizationNameShort) + " "
                      ),
                    ]),
                    _c(
                      "v-list-item-subtitle",
                      { staticStyle: { "font-size": "x-small" } },
                      [_vm._v(" FunFangle Console ")]
                    ),
                  ],
                  1
                ),
                _vm.organizations !== undefined && _vm.organizations.length > 1
                  ? _c(
                      "v-icon",
                      {
                        staticClass: "float-right",
                        staticStyle: { cursor: "pointer" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            _vm.showOrganizationSearch =
                              !_vm.showOrganizationSearch
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.mdiChevronDownCircle))]
                    )
                  : _vm._e(),
              ]
            : [
                _c("v-autocomplete", {
                  attrs: {
                    items: _vm.organizations,
                    color: "white",
                    "hide-no-data": "",
                    "hide-selected": "",
                    "item-text": "organizationName",
                    "item-value": "organizationId",
                    label: "Organizations",
                    placeholder: "Start typing to search",
                    "prepend-icon": "mdi-magnify",
                    "return-object": "",
                  },
                  on: {
                    blur: function ($event) {
                      _vm.showOrganizationSearch = !_vm.showOrganizationSearch
                    },
                  },
                  model: {
                    value: _vm.selectedOrganization,
                    callback: function ($$v) {
                      _vm.selectedOrganization = $$v
                    },
                    expression: "selectedOrganization",
                  },
                }),
              ],
        ],
        2
      ),
      _vm.hasMessages
        ? [
            _c(
              "v-list",
              {
                key: `message-list-key`,
                staticClass: "mt-2",
                attrs: { nav: "" },
              },
              [
                _vm._l(_vm.messages, function (item, index) {
                  return [
                    _c(
                      "v-list-item",
                      { key: index },
                      [
                        _c(
                          "v-list-item",
                          [
                            _c(
                              "v-alert",
                              {
                                attrs: {
                                  color: item.color,
                                  type: item.type,
                                  dismissible: "",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    style: item.to
                                      ? "cursor: pointer; text-decoration: underline;"
                                      : "",
                                    on: {
                                      click: function ($event) {
                                        return _vm.goToMessage(item)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(item.text))]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                }),
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm.currentOrganizationNameShort === ""
        ? [
            _c(
              "v-list",
              { staticClass: "mt-2", attrs: { nav: "" } },
              [
                _c("v-skeleton-loader", {
                  staticClass: "mt-2",
                  attrs: { type: "text", height: "15" },
                }),
                _vm._l(_vm.menuBottom, function (item, index) {
                  return [
                    _c(
                      "v-list-item",
                      {
                        key: index,
                        attrs: { href: item.href, to: item.to, link: "" },
                      },
                      [
                        _c(
                          "v-list-item-icon",
                          { staticStyle: { "min-width": "36px" } },
                          [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                          1
                        ),
                        _c(
                          "v-list-item",
                          [
                            item.child === undefined
                              ? _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(_vm._s(item.title)),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                }),
              ],
              2
            ),
          ]
        : [
            _vm._l(_vm.menus, function (menu, menuIndex) {
              return [
                menuIndex > 0
                  ? _c("v-divider", { key: `${menuIndex}-divider-key` })
                  : _vm._e(),
                _c(
                  "v-list",
                  {
                    key: `${menuIndex}-list-key`,
                    staticClass: "mt-2",
                    attrs: { nav: "" },
                  },
                  [
                    _vm._l(menu, function (item, index) {
                      return [
                        item.child !== undefined && item.child.length > 0
                          ? _c(
                              "v-list-group",
                              {
                                key: index,
                                class: item.showChildren
                                  ? "active-tile"
                                  : undefined,
                                attrs: { value: item.showChildren },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function () {
                                        return [
                                          _c(
                                            "v-list-item-icon",
                                            {
                                              staticStyle: {
                                                "min-width": "36px",
                                              },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v(_vm._s(item.icon)),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item",
                                            {
                                              attrs: {
                                                href: item.href,
                                                to: item.to,
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(_vm._s(item.title)),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              _vm._l(item.child, function (subitem, i) {
                                return _c(
                                  "v-list-item",
                                  {
                                    key: i,
                                    attrs: {
                                      href: subitem.href,
                                      to: subitem.to,
                                      link: "",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      { staticStyle: { "min-width": "36px" } },
                                      [
                                        _c("v-icon", [
                                          _vm._v(
                                            _vm._s(
                                              subitem.icon ||
                                                _vm.mdiChevronDoubleRight
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c("v-list-item-title", [
                                      _vm._v(_vm._s(subitem.title)),
                                    ]),
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          : _c(
                              "v-list-item",
                              {
                                key: index,
                                attrs: {
                                  href: item.href,
                                  to: item.to,
                                  link: "",
                                },
                              },
                              [
                                _c(
                                  "v-list-item-icon",
                                  { staticStyle: { "min-width": "36px" } },
                                  [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                                  1
                                ),
                                _c(
                                  "v-list-item",
                                  [
                                    item.child === undefined
                                      ? _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(_vm._s(item.title)),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                      ]
                    }),
                  ],
                  2
                ),
              ]
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }