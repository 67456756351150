var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c("b-row", [
        _c(
          "div",
          { staticClass: "col-md-4 col-md-offset-4 mt-2 mb-3 mx-auto" },
          [
            _c(
              "div",
              { staticClass: "panel panel-default" },
              [
                _c(
                  "b-alert",
                  {
                    staticClass: "text-center",
                    attrs: { variant: "info", show: "" },
                  },
                  [_vm._v(" A two-minute instructional video ... ")]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _c("b-row", [
        _c("div", { staticClass: "my-1 mx-auto" }, [
          _c("iframe", {
            attrs: {
              width: _vm.width,
              height: _vm.height,
              frameborder: "0",
              scrolling: "no",
              src: "https://screencast-o-matic.com/embed?sc=cY1jVZCfHy&v=6&ff=1&title=0&controls=1&a=1",
              allowfullscreen: "true",
            },
          }),
        ]),
      ]),
      _c("b-row", [
        _c("div", { staticClass: "col-md-4 col-md-offset-4 my-3 mx-auto" }, [
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                "mdb-btn",
                {
                  attrs: { disabled: !_vm.formIsValid },
                  on: {
                    click: function ($event) {
                      return _vm.handleContinueClick()
                    },
                  },
                },
                [_vm._v(" Continue ")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }