import { ApiEndpoints } from '../constants';
import { store } from '@/store/Store';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { User, UsersResponse } from '@fgl/funfangle-sdk/dist/rest/profile';
import { ApiService } from '@/shared/auth/auth-cognito';
import { getModule } from 'vuex-module-decorators';
import { ProfileModule } from './ProfileModule';

const apiSvc = new ApiService();

@Module({ dynamic: true, store, name: 'orgs' })
export class OrgsModule extends VuexModule {
  profileStore: ProfileModule = getModule(ProfileModule);
  //
  orgParticipants: User[] = [];
  orgUsers: User[] = [];

  @Action
  public async fetchOrgParticipants(): Promise<UsersResponse> {
    // this.context.dispatch
    const response = await apiSvc.httpGet(`${ApiEndpoints.apiEndpoint}/v2/profile/participants?organizationId=${this.profileStore.currOrganization.organizationId}`).catch((err: Error) => {
      return Promise.reject(err);
    });
    if (!response || response === undefined) {
      return Promise.reject(new Error('No users returned. Try again later.'));
    } else {
      this.setOrgParticipants(response);
    }
    // this.setOrgUsersResponse(res);
    return Promise.resolve(response);
  }

  @Action
  public async fetchOrgUsers(params?: { contains?: string; limit?: number; pageIndex?: number; hideInactive?: boolean }): Promise<User[]> {
    // if ((params === undefined || params.contains === undefined || params.contains.length < 2) {
    //   const e = new Error('Contains must have value of at least two characters');
    //   e.name = 'INSUFFICIENT_CONTAINS';
    //   return Promise.reject(e);
    // }

    if (params === undefined) params = {};

    if (params.limit === undefined || params?.limit === -1 || Number.isNaN(params.limit)) {
      params.limit = 2500;
    }
    if (params.hideInactive === undefined) {
      params.hideInactive = true;
    }

    // this.context.dispatch
    let url = `${ApiEndpoints.apiEndpoint}/v2/profile/users?organizationId=${this.profileStore.currOrganization.organizationId}`;
    // url = `${url}&fields=${encodeURIComponent('cabinName,currentBalance,imageUrl,session,verified')}`;
    if (params.hideInactive !== undefined) url = url + '&hideInactive=' + params.hideInactive;
    // if (roles !== undefined) url = url + '&roles=' + roles.map((value) => encodeURIComponent(value)).join(',');
    if (params !== undefined && params.contains !== undefined && params.contains !== '' && params.contains.length > 1) url = `${url}&contains=${params.contains}`;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let lastKey: any;
    let pageIndex = 0;
    const users: User[] = [];

    do {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const options: any = {
        // limit: 100,
        pageSize: params.limit,
      };
      if (lastKey !== undefined) options.startKey = lastKey;
      const getURL = `${url}&options=${encodeURIComponent(JSON.stringify(options))}`;

      const res = await apiSvc.httpGet(getURL).catch((err: Error) => {
        return Promise.reject(err);
      });
      if (!res || res === undefined || res.data === undefined || res.data.items === undefined) {
        return Promise.reject(new Error('No users returned. Try again later.'));
      } else if (pageIndex === 0) {
        this.setOrgUsers(res.data.items);
      } else {
        this.appendOrgUsers(res.data.items);
      }
      users.push(...res.data.items);

      if (params.pageIndex !== undefined && pageIndex === params.pageIndex) return Promise.resolve(users);

      lastKey = res.data.lastKey;
      pageIndex += 1;
    } while (lastKey !== undefined);

    // this.setOrgUsersResponse(res);
    return Promise.resolve(users);
  }

  /**
   *
   */
  get orgUsersItems(): User[] {
    if (this.orgUsers == null || this.orgUsers === undefined) {
      return [];
    }
    return this.orgUsers;
  }

  // get orgUsersTable(): TableBvResponseBody<User> {
  //   if (this.orgUsers == null || this.orgUsers === undefined || this.orgUsers.data === undefined) {
  //     return new TableBvResponseBody<User>();
  //   }
  //   return this.orgUsers.data;
  // }

  @Mutation
  public setOrgParticipants(users: any): void {
    this.orgParticipants = [];
    this.orgParticipants = this.orgParticipants.concat(users);
  }

  @Mutation
  public appendOrgUsers(users: User[]): void {
    if (users !== undefined) {
      this.orgUsers = this.orgUsers.concat(users);
    }
  }

  @Mutation
  public setOrgUsers(users: User[]): void {
    this.orgUsers = [];
    if (users !== undefined) {
      this.orgUsers = this.orgUsers.concat(users);
    }
  }

  @Mutation
  public setOrgUsersResponse(users: any): void {
    this.orgUsers = [];
    if (users !== undefined && users.data !== undefined && users.data.items !== undefined) {
      this.orgUsers = this.orgUsers.concat(users.data.items);
    }
  }
}

export class OrgIds {
  public static USA_IL_BPD = '363d40e5-e923-41f7-a53c-cff8abb1ea6b';
}
