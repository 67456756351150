import { ApiEndpoints } from '../constants';
import { store } from '@/store/Store';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import {
  LodgingUnitsResponse,
  LodgingUnit,
  LodgingStaysResponse,
  LodgingStay,
  LodgingStayCreateRequest,
  LodgingGuestInvitationResponse,
  LodgingGuestInvitationResponseBody,
} from '@fgl/funfangle-sdk/dist/rest/lodging';
import { ApiService } from '@/shared/auth/auth-cognito';
import { getModule } from 'vuex-module-decorators';
import { ProfileModule } from './ProfileModule';

const apiSvc = new ApiService();

@Module({ dynamic: true, store, name: 'lodging' })
export class LodgingModule extends VuexModule {
  profileStore: ProfileModule = getModule(ProfileModule);

  staysExpiresAt?: number;
  stays: LodgingStay[] = [];
  orgUnits: LodgingUnit[] = [];
  householdExpiresAt?: number;
  householdUnits: LodgingUnit[] = [];
  managedUnitsExpiresAt?: number;
  managedUnits: LodgingUnit[] = [];
  ownedUnitsExpiresAt?: number;
  ownedUnits: LodgingUnit[] = [];
  //
  unitsExpiresAt: number | undefined;
  unitsFilter = '';
  unitsResponse: LodgingUnitsResponse | undefined;

  get units(): LodgingUnit[] {
    if (this.unitsResponse === undefined || this.unitsResponse.getBodyObj() === undefined) return [];
    return this.unitsResponse.getBodyObj().items || [];
  }

  get userUnits(): LodgingUnit[] {
    const units = this.managedUnits.concat(this.ownedUnits);
    units.sort((a, b) => ((a.label || '') > (b.label || '') ? 1 : -1));
    return units;
  }

  @Action
  public async addStay(payload: LodgingStayCreateRequest): Promise<LodgingGuestInvitationResponseBody> {
    // const organizationId = this.organizationId
    // const userId = this.userId
    // payload.accountHolderId = userId
    // payload.organizationId = organizationId
    // TODO: upgrade to v2/api
    const url = `${ApiEndpoints.apiEndpoint}/v2/lodging/stay/create`;
    // console.warn(`Is this url correct? ${url}`);
    const result: LodgingGuestInvitationResponse = await apiSvc.httpPost(url, payload).catch((e: Error) => {
      return Promise.reject(e);
    });
    // console.log('addAuthorizedUser ', result);
    if (result && result !== undefined && result.data !== undefined && result.data.item !== undefined && result.data.item.stay !== undefined) {
      this.appendStay(result.data.item.stay);
    }
    return Promise.resolve(result.data);
  }

  async fetchOrgLodging() {
    const url = `${ApiEndpoints.apiEndpoint}/v2/profile/organization/lodgings?organizationId=${this.profileStore.organizationId}`;
    // console.log('fetchLodging url...', url)
    // this.isLoading = true;
    await apiSvc
      .httpGet(url)
      .then((res) => {
        // console.log('fetchLodging: ', res)
        if (res !== undefined && res.data !== undefined && res.data.items !== undefined) {
          this.setOrganizationUnits(res.data.items);
        }
      })
      .catch((err) => {
        // console.log("fetchLodging err:", err);
        // resolve(err)
      });
    // this.isLoading = false;
    return Promise.resolve();
  }

  @Action
  async fetchStays(fetchFresh = false): Promise<LodgingUnit[]> {
    if (!fetchFresh && this.staysExpiresAt !== undefined && this.staysExpiresAt > Date.now()) return Promise.resolve(this.stays);
    // set params and url
    const organizationId = this.profileStore.organizationId;
    const userId = this.profileStore.userId;
    let url = `${ApiEndpoints.apiEndpoint}/v2/lodging/stays?organizationId=${organizationId}`;
    /*if (this.profileStore.isPropertyManager)*/ url = `${url}&agentId=${userId}`;
    // /*if (this.profileStore.isPropertyManager)*/ url = `${url}&managerId=${userId}`
    /*if (this.profileStore.isPropertyOwner)*/ url = `${url}&ownerId=${userId}`;
    url = `${url}&sortOrder=asc`;
    const stayRes: LodgingStaysResponse | void = await apiSvc.httpGet(url).catch((err) => {
      return Promise.reject(err);
    });
    if (stayRes && stayRes !== undefined && stayRes.data !== undefined && stayRes.data.items !== undefined) {
      this.setStays(stayRes.data.items);
      return Promise.resolve(stayRes.data.items);
    }
    return Promise.resolve([]);
  }

  @Action
  async fetchHouseholdUnits(): Promise<LodgingUnit[]> {
    // await this.fetchManagedUnits().catch(err => {
    //   return Promise.reject(err);
    // });
    // await this.fetchOwnedUnits().catch(err => {
    //   return Promise.reject(err);
    // });
    if (this.householdExpiresAt !== undefined && this.householdExpiresAt > Date.now()) return Promise.resolve(this.householdUnits);
    const organizationId = this.profileStore.organizationId;
    const userId = this.profileStore.userId;
    const url = `${ApiEndpoints.apiEndpoint}/v2/lodging/household/units?householdId=${userId}&organizationId=${organizationId}`;
    const lodgingRes: LodgingUnitsResponse | void = await apiSvc.httpGet(url).catch((err) => {
      return Promise.reject(err);
    });
    if (lodgingRes && lodgingRes !== undefined && lodgingRes.data !== undefined && lodgingRes.data.items !== undefined) {
      this.setHouseholdUnits(lodgingRes.data.items);
      return Promise.resolve(lodgingRes.data.items);
    }
    return Promise.resolve(this.managedUnits);
  }

  @Action
  async fetchManagedUnits(): Promise<LodgingUnit[]> {
    if (this.managedUnitsExpiresAt !== undefined && this.managedUnitsExpiresAt > Date.now()) return Promise.resolve(this.managedUnits);
    const organizationId = this.profileStore.organizationId;
    const userId = this.profileStore.userId;
    const url = `${ApiEndpoints.apiEndpoint}/v2/lodging/manager/units?managerId=${userId}&organizationId=${organizationId}`;
    const lodgingRes: LodgingUnitsResponse | void = await apiSvc.httpGet(url).catch((err) => {
      return Promise.reject(err);
    });
    if (lodgingRes && lodgingRes !== undefined && lodgingRes.data !== undefined && lodgingRes.data.items !== undefined) {
      this.setManagedUnits(lodgingRes.data.items);
      return Promise.resolve(lodgingRes.data.items);
    }
    return Promise.resolve(this.managedUnits);
  }

  @Action
  async fetchOwnedUnits(): Promise<LodgingUnit[]> {
    if (this.ownedUnitsExpiresAt !== undefined && this.ownedUnitsExpiresAt > Date.now()) return Promise.resolve(this.ownedUnits);
    const organizationId = this.profileStore.organizationId;
    const userId = this.profileStore.userId;
    const url = `${ApiEndpoints.apiEndpoint}/v2/lodging/owner/units?ownerId=${userId}&organizationId=${organizationId}`;
    const lodgingRes: LodgingUnitsResponse | void = await apiSvc.httpGet(url).catch((err) => {
      return Promise.reject(err);
    });
    if (lodgingRes && lodgingRes !== undefined && lodgingRes.data !== undefined && lodgingRes.data.items !== undefined) {
      this.setOwnedUnits(lodgingRes.data.items);
      return Promise.resolve(lodgingRes.data.items);
    }
    return Promise.resolve(this.ownedUnits);
  }

  @Action
  public async fetchUnits(): Promise<LodgingUnitsResponse | undefined> {
    const fetchFresh = false;
    // if (request !== undefined) {
    //   ({ organizationId, fetchFresh } = request);
    // }
    if (!fetchFresh && this.unitsExpiresAt !== undefined && this.unitsExpiresAt > Date.now()) return Promise.resolve(this.unitsResponse);
    let res: LodgingUnitsResponse | undefined;
    // this.context.dispatch
    await apiSvc
      .httpGet(`${ApiEndpoints.apiEndpoint}/v2/lodging/units?organizationId=${this.profileStore.organizationId}`)
      .then((response: LodgingUnitsResponse) => {
        res = response;
        this.setUnitsResponse(response);
      })
      .catch((err: Error) => {
        return Promise.reject(err);
      });
    if (res === undefined) {
      return Promise.reject(new Error('No units returned.'));
    }
    return Promise.resolve(res);
  }

  @Mutation
  public setHouseholdUnits(units: LodgingUnit[]) {
    this.householdUnits = [];
    this.householdUnits = this.householdUnits.concat(units);
    this.householdExpiresAt = Date.now() + 15 * 60 * 1000;
  }

  @Mutation
  public setManagedUnits(units: LodgingUnit[]) {
    this.managedUnits = [];
    this.managedUnits = this.managedUnits.concat(units);
    this.managedUnitsExpiresAt = Date.now() + 15 * 60 * 1000;
  }

  @Mutation
  public setOrganizationUnits(units: LodgingUnit[]) {
    this.orgUnits = [];
    this.orgUnits = this.orgUnits.concat(units);
  }

  @Mutation
  public setOwnedUnits(units: LodgingUnit[]) {
    this.ownedUnits = [];
    this.ownedUnits = this.ownedUnits.concat(units);
    this.ownedUnitsExpiresAt = Date.now() + 15 * 60 * 1000;
  }

  @Mutation
  public setUnitsFilter(filter: string): void {
    this.unitsFilter = filter;
  }

  @Mutation
  public setUnitsResponse(response: LodgingUnitsResponse): void {
    this.unitsResponse = response;
    this.unitsExpiresAt = Date.now() + 30 * 60 * 1000; // expires in thirty minutes
  }

  @Mutation
  public appendStay(stay: LodgingStay | undefined): void {
    if (stay === undefined) return;
    if (this.stays === undefined) this.stays = [];
    this.stays.push(stay);
  }

  @Mutation
  public setStays(stays: LodgingStay[]) {
    this.stays = [];
    this.stays = this.stays.concat(stays);
    this.staysExpiresAt = Date.now() + 15 * 60 * 1000;
  }
}
