import Vue from 'vue';
import { ApiEndpoints } from '../constants';
import { store } from '@/store/Store';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { DetailsOnLoginResponse, User, DetailsOnLogin, Organization, UserRequest, SquareInfo, UserPair } from '@fgl/funfangle-sdk/dist/rest/profile';
import { CreateCustomerRequest } from '@fgl/funfangle-sdk/dist/rest/bank/square';
import { ApiService } from '@/shared/auth/auth-cognito';
import { AuthCookies } from '@/shared/auth/auth-cognito/AuthCookies';
import { Vendor } from '@fgl/funfangle-sdk/dist/rest/v3/profile/vendor';

const apiSvc = new ApiService();

@Module({ dynamic: true, store, name: 'profile' })
export class ProfileModule extends VuexModule {
  // current selected organization of the user
  currOrganization: Organization = new Organization();
  // current email
  // currEmail: string | undefined;
  // current selected user
  currUser: User = new User();
  currUserPairs: UserPair[] = [];
  // current user roles mapped by organization id
  currUserRoles: Map<string, string[]> = new Map<string, string[]>();
  //
  isParent2 = false;
  // //
  // loginEmail: string | undefined;
  // index of currently selected user
  userIndex = -1;
  userPairs: UserPair[] = [];
  // array of users that match the login
  users: User[] = [];

  _vendor: Vendor | null | undefined;
  _vendors: Vendor[] | null | undefined;

  @Action
  public async createAndInviteUser(params: CreateInviteUserParams): Promise<boolean> {
    const url = `${ApiEndpoints.apiEndpoint}/v2/noauth/signup/household`;
    const res = await apiSvc.httpPost(url, params).catch((err) => {
      // console.log('Update info err:', err)
      return Promise.reject(err);
    });
    if (res !== undefined) {
      return Promise.resolve(true);
    }
    return Promise.resolve(false);
  }

  @Action
  public async createPaymentUser(params: CreateCustomerRequest): Promise<void> {
    if (!params || params === undefined) return Promise.reject(new Error('Params are undefined'));
    if (params.organizationId === undefined || params.organizationId === '' || params.organizationId === 'UNSET') return Promise.reject('organizationId is undefined');
    if (params.email === undefined || params.email === '' || params.email === 'UNSET') return Promise.reject('email is undefined');
    // if (params.firstName === undefined) return Promise.reject('firstName is undefined');
    // if (params.lastName === undefined) return Promise.reject('lastName is undefined');

    const url = `${ApiEndpoints.apiEndpoint}/v2/bank/providers/square/create/customer`;
    const response = await apiSvc.httpPost(url, params).catch((err) => {
      // console.log('Get square/customer/create error:', err, ' params=', params)
      return Promise.reject(err);
    });
    // console.log('response', response);
    try {
      const squareInfo: SquareInfo | undefined = response.data.item.squareInfo;
      if (squareInfo) {
        // if (!this.currUser || this.currUser === undefined) {
        // // if (!this.context.getters["currUser"] || this.context.getters["currUser"] === undefined) {
        //   // this.currUser = new User();
        //   this.setCurrentUser(new User());
        //   // this.context.commit("setCurrentUser", new User())
        // }
        // this.currUser.squareInfo = squareInfo;
        this.setSquareInfo(squareInfo);
        // this.context.commit("setSquareInfo", squareInfo)
      }
    } catch (err) {
      // console.log('Some props dont exist:', err)
    }
  }

  @Action
  public async fetchAllDetails(email?: string): Promise<User[]> {
    email = email || this.email || 'UNSET';
    let u: User[] = [];
    // this.context.dispatch
    await apiSvc
      .httpGet(ApiEndpoints.apiEndpoint + '/v2/profile/users/all-details?email=' + encodeURIComponent(email))
      .then((response: DetailsOnLoginResponse) => {
        const item: DetailsOnLogin | undefined = response.data.item;
        if (item && item !== undefined) {
          u = item.users.sort((a: User, b: User) => {
            if (a === undefined || a.organization === undefined || a.organization.organizationName === undefined) return 1;
            if (b === undefined || b.organization === undefined || b.organization.organizationName === undefined) return -1;
            return a.organization.organizationName > b.organization.organizationName ? 1 : -1;
          });
          this.context.commit('setUsers', item.users);
          // this.setUsers(item.users);
          // see if the current user can be determined
          //  - if only one user is returned, then use that one (genius!)
          //  - if something other than one user is returned ...
          //  -- if a user was already selected and stored in the cookie, then use that
          //  -- if a user is not in a cookie, then default to the first returned
          if (u === undefined || u.length === 0) {
            // this is a problem!!
          } else if (u.length === 1) {
            this.context.commit('setCurrentByUsersIndex', 0);
            // this.setCurrentByUsersIndex(0);
          } else {
            const userId = AuthCookies.getUserId();
            const organizationId = AuthCookies.getOrganizationId();
            if (organizationId != null && organizationId !== undefined && organizationId !== '') {
              let notFound = true;
              let i = 0;
              while (notFound && i < u.length) {
                if (organizationId === u[i].organizationId) {
                  this.context.commit('setCurrentByUsersIndex', i);
                  notFound = false;
                }
                i += 1;
              }
            } else if (userId != null && userId !== undefined && userId !== '') {
              let notFound = true;
              let i = 0;
              while (notFound && i < u.length) {
                if (userId === u[i].userId) {
                  this.context.commit('setCurrentByUsersIndex', i);
                  notFound = false;
                }
                i += 1;
              }
            } else if (u.length > 0) {
              let found = false;
              // this.context.commit('setCurrentByUsersIndex', 0);
              // util.setCurrentByUsersIndex(0);
              for (let i = 0; i < item.users.length; i += 1) {
                if (window.location.href.startsWith(item.users[i].organization?.domainUrl || 'xx')) {
                  // console.log('Set user #3');
                  // this.setCurrentUser(user);
                  this.context.commit('setCurrentByUsersIndex', i);
                  found = true;
                }
              }
              if (!found) {
                // this.setCurrentUser(u[0]);
                this.context.commit('setCurrentByUsersIndex', 0);
              }
            }
          }
        }
      })
      .catch((err: Error) => {
        return Promise.reject(err);
      });
    return Promise.resolve(u);
  }

  @Action
  public async fetchOrganizationsByDomainUrl(params: FetchOrganizationsByDomainUrlParams): Promise<any> {
    // TODO: Switch to v2 endpoint
    if (!params || params === undefined) return Promise.reject(new Error('Params are undefined'));
    if (params.domain === undefined || params.domain === 'UNSET') return Promise.reject(new Error('Domain is undefined'));
    if (params.cacheResult === undefined) params.cacheResult = true;

    const payload = { domain: params.domain };
    const url = `${ApiEndpoints.authEndpoint}/organizations/getOrganizationsByDomainUrl`;
    const res = await apiSvc.httpPost(url, payload).catch((err) => {
      // console.log('Fetch orgs err:', err)
      return Promise.reject(err);
    });
    if (res !== undefined) {
      const organization = res.data.organizations[0];
      if (params.cacheResult) {
        // this.context.commit('setOrganization', organization);
        this.setOrganization(organization);
      }
      return Promise.resolve(organization);
    }
    return Promise.resolve();
  }

  @Action
  public async fetchUserDetails(): Promise<User | Error> {
    const email = this.email;
    const isParent2 = this.isParent2;
    const organizationId = this.organizationId;
    if (email === undefined || email === 'UNSET') return Promise.reject(new Error('email is undefined'));
    if (organizationId === undefined || organizationId === 'UNSET') return Promise.reject(new Error('organizationId is undefined'));
    // console.log('User Details Request:', data)
    // commit('setIsParent2', payload.isParent2)

    const url = `${ApiEndpoints.apiEndpoint}/v2/profile/user?email=${encodeURIComponent(email)}&isParent2=${isParent2}&organizationId=${organizationId}`;
    const response = await apiSvc.httpGet(url).catch((err) => {
      return Promise.reject(err);
    });
    // console.log('fetchUserDetails response ', response);
    if (!response || response === undefined || response.data === undefined || response.data.item === undefined) {
      return Promise.resolve(new Error('No user returned'));
    }
    const user = response.data.item;
    // set if is parent#2 or not...
    if (email === user.email) {
      this.setIsParent2(false);
    } else if (email === user.parent2Email) {
      this.setIsParent2(true);
    }
    // set user to be the current one
    this.setUser(user);
    this.setCurrentUser(user);
    return Promise.resolve(user);
  }

  @Action
  public async fetchUserPairs(): Promise<UserPair[] | Error> {
    const userId = this.userId;
    const organizationId = this.organizationId;
    if (userId === undefined || userId === 'UNSET') return Promise.reject(new Error('UserId is undefined'));
    if (organizationId === undefined || organizationId === 'UNSET') return Promise.reject(new Error('organizationId is undefined'));

    const url = `${ApiEndpoints.apiEndpoint}/v2/profile/userpairs?userId=${userId}&organizationId=${organizationId}`;
    const response = await apiSvc.httpGet(url).catch((err) => {
      return Promise.reject(err);
    });
    // console.log('fetchUserDetails response ', response);
    if (!response || response === undefined || response.data === undefined || response.data.item === undefined) {
      return Promise.resolve(new Error('No user returned'));
    }
    const userpairs: UserPair[] = response.data.items;
    // set user to be the current one
    // console.log('before ', this.userPairs.length, this.currUserPairs.length, userpairs.length);
    this.setUserPairs(userpairs);
    // console.log('after1 ', this.userPairs.length, this.currUserPairs.length, userpairs.length);
    this.setCurrentUser(this.currUser);
    // console.log('after2 ', this.userPairs.length, this.currUserPairs.length, userpairs.length);
    return Promise.resolve(userpairs);
  }

  get authorizedUsers(): User[] {
    return this.currUserPairs
      .filter((p) => {
        return p.child !== undefined;
      })
      .map((p) => p.child || new User());
  }

  get authorizedActiveUsers(): User[] {
    if (this.authorizedUsers === undefined) return [];
    return this.authorizedUsers.filter((user) => {
      return user.isInactive !== 1 && user.isHidden !== 1;
    });
  }

  get email(): string | undefined {
    if (this.currUser === undefined) return undefined;
    if (this.isParent2) return this.currUser.parent2Email;
    return this.currUser.email;
  }

  get firstName(): string | undefined {
    if (this.currUser === undefined) return undefined;
    if (this.isParent2) return this.currUser.parent2FirstName;
    return this.currUser.firstName;
  }

  get hasOnboardingRequired(): boolean {
    if (this.currOrganization === undefined) return false;
    const status: string[] = this.currOrganization.organizationStatus || [];
    if (status !== undefined) {
      const statusSet = new Set(status);
      if (statusSet.has('WELCOME') || statusSet.has('TIMEZONE') || statusSet.has('ABOUT_THEM') || statusSet.has('FIRST_SCHEDULE_VIDEO')) {
        return true;
      }
    }
    return false;
  }

  get householdId(): string | undefined {
    if (this.currUser === undefined) return undefined;
    return this.currUser.userId;
    // TODO: Update when household id added
  }

  get isAssumedUser(): boolean {
    const loginEmail = AuthCookies.getUserId();
    if (loginEmail === undefined) return false;
    if (this.email === undefined) return false;
    return loginEmail !== this.email;
  }

  get isOrganizationConsole(): boolean {
    return (
      window.location.host.indexOf('console.funfangle') === 0 ||
      window.location.host.indexOf('staging-console.funfangle') === 0 ||
      window.location.host.indexOf('test-console.funfangle') === 0 ||
      window.location.host.indexOf('dev-console.dev.funfangle') === 0
    );
  }

  get isOrganizationManager(): boolean {
    if (this.currUser !== undefined && this.currUser.role === 'organization-manager') {
      return true;
    }
    return false;
  }

  get isRoleSysAdmin(): boolean {
    const admins = new Set<string>(['pool6-manager@fangl.co', 'jeanna.hoover@jpgreze.com', 'matthew.vahlberg@jpgreze.com']);
    const isSysAdmin = this.email === undefined ? false : admins.has(this.email);
    const pu = new ProfileUtils();
    return isSysAdmin || pu.isRole(this.organizationId, 'sysadmin');
  }

  get isUserPortal(): boolean {
    return !this.isOrganizationConsole;
  }

  get lastName(): string | undefined {
    if (this.currUser === undefined) return undefined;
    if (this.isParent2) return this.currUser.parent2LastName;
    return this.currUser.lastName;
  }

  get loginEmail(): string | undefined {
    return AuthCookies.getUsername();
  }

  get name(): string | undefined {
    if (this.currUser === undefined) return undefined;
    return `${this.currUser.firstName || ''} ${this.currUser.lastName || ''}`;
  }

  get nameAbbreviated(): string | undefined {
    if (this.currUser === undefined) return undefined;
    if (this.currUser.lastName === undefined) return this.currUser.firstName;
    return `${this.currUser.firstName || ''} ${this.currUser.lastName.charAt(0) || ''}`;
  }

  get organization(): Organization | undefined {
    if (this.currOrganization === undefined) return undefined;
    if (this.currUser === undefined) return undefined;
    if (this.currUser.organizationId !== this.currOrganization.organizationId) return undefined;
    return this.currOrganization;
  }

  get organizationId(): string | undefined {
    if (this.currUser === undefined) return undefined;
    let orgId: string | undefined;
    if (this.currOrganization !== undefined) orgId = this.currOrganization.organizationId;
    if (orgId === undefined) orgId = AuthCookies.getOrganizationId();
    if (orgId === undefined) return undefined;
    if (this.currUser.organizationId !== orgId) return undefined;
    return orgId;
  }

  /**
   * Get the organizations.
   */
  get organizations(): Organization[] {
    const orgs: Organization[] = [];
    if (this.users && this.users !== undefined) {
      for (let i = 0; i < this.users.length; i += 1) {
        const org = this.users[i].organization;
        if (org && org !== undefined) {
          orgs.push(org);
        }
      }
    }
    return orgs;
  }

  get orgSessionCategories(): string[] {
    const categories: string[] = [];
    categories.push('Location');
    categories.push('Class');
    return categories;
  }

  get orgType(): string {
    if (this.currOrganization === undefined) {
      return 'unknown';
    }
    if (this.currOrganization.organizationType !== undefined) {
      return this.currOrganization.organizationType;
    }
    return 'rec-club';
  }

  get parent2Email(): string | undefined {
    if (this.currUser === undefined) return undefined;
    return this.currUser.parent2Email;
  }

  get phoneNumber(): string | undefined {
    if (this.currUser === undefined) return undefined;
    return this.currUser.phoneNumber;
  }

  get squareInfo(): SquareInfo | null {
    if (this.currUser === undefined || this.currUser.squareInfo === undefined) return null;
    return this.currUser.squareInfo;
  }

  /**
   * Get the user indicated by the user index
   */
  get user(): User {
    return this.users[this.userIndex];
  }

  get userId(): string | undefined {
    if (this.currUser === undefined) return undefined;
    return this.currUser.userId;
  }

  get username(): string | undefined {
    if (this.currUser === undefined) return undefined;
    return this.currUser.username;
  }

  get vendor(): Vendor | null | undefined {
    return this._vendor;
  }

  get vendors(): Vendor[] | null | undefined {
    return this._vendors;
  }

  // @Mutation
  // public setCurrentEmail(email: string): void {
  //   this.currEmail = email;
  // }

  @Mutation
  public setCurrentOrganization(org: Organization): void {
    // console.log('CurrentOrg=', org.organizationName, org);
    this.currOrganization = org;
    if (org.organizationId !== undefined) AuthCookies.setOrganizationIdCookie(org.organizationId);
  }

  @Mutation
  public setCurrentUser(user: User): void {
    // console.log('CurrentUser=', user.firstName, user.lastName, user);
    this.currUser = user;
  }

  @Mutation
  public setCurrentByUsersIndex(index: number): void {
    if (!this.users || this.users === undefined || index < 0 || this.users.length <= index) {
      return;
    }
    // set user
    //this.setCurrentUser(this.users[index]);
    this.currUser = this.users[index];
    // console.log('setCurrentByUsersIndex CurrentUser=', this.currUser.firstName, this.currUser.lastName, this.currUser);
    //this.setUserIndex(index);
    this.userIndex = index;
    // set org
    const org = this.users[index].organization;
    if (org && org !== undefined) {
      // console.log('setCurrentByUsersIndex CurrentOrg=', org.organizationName, org);
      this.currOrganization = org;
      if (org.organizationId !== undefined) AuthCookies.setOrganizationIdCookie(org.organizationId);
    }
  }

  @Mutation
  public setEmail(email: string): void {
    if (this.currUser === undefined) this.currUser = new User();
    this.currUser.email = email;
  }

  @Mutation
  public setFirstName(firstName: string): void {
    if (this.currUser === undefined) this.currUser = new User();
    this.currUser.firstName = firstName;
  }

  @Mutation
  public setIsParent2(isParent2: boolean): void {
    this.isParent2 = isParent2;
  }

  @Mutation
  public setLastName(lastName: string): void {
    if (this.currUser === undefined) this.currUser = new User();
    this.currUser.lastName = lastName;
  }

  // @Mutation
  // public setLoginEmail(email: string): void {
  //   this.loginEmail = email;
  // }

  @Mutation
  public setOrganization(organization: Organization): void {
    this.currOrganization = organization;
    if (organization.organizationId !== undefined) AuthCookies.setOrganizationIdCookie(organization.organizationId);
  }

  @Mutation
  public setOrgStatus(arr: string[]): void {
    if (this.currOrganization !== undefined) {
      this.currOrganization.organizationStatus = arr;
    }
  }

  @Mutation
  public setPhoneNumber(phoneNumber: string): void {
    if (this.currUser === undefined) this.currUser = new User();
    this.currUser.phoneNumber = phoneNumber;
  }

  @Mutation
  public setSquareInfo(sqInfo: SquareInfo): void {
    let u = this.currUser;
    if (u === undefined) u = new User();
    Vue.set(u, 'squareInfo', sqInfo);
    this.currUser = u;
  }

  @Mutation
  public setUser(user: User) {
    if (this.users === undefined) {
      this.users = [];
      this.users.push(user);
    } else {
      for (let i = 0; i < this.users.length; i += 1) {
        if (this.users[i].email !== undefined && this.users[i].email === user.email && this.users[i].organizationId === user.organizationId) {
          this.users[i] = user;
        }
      }
    }
  }

  @Mutation
  public setUserIndex(index: number): void {
    this.userIndex = index;
  }

  @Mutation
  public setUsers(users: User[]): void {
    this.users = users;
  }

  @Mutation
  public appendCurrUserPair(pair: UserPair): void {
    if (this.currUserPairs === undefined) this.currUserPairs = [];
    this.currUserPairs.push(pair);
    this.currUserPairs.sort((a, b) => ((a.child?.firstName || '') < (b.child?.firstName || '') ? -1 : 1));
  }

  @Mutation
  public appendUserPair(pair: UserPair): void {
    if (this.userPairs === undefined) this.userPairs = [];
    this.userPairs.push(pair);
  }

  @Mutation
  public setUserPairs(pairs: UserPair[]): void {
    this.userPairs = pairs;
  }

  @Mutation
  public setVendor(vendor: Vendor): void {
    this._vendor = vendor;
    if (this._vendors == null || this._vendors === undefined) this._vendors = [];
    let found = false;
    for (let i = 0; i < this._vendors.length; i++) {
      if (this._vendors[i].vendorId === vendor.vendorId && vendor.vendorId !== undefined) {
        this._vendors[i] = vendor;
        found = true;
        return;
      }
    }
    if (!found) this._vendors.push(vendor);
  }

  @Mutation
  public setVendors(vendors: Vendor[]): void {
    this._vendors = vendors;
  }

  @Action
  public async updateEmail(email: string): Promise<any> {
    // TODO: Use SDK interface definition for payload
    // TODO: Switch to v2 endpoint
    // TODO -FRANK
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      // TODO perform server call
      // axios.get()
      //   .then() {
      // Save email to local store
      // this.context.commit('setEmail', email);
      this.setEmail(email);
      // TODO return response
      resolve(true);
      // }
      // TODO return error if any
    });
  }

  @Action
  public async updateFirstLastName(params: UpdateFirstLastNameParams): Promise<any> {
    const organizationId = this.organizationId;
    const email = this.email;
    if (email === undefined || email === 'UNSET') return Promise.reject(new Error('email is undefined'));
    if (organizationId === undefined || organizationId === 'UNSET') return Promise.reject(new Error('organizationId is undefined'));
    if (!params || params === undefined) return Promise.reject(new Error('Params are undefined'));
    if (params.firstName === undefined || params.firstName === 'UNSET') return Promise.reject(new Error('First name is undefined'));
    if (params.lastName === undefined || params.lastName === 'UNSET') return Promise.reject(new Error('Last name is undefined'));
    const data: UserRequest = {
      email,
      firstName: params.firstName,
      lastName: params.lastName,
      organizationId,
    };

    const url = `${ApiEndpoints.apiEndpoint}/v2/profile/user`;
    return new Promise((resolve, reject) => {
      apiSvc
        .httpPost(url, data)
        .then(() => {
          // this.context.commit('setFirstName', firstName);
          // this.context.commit('setLastName', lastName);
          this.setFirstName(params.firstName);
          this.setLastName(params.lastName);
          resolve(true);
        })
        .catch((err) => {
          // console.log('Update info err:', err)
          reject(err);
        });
    });
  }

  @Action
  public async updatePhoneNumber(phone: string): Promise<any> {
    const email = this.email;
    const organizationId = this.organizationId;
    if (email === undefined || email === 'UNSET') return Promise.reject(new Error('email is undefined'));
    if (organizationId === undefined || organizationId === 'UNSET') return Promise.reject(new Error('organizationId is undefined'));
    const data: UserRequest = {
      email,
      phone,
      organizationId,
    };

    const url = `${ApiEndpoints.apiEndpoint}/v2/profile/user`;
    return new Promise((resolve, reject) => {
      apiSvc
        .httpPost(url, data)
        .then(() => {
          // this.context.commit('setPhoneNumber', phoneNumber);
          this.setPhoneNumber(phone);
          resolve(true);
        })
        .catch((err) => {
          // console.log('Update info err:', err)
          reject(err);
        });
    });
  }
}

export class ProfileUtils {
  profileStore: ProfileModule = getModule(ProfileModule, store);

  isRole(organizationId?: string, role?: string): boolean {
    if (this.profileStore.currUser === undefined) return false;
    if (organizationId === undefined) return false;
    if (role === undefined) return false;
    const roles = this.profileStore.currUserRoles.get(organizationId);
    if (roles == null || roles === undefined) {
      return false;
    }
    return roles.includes(role);
  }

  public setCurrentByUsersIndex(index: number): void {
    if (!this.profileStore.users || this.profileStore.users === undefined || index < 0 || this.profileStore.users.length <= index) {
      return;
    }
    // set user
    // console.log(`User for index=${index} is `, this.profileStore.users[index]);
    // this.context.commit('profile/setCurrentUser', this.users[index]);
    // this.context.commit
    this.profileStore.setCurrentUser(this.profileStore.users[index]);
    // this.userIndex = index;
    // // this.setCurrentUser(this.users[index]);
    // this.currUser = this.users[index];
    // //this.setUserIndex(index);
    // this.userIndex = index;
    // // set org
    // const org = this.users[index].organization;
    // if (org && org !== undefined) {
    //   // this.setCurrentOrganization(org);
    //   this.currOrganization = org;
    // }
  }
}

export interface CreateInviteUserParams {
  organizationId: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface FetchOrganizationsByDomainUrlParams {
  domain: string;
  cacheResult: boolean;
}

export interface UpdateFirstLastNameParams {
  firstName: string;
  lastName: string;
}
