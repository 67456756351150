import { Organization, OrganizationRequest } from '@fgl/funfangle-sdk/dist/rest/profile';
import { ProfileOrganizationApiClient } from '.';

export class OrganizationConfigClient {
  //   async updateEventReservationSettingCutoffAtHoursPrior(session?: Session, cutoffAtHoursPrior?: number) {
  //     if (session === undefined) return;
  //     if (session.organizationId === undefined) return;
  //     if (session.sessionId === undefined) return;
  //     if (cutoffAtHoursPrior === undefined) return;
  //     const req = new SessionRequest(session.organizationId);
  //     req.sessionId = session.sessionId;
  //     req.eventReservationSettings = session.eventReservationSettings;
  //     if (req.eventReservationSettings === undefined) req.eventReservationSettings = new EventReservationSettings();
  //     req.eventReservationSettings.cutoffAtHoursPrior = cutoffAtHoursPrior;
  //     const apiSvc = new SessionApiClient();
  //     return apiSvc.postSession(req);
  //   }

  async updateOrganizationName(organization?: Organization, organizationName?: string | null) {
    if (organization === undefined) return;
    if (organization.organizationId === undefined) return;
    if (organizationName === null || organizationName === undefined) return;
    const req = new OrganizationRequest(organization.organizationId);
    req.organizationId = organization.organizationId;
    req.organizationName = organizationName;
    const apiSvc = new ProfileOrganizationApiClient();
    return apiSvc.postOrganization(req);
  }

  async updateSendInvitesOnImport(organization?: Organization, sendInvitesOnImport?: boolean | null) {
    if (organization === undefined) return;
    if (organization.organizationId === undefined) return;
    if (sendInvitesOnImport === null || sendInvitesOnImport === undefined) return;
    const req = new OrganizationRequest(organization.organizationId);
    req.organizationId = organization.organizationId;
    req.sendInvitesOnImport = sendInvitesOnImport;
    const apiSvc = new ProfileOrganizationApiClient();
    return apiSvc.postOrganization(req);
  }
}
