// import { ApiEndpoints } from '../constants';
import { store } from '@/store/Store';
// import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
// import { Contributor, User, UserWaiverScopeType } from '@fgl/funfangle-sdk/dist/rest/profile';
// import { ApiService } from '@/shared/auth/auth-cognito';
import { getModule } from 'vuex-module-decorators';
import { Inventory, InventoryLot, InventoryPurchaseDetail, InventoryPurchaseOrder } from '@fgl/funfangle-sdk/dist/rest/v3/inventory';
import { NavModule } from './NavModule';
import { ProfileModule } from './ProfileModule';
// import { RouterUtils } from '@/router/RouterUtils';

// const apiSvc = new ApiService();

@Module({ dynamic: true, store, name: 'inventory' })
export class InventoryModule extends VuexModule {
  profileStore: ProfileModule = getModule(ProfileModule);
  navStore: NavModule = getModule(NavModule);

  _item: Inventory | null | undefined;

  _items: Inventory[] | null | undefined;

  _lots: InventoryLot[] | null | undefined;

  _purchaseDetail: InventoryPurchaseDetail | null | undefined;

  _purchaseDetails: InventoryPurchaseDetail[] | null | undefined;

  _purchaseOrder: InventoryPurchaseOrder | null | undefined;

  _purchaseOrders: InventoryPurchaseOrder[] | null | undefined;

  get countItems(): number {
    return this._items ? this._items.length : 0;
  }

  get countLots(): number {
    return this._lots ? this._lots.length : 0;
  }

  get countPurchaseDetails(): number {
    return this._purchaseDetails ? this._purchaseDetails.length : 0;
  }

  get countPurchaseOrders(): number {
    return this._purchaseOrders ? this._purchaseOrders.length : 0;
  }

  get item(): Inventory | null | undefined {
    return this._item;
  }

  get items(): Inventory[] | null | undefined {
    return this._items;
  }

  get lots(): InventoryLot[] | null | undefined {
    return this._lots;
  }

  get purchaseDetail(): InventoryPurchaseDetail | null | undefined {
    return this._purchaseDetail;
  }

  get purchaseDetails(): InventoryPurchaseDetail[] | null | undefined {
    return this._purchaseDetails;
  }

  get purchaseOrder(): InventoryPurchaseOrder | null | undefined {
    return this._purchaseOrder;
  }

  get purchaseOrders(): InventoryPurchaseOrder[] | null | undefined {
    return this._purchaseOrders;
  }

  @Mutation
  public clearItem(): void {
    this._item = null;
  }

  @Mutation
  public clearItems(): void {
    this._items = [];
  }

  @Mutation
  public clearLots(): void {
    this._lots = [];
  }

  @Mutation
  public clearPurchaseDetails(): void {
    this._purchaseDetails = [];
  }

  @Mutation
  public clearPurchaseOrder(): void {
    this._purchaseOrder = null;
  }

  @Mutation
  public clearPurchaseOrders(): void {
    this._purchaseOrders = [];
  }

  @Mutation
  public setItem(item: Inventory): void {
    this._item = item;
  }

  @Mutation
  public setItems(items: Inventory[]): void {
    this._items = items;
  }

  @Mutation
  public setLots(lots: InventoryLot[]): void {
    this._lots = lots;
  }

  @Mutation
  public setPurchaseDetail(purchaseDetail: InventoryPurchaseDetail): void {
    this._purchaseDetail = purchaseDetail;
  }

  @Mutation
  public setPurchaseDetails(purchaseDetails: InventoryPurchaseDetail[]): void {
    this._purchaseDetails = purchaseDetails;
  }

  @Mutation
  public setPurchaseOrder(purchaseOrder: InventoryPurchaseOrder): void {
    this._purchaseOrder = purchaseOrder;
    if (this._purchaseOrders == null || this._purchaseOrders === undefined) this._purchaseOrders = [];
    let found = false;
    for (let i = 0; i < this._purchaseOrders.length; i++) {
      if (this._purchaseOrders[i].purchaseId === purchaseOrder.purchaseId && purchaseOrder.purchaseId !== undefined) {
        this._purchaseOrders[i] = purchaseOrder;
        found = true;
        return;
      }
    }
    if (!found) this._purchaseOrders.push(purchaseOrder);
  }

  @Mutation
  public setPurchaseOrders(purchaseOrders: InventoryPurchaseOrder[]): void {
    this._purchaseOrders = purchaseOrders;
  }
}
