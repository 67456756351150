import { User } from '@fgl/funfangle-sdk/dist/rest/profile';
import { getModule } from 'vuex-module-decorators';
import { ProfileModule, store } from '.';
import { SessionModule } from './SessionModule';

export class StoreUtils {
  profileStore: ProfileModule = getModule(ProfileModule, store);
  sessionStore: SessionModule = getModule(SessionModule, store);

  public async onLoginActions(email?: string): Promise<User[] | Error> {
    const username = email === undefined ? undefined : email.toLowerCase().replace(/^\s+|\s+$/gm, '');
    const resAllDetails = await this.profileStore.fetchAllDetails(username).catch(() => {
      // console.log('fetchAllDetails error ', err);
      // ignore
    });
    if (!resAllDetails || resAllDetails === undefined) {
      return Promise.resolve([]); // return empty array
    }
    if (resAllDetails instanceof Error) {
      return Promise.resolve(resAllDetails);
    }

    // post-login asyncronous actions ...
    this.sessionStore.fetchSessions().catch(() => {});

    return Promise.resolve(resAllDetails);
  }
}
