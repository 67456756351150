import { ApiEndpoints } from '../constants';
import { store } from '@/store/Store';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import {
  ImportLog,
  ImportLogResponse,
  ImportLogsResponse,
  ImportSessionLog,
  ImportSessionLogResponse,
  ImportSessionLogsResponse,
  UserLog,
  UserLogResponse,
  UserLogsResponse,
} from '@fgl/funfangle-sdk/dist/rest/log';
import { ApiService } from '@/shared/auth/auth-cognito';

const apiSvc = new ApiService();

@Module({ dynamic: true, store, name: 'log' })
export class LogModule extends VuexModule {
  // imported logs
  importLogs: ImportLog[] = [];

  @Action
  public async fetchImportLogs(organizationId: string): Promise<ImportLog[]> {
    let logs: ImportLog[] = [];
    await apiSvc
      .httpGet(ApiEndpoints.logEndpoint + '/v2/import/list?organizationId=' + organizationId)
      .then((response: ImportLogsResponse) => {
        logs = response.data.items;
        if (logs && logs !== undefined) {
          this.context.commit('setImportLogs', logs);
        }
      })
      .catch((err: Error) => {
        return Promise.reject(err);
      });
    return Promise.resolve(logs);
  }

  /**
   * Get the user indicated by the user index
   */
  // get importLogs(): User {
  //   return this.users[this.userIndex];
  // }

  @Mutation
  public setImportLogs(logs: ImportLog[]): void {
    this.importLogs = logs;
  }
}
