<template>
  <div>
    <!-- <console-on-boarding v-if="hasOnboardingRequired" /> -->
    <console-top-menu v-if="!hasOnboardingRequired && enableTopMenu" :class="classTopMenu" @toggleSideMenu="toggleSideMenu" />
    <console-side-menu v-if="!hasOnboardingRequired && enableSideMenu" :show-menu="showSideMenu" />
    <router-view v-if="!hasOnboardingRequired" :class="classRouterView" />
    <router-view v-if="hasOnboardingRequired" />
  </div>
  <!--
  <div id="app">
    <side-menu v-if="showSideMenu" />
    <top-menu @toggleSideMenu="toggleSideMenu" class="app-flex" />
    <app-profile v-if="activeApp === 'profile'" />
    <section v-if="activeApp === 'root'">root {{ showSideMenu }}</section>
  </div>
  -->
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { ProfileModule } from '@/store/ProfileModule';
// import MainContent from '@/views/MainContent.vue';
import ConsoleSideMenu from '@/views/console/SideMenu.vue';
import ConsoleTopMenu from '@/views/console/TopMenu.vue';
import ConsoleOnBoarding from '@/views/console/OnBoarding.vue';
import { NavModule } from './store';

// The @Component decorator indicates the class is a Vue component
@Component({
  components: {
    // MainContent,
    ConsoleOnBoarding,
    ConsoleSideMenu,
    ConsoleTopMenu,
  },
})
export default class App extends Vue {
  // Initial data can be declared as instance properties
  navStore: NavModule = getModule(NavModule, this.$store);
  profileStore: ProfileModule = getModule(ProfileModule, this.$store);
  activeApp = 'welcome';
  enableSideMenu = false;
  enableTopMenu = false;

  get classRouterView(): string {
    if (this.showSideMenu && !this.navStore.sideToggleCollapsed) {
      return 'app-routerview-sidenavopened';
    }
    if (this.showSideMenu && this.navStore.sideToggleCollapsed) {
      return 'app-routerview-sidenavcollapsed';
    }
    return 'app-routerview-sidenavclosed';
  }

  get classTopMenu(): string {
    if (this.showSideMenu && !this.navStore.sideToggleCollapsed) {
      return 'app-topmenu-sidenavopened';
    }
    if (this.showSideMenu && this.navStore.sideToggleCollapsed) {
      return 'app-topmenu-sidenavcollapsed';
    }
    return 'app-topmenu-sidenavclosed';
  }

  get showSideMenu(): boolean {
    return this.navStore.showSideMenu;
  }

  /** Lifecycle Functions */
  @Watch('hasOnboardingRequired')
  watchOnboardingRequired() {
    if (this.hasOnboardingRequired) {
      this.$router.push({ name: 'onboarding' });
    }
  }
  mounted() {
    this.configMenus();
    if (this.hasOnboardingRequired) {
      this.$router.push({ name: 'onboarding' });
    }
  }
  // Take action whenever the route object changes
  @Watch('$route', { immediate: true, deep: true })
  updateMenus() {
    this.configMenus();
  }
  /** User-Defined Functions */
  configMenus() {
    if (
      window.location.pathname.startsWith('/activate') ||
      window.location.pathname.startsWith('/auth') ||
      window.location.pathname.startsWith('/portal') ||
      window.location.pathname.startsWith('/signin') ||
      window.location.pathname.startsWith('/signout') ||
      window.location.pathname.startsWith('/start')
    ) {
      this.navStore.setShowSideMenu(false);
      this.activeApp = 'auth';
      this.enableSideMenu = false;
      this.enableTopMenu = false;
    } else if (window.location.pathname.startsWith('/retail')) {
      this.activeApp = 'retail';
      if (
        window.location.pathname.startsWith('/retail/inventory/catalog') ||
        window.location.pathname.startsWith('/retail/inventory/histories') ||
        window.location.pathname.startsWith('/retail/inventory/history') ||
        window.location.pathname.startsWith('/retail/inventory/item') ||
        window.location.pathname.startsWith('/retail/inventory/lot') ||
        window.location.pathname.startsWith('/retail/inventory/purchasedetail') ||
        window.location.pathname.startsWith('/retail/inventory/purchaseorder') ||
        window.location.pathname.startsWith('/retail/inventory/vendor')
      ) {
        this.navStore.setShowSideMenu(this.$vmq.lg);
        this.enableSideMenu = true;
        this.enableTopMenu = true;
      } else {
        this.navStore.setShowSideMenu(false);
        this.enableSideMenu = false;
        this.enableTopMenu = false;
      }
      // this.navStore.setShowSideMenu(this.$vmq.lg);
      // this.enableSideMenu = true;
      // this.enableTopMenu = true;
    } else if (window.location.pathname.startsWith('/profile')) {
      this.navStore.setShowSideMenu(this.$vmq.lg);
      this.activeApp = 'profile';
      this.enableSideMenu = true;
      this.enableTopMenu = true;
    } else if (window.location.pathname.startsWith('/welcome')) {
      this.navStore.setShowSideMenu(this.$vmq.lg);
      this.activeApp = 'welcome';
      this.enableSideMenu = false;
      this.enableTopMenu = false;
    } else {
      this.navStore.setShowSideMenu(this.$vmq.lg);
      this.activeApp = 'home';
      this.enableSideMenu = true;
      this.enableTopMenu = true;
    }
  }
  //
  // handleTopMenuClicked() {
  //   // console.log('top menu clicked');
  //   if (this.enableSideMenu && this.showSideMenu) {
  //     if (!this.$vmq.lg) {
  //       // console.log('top menu clicked: togglee');
  //       this.toggleSideMenu();
  //     }
  //   }
  // }
  //

  get hasOnboardingRequired(): boolean {
    return this.profileStore.hasOnboardingRequired;
  }

  lastToggled = 0;

  toggleSideMenu() {
    this.lastToggled = Date.now();
    this.navStore.toggleSideMenu();
    // console.log('showSideMenu=', this.navStore.showSideMenu);
  }

  //
  // Breakpoint Eventing
  //  - Adjust side menu based on breakpoint, when window is resized
  //

  onBreakpoint() {
    if (this.lastToggled > Date.now() - 1000) return;
    this.navStore.setShowSideMenu(this.$vmq.lg);
  }

  created() {
    window.addEventListener('resize', this.onBreakpoint);
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.onBreakpoint);
  }
}
</script>

<style scoped>
.app-routerview-sidenavclosed {
  transition-timing-function: ease-in-out;
  padding-top: 10px;
  padding-left: 10px;
}

.app-routerview-sidenavcollapsed {
  transition-timing-function: ease-in-out;
  padding-top: 10px;
  padding-left: 60px;
}
.app-routerview-sidenavopened {
  transition-timing-function: ease-in-out;
  padding-top: 10px;
  padding-left: 256px;
}

.app-topmenu-sidenavclosed {
  transition-timing-function: ease-in-out;
  padding-left: 10px;
}

.app-topmenu-sidenavcollapsed {
  transition-timing-function: ease-in-out;
  padding-left: 60px;
}

.app-topmenu-sidenavopened {
  transition-timing-function: ease-in-out;
  padding-left: 256px;
}
</style>
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
