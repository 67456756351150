<template>
  <b-container fluid>
    <!-- <mq-layout mq="sm"> -->
    <b-row>
      <div class="col-md-4 col-md-offset-4 mt-2 mb-3 mx-auto">
        <div class="panel panel-default">
          <b-alert variant="info" class="text-center" show> A two-minute instructional video ... </b-alert>
        </div>
      </div>
    </b-row>
    <b-row>
      <div class="my-1 mx-auto">
        <iframe :width="width" :height="height" frameborder="0" scrolling="no" src="https://screencast-o-matic.com/embed?sc=cY1jVZCfHy&v=6&ff=1&title=0&controls=1&a=1" allowfullscreen="true"></iframe>
      </div>
    </b-row>
    <b-row>
      <div class="col-md-4 col-md-offset-4 my-3 mx-auto">
        <div class="text-center">
          <mdb-btn :disabled="!formIsValid" @click="handleContinueClick()"> Continue </mdb-btn>
        </div>
      </div>
    </b-row>
  </b-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import { ProfileModule } from '@/store/ProfileModule';
import { SessionModule } from '@/store/SessionModule';
// import axios from 'axios';
// import { OrganizationRequest } from '@fgl/funfangle-sdk/dist/rest/profile';
import { mdbAlert, mdbAutocomplete, mdbBtn, mdbSelect } from 'mdbvue';
// import { ApiEndpoints } from './../../../constants';
// import { ApiService } from '@/shared/auth/auth-cognito';
// import { Constants } from '@/constants';
// import * as RouterIcons from '@/router/RouterIcons';

interface SelectOption {
  text: string;
  value: string;
  selected: boolean;
}

@Component({
  components: {
    mdbAlert,
    mdbAutocomplete,
    mdbBtn,
    mdbSelect,
  },
})
export default class FirstEventRegistrationVideoComponent extends Vue {
  errorMessage: string | null = null;
  successMessage: string | null = null;
  showContinueScreen = false;
  infoMessage: string | null = null;
  profileStore: ProfileModule = getModule(ProfileModule, this.$store);
  sessionStore: SessionModule = getModule(SessionModule, this.$store);
  disableAllInputs = false;
  height = 720;
  width = 1280;

  async mounted() {
    const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    this.height = h * 0.6;
    this.width = (h * 0.6) / 0.5625;
    // if (this.height > h / 40) {
    //   this.height = h - h / 40;
    //   this.width = (h - h / 40) / 0.5625;
    // }
  }

  // see if the form elements are valid
  get formIsValid(): boolean {
    // if (this.guideMessage !== 'Looks good ...') {
    //   return false;
    // }
    return true;
  }

  get guideMessage(): string {
    // if (!this.timeZoneSet.has(this.timeZone)) {
    //   return `Enter a standard time zone. ${this.defaultTimeZone}`;
    // }
    return 'Looks good ...';
  }

  // handleBackClick() {
  //   this.$emit('back');
  // }

  async handleContinueClick(): Promise<void> {
    // Remove alert boxes
    this.successMessage = null;
    this.errorMessage = null;
    this.infoMessage = null;
    this.$emit('next');
  }

  handleSkipClick() {
    this.$emit('skip');
  }
}
</script>

<style></style>
