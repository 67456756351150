import { getModule } from 'vuex-module-decorators';
import { UserRequest } from '@fgl/funfangle-sdk/dist/rest/profile';
import { ApiEndpoints } from '@/constants';
import { ProfileModule } from '@/store';
import { store } from '../../store/Store';
import { ApiClient } from '.';

export class ProfileApiClient extends ApiClient {
  public profileStore: ProfileModule = getModule(ProfileModule, store);

  async deleteAccount(request: UserRequest) {
    const url = `${ApiEndpoints.apiEndpoint}/v2/profile/account/delete`;
    const apiSvc = new ApiClient();
    return apiSvc.httpPost(url, request);
  }

  async getUserById(userId: string) {
    const apiSvc = new ApiClient();
    return apiSvc.httpGet(`${ApiEndpoints.apiEndpoint}/v2/profile/user?organizationId=${this.profileStore.currOrganization.organizationId}&userId=${userId}`);
  }

  async postUser(request: UserRequest) {
    const url = `${ApiEndpoints.apiEndpoint}/v2/profile/user`;
    const apiSvc = new ApiClient();
    return apiSvc.httpPost(url, request);
  }
}
