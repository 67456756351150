import { ApiEndpoints } from '../constants';
import { store } from '@/store/Store';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { DateTime } from 'luxon';
import { Booking, EventOccurance, EventsResponse } from '@fgl/funfangle-sdk/dist/rest/event';
import { Session, SessionsResponse } from '@fgl/funfangle-sdk/dist/rest/session';
import { ApiService } from '@/shared/auth/auth-cognito';
import { getModule } from 'vuex-module-decorators';
import { ProfileModule } from './ProfileModule';
import { TableBvResponseBody } from '@fgl/funfangle-sdk/dist/rest/TableBvResponse';
import { SessionApiClient } from '@/shared/api/SessionApiClient';

const apiSvc = new ApiService();

@Module({ dynamic: true, store, name: 'session' })
export class SessionModule extends VuexModule {
  profileStore: ProfileModule = getModule(ProfileModule);
  // private profileModule: ProfileModule | undefined;
  currEventIds: string[] | undefined = [];
  // current selected organization of the user
  currSession: Session | null = null;
  currSessionEvents: EventOccurance[] = [];
  currSessionIds: string[] | undefined = [];
  // current selected organization of the user
  currSessionId: string | null = null;
  // current selected user
  currEndSeconds: number | undefined = 0;
  currStartSeconds: number | undefined = 0;
  // events
  bookings: Booking[] = [];
  events: EventOccurance[] = [
    // {
    //   // "cancelledCount": 0,
    //   // "capacityCount": 25,
    //   // "createdAt": 1591211169.317,
    //   // "endSeconds": 1591369200,
    //   // "gsi1Dk": "dfaa3ced-d447-4700-b867-560bf9d56d02",
    //   // "pk": "11110001-0000-0000-0000-000000000000#SessionEvent",
    //   // "registeredCount": 0,
    //   // "sk": "1591362000#dfaa3ced-d447-4700-b867-001591362000",
    //   // "sourceFunction": "DAO",
    //   // "syncDeviceId": "SERVER",
    //   // "updatedAt": 1591211169.317,
    //   // "waitlistedCount": 0
    // }
  ];
  isLoadingSessions = false;
  sessions: Session[] = [];
  sessionsExpiresAt?: number;
  sessionsResponse: SessionsResponse | undefined;
  //  = [
  //   // {
  //   //   endDate: '6/7/2020',
  //   //   endSeconds: 1591570800,
  //   //   organizationId: '11110001-0000-0000-0000-000000000000',
  //   //   sessionId: 'dfaa3ced-d447-4700-b867-560bf9d56d02',
  //   //   sessionName: 'Class #1',
  //   //   sessionString: 'CLASS1',
  //   //   startDate: '6/5/2020',
  //   //   startSeconds: 1591362000,
  //   //   state: 'UNSET'
  //   // },
  //   // {
  //   //   endDate: '6/7/2020',
  //   //   endSeconds: 1591570800,
  //   //   organizationId: '11110001-0000-0000-0000-000000000000',
  //   //   sessionId: '938df7eb-f0d9-4f2f-8a9c-dec18f358b7b',
  //   //   sessionName: 'Pool #1',
  //   //   sessionString: 'POOL1',
  //   //   startDate: '6/5/2020',
  //   //   startSeconds: 1591362000,
  //   //   state: 'UNSET'
  //   // }
  // ];

  // sessions: ,
  // events: {
  //   "dfaa3ced-d447-4700-b867-560bf9d56d02": ,
  //   "938df7eb-f0d9-4f2f-8a9c-dec18f358b7b": [{
  //     "cancelledCount": 0,
  //     "capacityCount": 25,
  //     "createdAt": 1591211163.254,
  //     "endSeconds": 1591369200,
  //     "gsi1Dk": "938df7eb-f0d9-4f2f-8a9c-dec18f358b7b",
  //     "pk": "11110001-0000-0000-0000-000000000000#SessionEvent",
  //     "registeredCount": 0,
  //     "sk": "1591362000#938df7eb-f0d9-4f2f-8a9c-001591362000",
  //     "sourceFunction": "DAO",
  //     "syncDeviceId": "SERVER",
  //     "updatedAt": 1591211163.254,
  //     "waitlistedCount": 0
  //   },{
  //     "cancelledCount": 0,
  //     "capacityCount": 12,
  //     "createdAt": 1591211163.254,
  //     "endSeconds": 1591369200,
  //     "gsi1Dk": "938df7eb-f0d9-4f2f-8a9c-dec18f358b7b",
  //     "pk": "11110001-0000-0000-0000-000000000000#SessionEvent",
  //     "registeredCount": 0,
  //     "sk": "1591371000#938df7eb-f0d9-4f2f-8a9c-001591362000",
  //     "sourceFunction": "DAO",
  //     "syncDeviceId": "SERVER",
  //     "updatedAt": 1591211163.254,
  //     "waitlistedCount": 0
  //   },{
  //     "cancelledCount": 0,
  //     "capacityCount": 15,
  //     "createdAt": 1591211163.254,
  //     "endSeconds": 1591369200,
  //     "gsi1Dk": "938df7eb-f0d9-4f2f-8a9c-dec18f358b7b",
  //     "pk": "11110001-0000-0000-0000-000000000000#SessionEvent",
  //     "registeredCount": 0,
  //     "sk": "1591380000#938df7eb-f0d9-4f2f-8a9c-001591362000",
  //     "sourceFunction": "DAO",
  //     "syncDeviceId": "SERVER",
  //     "updatedAt": 1591211163.254,
  //     "waitlistedCount": 0
  //   }]
  // },

  // @Action
  // public async fetchBookingsByEventId(organizationId: string, eventId: string): Promise<Booking[]> {
  //   // this.currEventIds = eventIds;
  //   // formulate querystring
  //   // let querystring: string = '';
  //   // if (this.currEventIds !== undefined && this.currEventIds.length > 0) {
  //   //   querystring += querystring === '' ? '?' : '&';
  //   //   querystring += `eventId=${this.currEventIds[0]}`;
  //   // }

  //   // this.context.dispatch
  //   const url = `${ApiEndpoints.apiEndpoint}/v2/session/event/bookings?organizationId=${organizationId}&eventId=${eventId}`;
  //   await apiSvc
  //     .httpGet(url)
  //     .then((response: SessionEventsResponse) => {
  //       this.events = response.data.items;
  //     })
  //     .catch((err: Error) => {
  //       return Promise.reject(err);
  //     });
  //   return Promise.resolve(this.bookings);
  // }

  @Action
  public async fetchEvents(startSeconds?: number, endSeconds?: number, sessionIds?: string[]): Promise<EventOccurance[]> {
    // console.log('fetchEvents');
    // save params
    this.currEndSeconds = endSeconds;
    this.currStartSeconds = startSeconds;
    this.currSessionIds = sessionIds;
    // formulate querystring
    let querystring = '';
    if (this.currEndSeconds !== undefined && this.currEndSeconds > 0) {
      querystring += querystring === '' ? '?' : '&';
      querystring += `endSeconds=${this.currEndSeconds}`;
    }
    if (this.currStartSeconds !== undefined && this.currStartSeconds > 0) {
      querystring += querystring === '' ? '?' : '&';
      querystring += `startSeconds=${this.currStartSeconds}`;
    }
    if (this.currSessionIds !== undefined && this.currSessionIds.length > 0) {
      querystring += querystring === '' ? '?' : '&';
      querystring += `sessionId=${this.currSessionIds[0]}`;
    }

    // this.context.dispatch
    await apiSvc
      .httpGet(ApiEndpoints.apiEndpoint + '/v2/event/list' + querystring)
      .then((response: EventsResponse) => {
        this.events = response.data.items;
      })
      .catch((err: Error) => {
        return Promise.reject(err);
      });
    return Promise.resolve(this.events);
  }

  @Action
  public async fetchSessions(request?: FetchSessionsRequest): Promise<Session[]> {
    this.setLoadingSessionsStatus(true);

    let fetchFresh = false;
    if (request !== undefined) {
      ({ fetchFresh } = request);
    }
    if (!fetchFresh && this.sessionsExpiresAt !== undefined && this.sessionsExpiresAt > Date.now()) return Promise.resolve(this.sessions);

    let res: SessionsResponse | undefined;
    const client = new SessionApiClient();
    await client
      .getSessions({
        organizationId: request?.organizationId,
        organizationIds: request?.organizationIds,
      })
      .then((response: SessionsResponse) => {
        // console.log('sessions response ', response);
        res = response;
        this.context.commit('setSessionResponse', response);
      })
      .catch((err: Error) => {
        this.setLoadingSessionsStatus(false);
        return Promise.reject(err);
      });
    if (res === undefined) {
      this.setLoadingSessionsStatus(false);
      return Promise.reject(new Error('Could not fetch sessions'));
    }

    this.setLoadingSessionsStatus(false);

    // this.setSessionResponse(res);
    return Promise.resolve(this.sessions);
  }

  @Action
  public async fetchSessionEvents(params: { sessionId: string; lower?: number; upper?: number; organizationId?: string }): Promise<EventsResponse> {
    const client = new SessionApiClient();
    let response: EventsResponse | undefined;
    await client
      .getSessionEvents(params)
      .then((res: EventsResponse) => {
        response = res;
        this.context.commit('setCurrentSessionEvents', res.data.items);
      })
      .catch((err: Error) => {
        return Promise.reject(err);
      });
    if (response === undefined) {
      return Promise.reject(new Error('EventsResponse is undefined'));
    }
    return Promise.resolve(response);
  }

  @Action
  public async fetchSessionEventsSinceToday(sessionId: string, organizationId?: string): Promise<EventsResponse> {
    // console.log('fetchSessionEventsSinceToday');
    // use the current org's id
    if (organizationId === undefined) {
      if (this.profileStore === undefined || this.profileStore.currOrganization === undefined) {
        return Promise.reject('ProfileModule is undefined');
      }
      organizationId = this.profileStore.currOrganization.organizationId || 'UNSET';
    }

    const lower =
      DateTime.local()
        .setZone(this.profileStore.currOrganization.timeZone || 'America/New_York')
        .setLocale(this.profileStore.currOrganization.locale || 'en-US')
        .startOf('day')
        .valueOf() / 1000;

    const url = `${ApiEndpoints.apiEndpoint}/v2/session/events/session?organizationId=${organizationId}&sessionId=${sessionId}&lower=${lower}`;
    // console.log('url ', url);
    let response: EventsResponse | undefined;
    const apiSvc = new ApiService();
    await apiSvc
      .httpGet(url)
      .then((res: EventsResponse) => {
        response = res;
        this.setCurrentSessionEvents(res.data.items);
      })
      .catch((err: Error) => {
        return Promise.reject(err);
      });
    if (response === undefined) {
      return Promise.reject(new Error('SessionEventsResponse is undefined'));
    }
    return Promise.resolve(response);
  }

  // bookReservation: async ({ commit }, payload) => {
  //   const url = `${BaseEndpoint}/session/v2/event/reservation`
  //   // console.log('Sessions Payload...', payload)
  //   await httpPost(url, data)
  //       .then(res => {
  //         // console.log('Reservations: ', res)
  //         // commit('setReservations', res.data.message.Items);
  //       })
  //       .catch(err => {
  //         // console.log('Sessions Fetch org user err:', err)
  //         // resolve(err)
  //       })

  //   return Promise.resolve();
  // },

  // @Action
  // public async cancelReservation(userId: string, lower: number = Date.now() / 1000, upper?: number, authUserId?: string) {
  //   cancelReservation: async ({ commit }, payload) => {
  //   const url = `${BaseEndpoint}/session/v2/event/cancellation`
  //   // console.log('cancelReservation Payload...', payload)
  //   await httpPost(url, payload)
  //       .then(res => {
  //         // console.log('Reservations: ', res)
  //         // commit('addReservation', res)
  //         // commit('setReservations', res.data.message.Items);
  //       })
  //       .catch(err => {
  //         // console.log('Sessions Fetch org user err:', err)
  //         // resolve(err)
  //       })

  //   return Promise.resolve();
  // }

  // @Action
  // public async fetchBookingsByUserId(userId: string, lower: number = Date.now() / 1000, upper?: number, authUserId?: string) {
  //   const url = `${ApiEndpoints.apiEndpoint}/v2/session/user/bookings?userId=${userId}&lower=${lower}`;
  //   // console.log('Sessions Payload...', payload)
  //   await apiSvc
  //     .httpGet(url)
  //     .then((response: SessionEventsResponse) => {
  //       // this.events = response.data.items;
  //     })
  //     .catch((err: Error) => {
  //       return Promise.reject(err);
  //     });
  //   return Promise.resolve(this.bookings);
  // }

  // /**
  //  * Get the organizations.
  //  */
  // get organizations(): Organization[] {
  //   const orgs: Organization[] = [];
  //   if (this.users && this.users !== undefined) {
  //     for (let i = 0; i < this.users.length; i += 1) {
  //       const org = this.users[i].organization;
  //       if (org && org !== undefined) {
  //         orgs.push(org);
  //       }
  //     }
  //   }
  //   return orgs;
  // }

  // get orgSessionCategories(): string[] {
  //   const categories: string[] = [];
  //   categories.push('Location');
  //   categories.push('Class');
  //   return categories;
  // }

  get sessionTable(): TableBvResponseBody<Session> {
    if (this.sessionsResponse === undefined || this.sessionsResponse.data === undefined) {
      return new TableBvResponseBody<Session>();
    }
    return this.sessionsResponse.data;
  }

  get sessionItems(): Session[] {
    if (this.sessionsResponse === undefined || this.sessionsResponse.data === undefined || this.sessionsResponse.data.items === undefined) {
      return [];
    }
    return this.sessionsResponse.data.items;
  }

  get sessionsById(): Map<string, Session> {
    const map = new Map<string, Session>();
    if (this.sessions !== undefined) {
      for (const session of this.sessions) {
        const id = session.sessionId;
        if (id !== undefined) map.set(id, session);
      }
    }
    return map;
  }

  get currentSession(): Session | null {
    return this.currSession;
  }

  get currentSessionId(): string | null {
    return this.currSessionId;
  }

  // get currentSession(): Session | undefined {
  //   if (this.sessions === undefined) {
  //     return undefined;
  //   }
  //   if (this.currSessionId === undefined) {
  //     return undefined;
  //   }
  //   for (let session of this.sessions) {
  //     if (session.sessionId === this.currSessionId) {
  //       return session;
  //     }
  //   }
  //   return undefined;
  // }

  get currentSessions(): Session[] {
    if (this.sessionsResponse === undefined) {
      return [];
    }
    return this.sessionsResponse.data.items;
  }

  // get currSessionId(): string | undefined {
  //   return this.currSessionId;
  // }

  // @Mutation
  // public setCurrentOrganization(org: Organization): void {
  //   this.currOrganization = org;
  // }

  // @Mutation
  // public setCurrentUser(user: User): void {
  //   this.currUser = user;
  // }

  @Mutation
  public setCurrentSessionEvents(se: EventOccurance[]): void {
    this.currSessionEvents = [];
    this.currSessionEvents = this.currSessionEvents.concat(se);
  }

  @Mutation
  public setCurrentSessionId(id: string): void {
    this.currSessionId = id;
    // set the current session
    if (this.currSessionId == null || this.currSessionId === undefined) {
      this.currSession = null;
    }
    if (this.sessions !== undefined && this.currSessionId !== undefined) {
      for (const session of this.sessions) {
        if (session.sessionId === this.currSessionId) {
          this.currSession = session;
        }
      }
    }
  }

  @Mutation
  public setLoadingSessionsStatus(status: boolean): void {
    this.isLoadingSessions = status;
  }

  @Mutation
  public setSessionResponse(sessionsResponse: SessionsResponse): void {
    this.sessionsResponse = undefined;
    this.sessionsResponse = sessionsResponse;
    this.sessions = [];
    this.sessions = this.sessions.concat(sessionsResponse.data.items);
    this.sessionsExpiresAt = Date.now() + 30 * 60 * 1000; // expires in thirty minutes
    // set the current session
    if (this.sessions !== undefined && this.currSessionId != null && this.currSessionId !== undefined) {
      for (const session of this.sessions) {
        if (session.sessionId === this.currSessionId) {
          this.currSession = session;
        }
      }
    }
  }
}

export interface FetchSessionsRequest {
  fetchFresh: boolean;
  organizationId: string;
  organizationIds?: string[];
}
