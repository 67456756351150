import { getModule } from 'vuex-module-decorators';
import { VehicleRequest, VehiclesResponse } from '@fgl/funfangle-sdk/dist/rest/profile';
import { ApiEndpoints } from '@/constants';
import { ProfileModule } from '@/store';
import { store } from '../../store/Store';
import { ApiClient } from '.';

export class ProfileVehicleApiClient extends ApiClient {
  public profileStore: ProfileModule = getModule(ProfileModule, store);

  async getHouseholdVehicles(householdId?: string): Promise<VehiclesResponse> {
    let url = `${ApiEndpoints.apiEndpoint}/v2/profile/vehicle/household/list?householdId=${householdId || this.profileStore.householdId || this.profileStore.userId || ''}`;
    if (this.profileStore.userId != null && this.profileStore.userId !== undefined) {
      url = `${url}&userId=${this.profileStore.userId}`;
    }
    if (this.profileStore.currOrganization.organizationId != null && this.profileStore.currOrganization.organizationId !== undefined && this.profileStore.currOrganization.organizationId !== '') {
      url = `${url}&organizationId=${this.profileStore.currOrganization.organizationId}`;
    }

    return new ApiClient().httpGet(url);
  }

  async getOrganizationVehicles(): Promise<VehiclesResponse> {
    let url = `${ApiEndpoints.apiEndpoint}/v2/profile/vehicle/organization/list?`;
    if (this.profileStore.userId != null && this.profileStore.userId !== undefined) {
      url = `${url}&userId=${this.profileStore.userId}`;
    }
    if (this.profileStore.currOrganization.organizationId != null && this.profileStore.currOrganization.organizationId !== undefined && this.profileStore.currOrganization.organizationId !== '') {
      url = `${url}&organizationId=${this.profileStore.currOrganization.organizationId}`;
    }

    return new ApiClient().httpGet(url);
  }

  async postDeleteVehicle(request: VehicleRequest) {
    const url = `${ApiEndpoints.apiEndpoint}/v2/profile/vehicle/delete`;
    return new ApiClient().httpPost(url, request);
  }

  async postUpsertVehicle(request: VehicleRequest) {
    const url = `${ApiEndpoints.apiEndpoint}/v2/profile/vehicle/upsert`;
    return new ApiClient().httpPost(url, request);
  }

  // public async requestInvitation(params: InvitationRequest): Promise<InvitationResponse> {
  //   if (params.organizationId == null || params.organizationId === undefined || params.organizationId === '') {
  //     delete params.organizationId;
  //   }
  //   const url = `${ApiEndpoints.apiEndpoint}/v2/ua/invitation/request`;
  //   return new ApiClient().httpPost(url, params);
  // }
}
