import { ProfileModule } from '@/store';

export class LoginUtils {
  profileStore: ProfileModule;

  constructor(profileStore: ProfileModule) {
    this.profileStore = profileStore;
  }

  isDomainUrlDifferent(comparisonDomainUrl: string): boolean {
    const domainUrl = this.getDomainHref();
    return domainUrl !== comparisonDomainUrl;
  }

  /**
   *
   * @param targetUrl
   * @param defaultUrl
   * @param host
   * @param port
   */
  getDomainHref(targetUrl = this.profileStore.currOrganization.domainUrl, defaultUrl = 'https://portal.funfangle.com', host = window.location.host, port = window.location.port): string {
    const url = targetUrl || defaultUrl;
    return this.getUrlLocalized(url, host, port);
  }

  /**
   *
   * @param targetUrl
   */
  getUrlLocalized(targetUrl: string, host = window.location.host, port = window.location.port): string {
    let lvhMe = '';
    if (host.indexOf('lvh.me') >= 0) {
      lvhMe = '.lvh.me';
    }

    if (host && host.indexOf('dev-console.dev.') >= 0 && targetUrl && targetUrl.indexOf('//console.') >= 0) targetUrl = targetUrl.replace('//console.', '//dev-console.dev.');
    if (host && host.indexOf('dev-portal.dev.') >= 0 && targetUrl && targetUrl.indexOf('//portal.') >= 0) targetUrl = targetUrl.replace('//portal.', '//dev-portal.dev.');
    if (host && host.indexOf('staging-console.') >= 0 && targetUrl && targetUrl.indexOf('//console.') >= 0) targetUrl = targetUrl.replace('//console.', '//staging-console.');
    if (host && host.indexOf('staging-portal.') >= 0 && targetUrl && targetUrl.indexOf('//portal.') >= 0) targetUrl = targetUrl.replace('//portal.', '//staging-portal.');
    if (host && host.indexOf('test-console.') >= 0 && targetUrl && targetUrl.indexOf('//console.') >= 0) targetUrl = targetUrl.replace('//console.', '//test-console.');
    if (host && host.indexOf('test-portal.') >= 0 && targetUrl && targetUrl.indexOf('//portal.') >= 0) targetUrl = targetUrl.replace('//portal.', '//test-portal.');

    const a = document.createElement('a');
    a.href = targetUrl;
    if (port !== undefined && port !== '' && port !== '443') {
      return `${a['protocol']}//${a['hostname']}${lvhMe}:${port}${window.location.pathname}`;
    }
    return `${a['protocol']}//${a['hostname']}${lvhMe}${window.location.pathname}`;
  }

  getWindowHref() {}
  /** Redirect? */
  redirectToPortalFresh(): boolean {
    return (
      this.profileStore.currUser === undefined ||
      this.profileStore.currOrganization === undefined ||
      (this.profileStore.isOrganizationConsole &&
        this.profileStore.currUser.role !== 'organization-manager' &&
        this.profileStore.currUser.role !== 'organization-employee' &&
        this.profileStore.currUser.role !== 'employee')
    );
  }
  /** Redirect? */
  redirectToPortalLoggedIn(): boolean {
    if (this.profileStore.isOrganizationConsole) return false;
    if (this.profileStore.currOrganization === undefined) return true;
    const domainUrl = this.getDomainHref();
    // console.log('redirectToPortalLoggedIn | domainUrl=', domainUrl, ' origin=', window.location.origin, window.location.origin !== this.getDomainHref());
    return window.location.origin !== this.getDomainHref();
  }
}
