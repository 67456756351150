import { Invoice } from '@fgl/funfangle-sdk/dist/rest/bank';
import { Booking, EventOccurance } from '@fgl/funfangle-sdk/dist/rest/event';
import { LodgingStay, LodgingUnit } from '@fgl/funfangle-sdk/dist/rest/lodging';
import { Pet, User, Vehicle } from '@fgl/funfangle-sdk/dist/rest/profile';
import { TaskTicketTableItem } from '.';

export class TaskTicketTableRow {
  booking?: Booking;

  creator?: User;

  event?: EventOccurance;

  invoice?: Invoice;

  item?: TaskTicketTableItem;

  lodging?: LodgingUnit;

  stay?: LodgingStay;

  tenant?: User;

  tenantPets?: Pet[];

  tenantVehicles?: Vehicle[];
}
