import { ApiEndpoints } from './../../../../constants';
import { ApiService } from '@/shared/auth/auth-cognito';
import { OrganizationRequest } from '@fgl/funfangle-sdk/dist/rest/profile';
import { ProfileModule } from '@/store';

export class OrganizationProfileClient {
  commonZones = [
    {
      common: 'US/Eastern',
      zone: 'America/New_York',
    },
    {
      common: 'US/Central',
      zone: 'America/Chicago',
    },
    {
      common: 'US/Mountain',
      zone: 'America/Denver',
    },
    {
      common: 'US/Pacific',
      zone: 'America/Los_Angeles',
    },
    {
      common: 'US/Alaska',
      zone: 'America/Anchorage',
    },
    {
      common: 'US/Hawaii',
      zone: 'Pacific/Honolulu',
    },
    {
      common: 'CA/Pacific',
      zone: 'America/Vancouver',
    },
    {
      common: 'CA/Mountain',
      zone: 'America/Edmonton',
    },
    {
      common: 'CA/Central',
      zone: 'America/Winnipeg',
    },
    {
      common: 'CA/Eastern',
      zone: 'America/Toronto',
    },
    {
      common: 'CA/Atlantic',
      zone: 'America/Halifax',
    },
  ];

  profileStore: ProfileModule | undefined;

  async performUpdate(request: OrganizationRequest) {
    const url = `${ApiEndpoints.apiEndpoint}/v2/profile/organization`;
    const apiSvc = new ApiService();
    return apiSvc.httpPost(url, request);
  }

  setProfileStore(store: ProfileModule) {
    this.profileStore = store;
  }

  async updatePortalSettings(portalSettingDisplayLodging: boolean) {
    if (this.profileStore === undefined || this.profileStore.currOrganization === undefined || this.profileStore.currOrganization.organizationId === undefined) return;
    const orgRequest = new OrganizationRequest(this.profileStore.currOrganization.organizationId);
    orgRequest.portalSettingDisplayLodging = portalSettingDisplayLodging;
    const apiSvc = new OrganizationProfileClient();
    return apiSvc.performUpdate(orgRequest);
  }
}
