export const HOME = 'home';
export const ACCOUNT = 'account';
export const ACCOUNT_CREDENTIALS = 'accountCredentials';
export const ACCOUNT_IMPORT_STATUS = 'accountImportStatus';
export const ACCOUNT_IMPORT_UPLOAD = 'accountImportUpload';
export const ACCOUNTS_VEHICLES = 'accountsVehicles';
export const ACTIVITY_BOOKING_HOUSEHOLD = 'activityBookingHousehold';
export const ACTIVITY_BOOKING_MAKE = 'activityBookingMaker';
export const ACTIVITY_EDITOR = 'activityEditor';
export const ACTIVITY = 'activityViewer';
export const ACTIVITIES = 'activities';
export const AUTH_ACTIVATE = 'authActivate';
export const AUTH_ACTIVATE_QS = 'authActivateQS';
export const AUTH_LOGIN = 'authLogin';
export const AUTH_LOGOUT = 'authLogout';
export const AUTH_CREATE = 'authCreate';
export const AUTH_DENIED = 'authDenied';
export const AUTH_GETSTARTED = 'authGetStarted';
export const AUTH_INVITATION = 'authInvitation';
export const AUTH_NEWPASSWORD = 'authNewPassword';
export const AUTH_RECOVERY = 'authRecovery';
export const AUTH_SELECT_ORG = 'authSelectOrganization';
export const AUTH_VERIFY = 'authVerify';
export const AUTH_SIGNOUT = 'authSignOut';
export const CASHLESS_HOME = 'cashless';
export const INVOICE_DETAIL = 'invoiceDetail';
export const INVOICE_LIST = 'invoiceList';
export const INVOICE_MAKE_PAYMENT = 'invoiceMakePayment';
export const LODGING_ALL_STAYS = 'lodgingAllStays';
export const LODGING_UNIT_BOOKINGS = 'lodgingUnitBookings';
export const LODGING_UNIT_DETAILS = 'lodgingUnitDetails';
export const LODGING_UNIT_STAYS = 'lodgingUnitStays';
export const LODGING_UNIT_USERS = 'lodgingUnitUsers';
export const NOT_IMPLEMENTED = 'notImplemented';
export const SESSION_EDITOR = 'sessionEditor';
export const SESSION = 'session';
export const SESSIONS = 'sessions';
export const SETTINGS = 'settingsHome';
export const SETTING_CONFIG_ALL = 'settingsConfigAll';
export const SETTING_LOGO = 'settingsLogoUpload';
export const SETTING_WAIVER = 'settingsWaiver';
export const SYS_ORGANIZATION_ADD = 'sysOrganizationAdd';
export const SYS_ORGANIZATION_LIST = 'sysOrganizationList';
export const SYS_RESET = 'sysReset';
export const TASK_ACTION = 'taskAction';
export const TASKS = 'tasks';

export class RouterNames {
  static HOME = 'home';
  static ACCOUNT = 'account';
  static ACCOUNT_CREDENTIALS = 'accountCredentials';
  static ACCOUNTS_VEHICLES = 'accountsVehicles';
  static ACTIVITY_EDITOR = 'activityEditor';
  static ACTIVITY_BOOKING_HOUSEHOLD = 'activityBookingHousehold';
  static ACTIVITY_BOOKING_MAKE = 'activityBookingMaker';
  static ACTIVITY = 'activityViewer';
  static ACTIVITIES = 'activities';
  static AUTH_LOGIN = 'authLogin';
  static AUTH_LOGOUT = 'authLogout';
  static AUTH_DENIED = 'authDenied';
  static AUTH_ACTIVATE = 'authActivate';
  static AUTH_ACTIVATE_QS = 'authActivateQS';
  static AUTH_CREATE = 'authCreate';
  static AUTH_GETSTARTED = 'authGetStarted';
  static AUTH_INVITATION = 'authInvitation';
  static AUTH_NEWPASSWORD = 'authNewPassword';
  static AUTH_RECOVERY = 'authRecovery';
  static AUTH_SELECT_ORG = 'authSelectOrganization';
  static AUTH_VERIFY = 'authVerify';
  static AUTH_SIGNOUT = 'authSignOut';
  static CASHLESS_HOME = 'cashless';
  static ACCOUNT_IMPORT_STATUS = 'accountImportStatus';
  static ACCOUNT_IMPORT_UPLOAD = 'accountImportUpload';
  static INVOICE_DETAIL = 'invoiceDetail';
  static INVOICE_LIST = 'invoiceList';
  static INVOICE_MAKE_PAYMENT = 'invoiceMakePayment';
  static LODGING_ALL_STAYS = 'lodgingAllStays';
  static LODGING_UNIT_BOOKINGS = 'lodgingUnitBookings';
  static LODGING_UNIT_DETAILS = 'lodgingUnitDetails';
  static LODGING_UNIT_STAYS = 'lodgingUnitStays';
  static LODGING_UNIT_USERS = 'lodgingUnitUsers';
  static NOT_IMPLEMENTED = 'notImplemented';
  static SESSION_EDITOR = 'sessionEditor';
  static SESSION = 'session';
  static SESSIONS = 'sessions';
  static SETTINGS = 'settingsHome';
  static SETTING_CONFIG_ALL = 'settingsConfigAll';
  static SETTING_LOGO = 'settingsLogoUpload';
  static SETTING_WAIVER = 'settingsWaiver';
  static SITEMAP = 'sitemap';
  static SYS_ORGANIZATION_ADD = 'sysOrganizationAdd';
  static SYS_ORGANIZATION_LIST = 'sysOrganizationList';
  static SYS_RESET = 'sysReset';
  static TASK_ACTION = 'taskAction';
  static TASKS = 'tasks';
}
