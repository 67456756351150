<template>
  <!-- <v-app> -->
  <app-console v-if="isConsole" data-app />
  <app-portal v-else-if="!isConsole" data-app />
  <!-- </v-app> -->
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import { VApp } from 'vuetify/lib';
import AppConsole from './AppConsole.vue';
import AppPortal from './AppPortal.vue';
import { SiteUtils } from '@/utils';

@Component({
  components: {
    AppConsole,
    AppPortal,
    VApp,
  },
})
export default class Sessions extends Vue {
  get isConsole(): boolean {
    return SiteUtils.isConsole();
  }
}
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>
