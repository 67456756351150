import { store } from '@/store/Store';
import { OrganizationType, User } from '@fgl/funfangle-sdk/dist/rest/profile';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
// import { ApiService } from '@/shared/auth/auth-cognito';
import { getModule } from 'vuex-module-decorators';
import { ProfileModule } from './ProfileModule';

// const apiSvc = new ApiService();

@Module({ dynamic: true, store, name: 'nav' })
export class NavModule extends VuexModule {
  profileStore: ProfileModule = getModule(ProfileModule);
  backbarStack: any[] = [];
  organizationsFilter = '';
  selectedUser: User | null = null;
  selectedUserId = 'UNSET';
  showSideMenu = false;
  sideToggleCollapsed = false;

  get allowCreateAuthorizedUsers(): boolean {
    if (this.profileStore.currOrganization && this.profileStore.currOrganization.portalSettings && 'allowCreateAuthorizedUsers' in this.profileStore.currOrganization.portalSettings) {
      return this.profileStore.currOrganization.portalSettings.allowCreateAuthorizedUsers === true;
    }
    return false;
  }

  get buttonDangerColor(): string {
    return 'danger';
  }

  get buttonDisabledColor(): string {
    return 'gray';
  }

  get buttonInfoColor(): string {
    return 'info';
  }

  get buttonInfoOutlineColor(): string {
    return 'outline-info';
  }

  get buttonLightColor(): string {
    return 'light';
  }

  get buttonNextColor(): string {
    return 'amber';
  }

  get buttonNextOutlineColor(): string {
    return 'outline-amber';
  }

  get buttonSuccessColor(): string {
    return 'success';
  }

  get buttonWarningColor(): string {
    return 'warning';
  }

  get displayDashboard(): boolean {
    if (
      this.profileStore.currOrganization !== undefined &&
      (this.profileStore.currOrganization.organizationId === '363d40e5-e923-41f7-a53c-cff8abb1ea6b' || this.profileStore.currOrganization.organizationId === '363d40e5-e923-41f7-a53c-cff8abb1ea6b')
    ) {
      return true;
    }
    return false;
  }

  get displayLodging() {
    if (
      this.profileStore.currOrganization !== undefined &&
      this.profileStore.currOrganization.portalSettings !== undefined &&
      this.profileStore.currOrganization.portalSettings.displayLodging === true
    ) {
      return true;
    }
    return false;
  }

  get displayLodgingStays(): boolean {
    if (
      // this.isFeatureEventReservationsEnabled &&
      this.profileStore.currOrganization.portalSettings !== undefined &&
      this.profileStore.currOrganization.portalSettings.displayRenterStays === true
      // this.profileStore.isLongTermTenant !== true &&
      // this.profileStore.isShortTermTenant !== true
    ) {
      return true;
    }
    if (this.profileStore.organizationId === '0b9c4c63-34a2-4361-9a62-27db40b8e0f0' || this.profileStore.organizationId === 'prod-dup-34a2-4361-9a62-27db40b8e0f0') {
      return true;
    }
    return false;
  }

  get displayPets() {
    // see if the 'route' is configured to show the backbar
    if (
      // this.isSelectSeabluffe &&
      this.profileStore.currOrganization !== undefined &&
      this.profileStore.currOrganization.portalSettings !== undefined &&
      this.profileStore.currOrganization.portalSettings.displayPets === true
    ) {
      return true;
    }
    if (this.profileStore.organizationId === '0b9c4c63-34a2-4361-9a62-27db40b8e0f0' || this.profileStore.organizationId === 'prod-dup-34a2-4361-9a62-27db40b8e0f0') {
      return true;
    }
    return false;
  }

  get displayTasks() {
    // see if the 'route' is configured to show the backbar
    if (
      this.profileStore.currOrganization !== undefined &&
      this.profileStore.currOrganization.portalSettings !== undefined &&
      this.profileStore.currOrganization.portalSettings.displayTasks === true
    ) {
      return true;
    }
    return false;
  }

  get displayTransactions(): boolean {
    if (
      this.profileStore.currOrganization !== undefined &&
      (this.profileStore.currOrganization.organizationId === '363d40e5-e923-41f7-a53c-cff8abb1ea6b' || this.profileStore.currOrganization.organizationId === '363d40e5-e923-41f7-a53c-cff8abb1ea6b')
    ) {
      return false;
    }
    return false;
  }

  /**
   *
   */
  get displayReports() {
    // if (this.profileStore.organizationId === 'b0b7156e-d9d1-45c9-9144-c507ff9446fb' || // Monarch
    //     this.profileStore.organizationId === '3c5c3489-7e1a-47b7-9497-516704dc7039' // High Point
    // ) {
    //   return true;
    // }
    // return false;
    if (
      this.profileStore.currOrganization !== undefined &&
      (this.profileStore.currOrganization.organizationId === '363d40e5-e923-41f7-a53c-cff8abb1ea6b' || // Bartlett Parks
        this.profileStore.currOrganization.organizationId === '363d40e5-e923-41f7-a53c-cff8abb1ea6b' || // Bartlett Parks
        this.profileStore.currOrganization.organizationId === '3c5c3489-7e1a-47b7-9497-516704dc7039' || // High Point
        this.profileStore.currOrganization.organizationId === '3c5c3489-7e1a-47b7-9497-516704dc7039') // High Point
    ) {
      return false;
    }
    return false;
  }

  get displayVehicles() {
    // see if the 'route' is configured to show the backbar
    if (
      // this.isSelectSeabluffe &&
      this.profileStore.currOrganization !== undefined &&
      this.profileStore.currOrganization.portalSettings !== undefined &&
      this.profileStore.currOrganization.portalSettings.displayVehicles === true
    ) {
      return true;
    }
    if (this.profileStore.organizationId === '0b9c4c63-34a2-4361-9a62-27db40b8e0f0' || this.profileStore.organizationId === 'prod-dup-34a2-4361-9a62-27db40b8e0f0') {
      return true;
    }
    return false;
  }

  get logoSidebar() {
    if (location.hostname.search(/[.]lvh[.]me$/)) {
      return `https://files-na.funfangle.com/org/${this.profileStore.organizationId}/dynamic/logos/sidebar-logo.png`;
    }
    return `https://files-na.funfangle.com/org/${this.profileStore.organizationId}/dynamic/logos/sidebar-logo.png`;
  }

  get logoMain() {
    if (location.hostname.search(/[.]lvh[.]me$/)) {
      return `https://files-na.funfangle.com/org/${this.profileStore.organizationId}/dynamic/logos/main-logo.png`;
    }
    return `https://files-na.funfangle.com/org/${this.profileStore.organizationId}/dynamic/logos/main-logo.png`;
  }

  get locale(): string {
    let locale = 'en-US';
    if (this.profileStore !== undefined && this.profileStore.organization !== undefined) {
      locale = this.profileStore.organization.locale || 'en-US';
    }
    return locale;
  }

  get maxAuthorizedUsers(): number | null {
    if (this.profileStore.organizationId === 'e926ebce-c1af-4b5f-b5e4-850fd0f4afcb') return 6;
    return null;
  }

  get orgTypes(): OrganizationType[] {
    return [
      {
        isMirror: false,
        label: 'Camps',
        organizationType: 'camp',
      },
      {
        isMirror: false,
        label: 'Camps Disabled',
        organizationType: 'inactive-camp',
      },
      {
        isMirror: true,
        label: 'Camps Mirrored',
        organizationType: 'camp',
      },
      {
        isMirror: false,
        label: 'Communities',
        organizationType: 'rec-club',
      },
      {
        isMirror: false,
        label: 'Communities Disabled',
        organizationType: 'inactive-rec-club',
      },
      {
        isMirror: true,
        label: 'Communities Mirrored',
        organizationType: 'rec-club',
      },
      {
        isMirror: false,
        label: 'Unknown',
        organizationType: 'unknown',
      },
    ];
  }

  get timeZone(): string {
    let timeZone = 'America/New_York';
    if (this.profileStore !== undefined && this.profileStore.organization !== undefined) {
      timeZone = this.profileStore.organization.timeZone || 'America/New_York';
    }
    return timeZone;
  }

  get usersView() {
    if (
      this.profileStore.currOrganization !== undefined &&
      this.profileStore.currOrganization.portalSettings !== undefined &&
      this.profileStore.currOrganization.portalSettings.usersView !== undefined
    ) {
      return this.profileStore.currOrganization.portalSettings.usersView;
    }
    return 'all';
  }

  @Mutation
  pushBackbarStack(item: any) {
    this.backbarStack.push(item);
  }

  @Mutation
  resetBackbarStack(): void {
    this.backbarStack = [];
  }

  @Mutation
  setBackbarStack(items: any[]): void {
    this.backbarStack = items;
  }

  @Mutation
  public setOrganizationsFilter(filter: string): void {
    this.organizationsFilter = filter;
  }

  setSelectedUser(u: User | null | undefined): void {
    if (u != null && u !== undefined) {
      this.selectedUser = u;
      this.selectedUserId = u.userId || 'UNSET';
    } else {
      this.selectedUser = null;
      this.selectedUserId = 'UNSET';
    }
  }

  @Mutation
  setShowSideMenu(showSideMenu: boolean) {
    this.showSideMenu = showSideMenu;
  }

  @Mutation
  setSideToggleCollapse(toggleCollapsed: boolean) {
    this.sideToggleCollapsed = toggleCollapsed;
  }

  @Mutation
  toggleSideMenu() {
    this.showSideMenu = !this.showSideMenu;
  }
}
