// bootstrap vue
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
// mdb vue
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'flatpickr/dist/flatpickr.css';
import 'mdbvue/lib/css/mdb.min.css';
import { Notify } from 'mdbvue';
// import vueVimeoPlayer from 'vue-vimeo-player'
// import vimeoPlayerWrapper from '@/components/vimeo-player-wrapper/main'
import '@/assets/bootstrap-vue/overrides.css'; // Import the global CSS file

// // core vue
import Vue from 'vue';
import singleSpaVue from 'single-spa-vue';
// import { registerApplication, start } from 'single-spa';

import { i18n } from '@/i18n';
import router from './router';
import { store } from './store/Store';
// import '@/sass/overrides.sass';
import App from './App.vue';
import './registerServiceWorker';
// utilities
import axios from 'axios';

// import './plugins/base';
// import './plugins/chartist';

import VueAxios from 'vue-axios';
// import VueClosable from 'vue-closable';
import VueCookies from 'vue-cookies';
import VueMq from 'vue-mq'; // no typescript support, but more functionality
import VueMediaQuery from 'vue-media-q'; // better typescript support, but less functionality
// var VueScrollTo = require('vue-scrollto');
// Vue.use(VueScrollTo);
// import VueSidebarMenu from 'vue-sidebar-menu';
import 'vue2-timepicker/dist/VueTimepicker.css';
import Vue2TouchEvents from 'vue2-touch-events';
Vue.use(Vue2TouchEvents);
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css';
import vuetify from './vuetify';

// import 'vue-ngx-datatable/release/index.css';
// import DatatableComponent from 'vue-ngx-datatable/release';
// Vue.component('ngx-datatable', DatatableComponent);

// import VueHtmlToPaper from 'vue-html-to-paper';
// const options = {
//   name: '_blank',
//   specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
//   styles: ['https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css', 'https://unpkg.com/kidlat-css/css/kidlat.css'],
//   timeout: 1000, // default timeout before the print window appears
//   autoClose: true, // if false, the window will not close after printing
//   windowTitle: window.document.title // override the window title
// };
// Vue.use(VueHtmlToPaper, options);
// or, using the defaults with no stylesheet
// Vue.use(VueHtmlToPaper);

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(BootstrapVue);
Vue.use(Notify);
// Vue.use(VueClosable);
Vue.use(VueCookies);
// Vue.use(VueSidebarMenu);

// import Chartist from 'chartist';
// Vue.prototype.$Chartist = Chartist;

// import VueChartist from 'vue-chartist';
// Vue.use(VueChartist);

import VueApexCharts from 'vue-apexcharts';
Vue.use(VueApexCharts);
Vue.component('Apexchart', VueApexCharts);

Vue.use(VueMq, {
  breakpoints: {
    sm: 450,
    md: 1250,
    lg: Infinity,
  },
  defaultBreakpoint: 'sm',
});

Vue.use(VueMediaQuery, {
  xs: 425,
  sm: 769,
  md: 992,
  lg: Infinity,
});

// import Notify from 'mdbvue/lib/components/Notify'
// Vue.use(Notify)
// Vue.use(vimeoPlayerWrapper)
// Vue.use(vueVimeoPlayer)

// Vue.directive('click-outside', {
//   bind: function(el, binding, vnode) {
//     el.clickOutsideEvent = function(event) {
//       // here I check that click was outside the el and his childrens
//       if (!(el == event.target || el.contains(event.target))) {
//         // and if it did, call method provided in attribute value
//         vnode.context[binding.expression](event);
//       }
//     };
//     document.body.addEventListener('click', el.clickOutsideEvent);
//   },
//   unbind: function(el) {
//     document.body.removeEventListener('click', el.clickOutsideEvent);
//   }
// });

// new Vue({
//   i18n,
//   router,
//   store,
//   vuetify,
//   render: (h: any) => h(App),
// }).$mount('#app');

const app = singleSpaVue({
  Vue,
  appOptions: {
    vuetify,
    render: (h: any) => h(App),
    i18n,
    router,
    store,
  },
});

// (window as any).vueApp = app;
export const bootstrap = app.bootstrap;
export const mount = app.mount;
export const unmount = app.unmount;

// if (process.env.SINGLE_SPA_LOCAL) {
//   registerApplication({
//     name: 'funfangle-console',
//     app,
//     activeWhen: ['/'],
//     // customProps: {
//     //   authToken: 'safd787sd90d87s90sdf8fs0d9',
//     // },
//   });

//   start();
// }
