import { getModule } from 'vuex-module-decorators';
import { EventsResponse } from '@fgl/funfangle-sdk/dist/rest/event';
import { SessionRequest, SessionsResponse } from '@fgl/funfangle-sdk/dist/rest/session';
import { ApiEndpoints } from '@/constants';
import { ProfileModule } from '@/store';
import { store } from '../../store/Store';
import { ApiClient } from '.';

export class SessionApiClient extends ApiClient {
  public profileStore: ProfileModule = getModule(ProfileModule, store);

  /**
   *
   * @param params
   * @returns
   */
  public async getSessions(params: { organizationId?: string; organizationIds?: string[] }): Promise<SessionsResponse> {
    // use the current org's id
    let organizationId: string | undefined;
    let organizationIds: string[] | undefined;
    if (params !== undefined) {
      ({ organizationId, organizationIds } = params);
    }
    if (organizationId === undefined) {
      if (this.profileStore === undefined || this.profileStore.currOrganization === undefined) {
        return Promise.reject('ProfileModule is undefined');
      }
      organizationId = this.profileStore.currOrganization.organizationId;
    }

    // this.context.dispatch
    let url = `${ApiEndpoints.apiEndpoint}/v2/session/list`;
    if (organizationId !== undefined && organizationId !== 'UNSET') url = `${url}?organizationId=${organizationId}`;
    if (organizationIds !== undefined && organizationIds.length > 0) url = `${url}&organizationIds=${encodeURIComponent(organizationIds.join(','))}`;

    const apiSvc = new ApiClient();
    return apiSvc.httpGet(url);
  }

  /**
   *
   * @param params
   * @returns
   */
  public async getSessionEvents(params: { sessionId?: string; lower?: number; upper?: number; organizationId?: string; organizationIds?: string[] }): Promise<EventsResponse> {
    // console.log('fetchSessionEvents');
    // use the current org's id
    if (params.organizationId === undefined) {
      if (this.profileStore === undefined || this.profileStore.currOrganization === undefined) {
        return Promise.reject('ProfileModule is undefined');
      }
      params.organizationId = this.profileStore.currOrganization.organizationId || 'UNSET';
    }

    let url = `${ApiEndpoints.apiEndpoint}/v2/session/events/${params.sessionId === undefined ? 'organization' : 'session'}?organizationId=${params.organizationId}`;
    if (params.organizationIds !== undefined && params.organizationIds.length > 0) url = `${url}&organizationIds=${encodeURIComponent(params.organizationIds.join(','))}`;
    if (params.sessionId !== undefined) url = `${url}&sessionId=${params.sessionId}`;
    if (params.lower !== undefined && !isNaN(params.lower)) url = `${url}&lower=${params.lower}`;
    if (params.upper !== undefined && !isNaN(params.upper)) url = `${url}&upper=${params.upper}`;
    const apiSvc = new ApiClient();
    return apiSvc.httpGet(url);
  }

  async postSession(request: SessionRequest) {
    const url = `${ApiEndpoints.apiEndpoint}/v2/session/update`;
    return new ApiClient().httpPost(url, request);
  }
}
