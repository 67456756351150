import { getModule } from 'vuex-module-decorators';
import { OrganizationCreateRequest } from '@fgl/funfangle-sdk/dist/rest/v3/organization';
import { ApiEndpoints } from '@/constants';
import { ProfileModule } from '@/store';
import { store } from '../../store/Store';
import { ApiClient } from '.';

export class OrganizationCreateApiClient extends ApiClient {
  public profileStore: ProfileModule = getModule(ProfileModule, store);
  async postCreateOrganization(request: OrganizationCreateRequest) {
    const url = `${ApiEndpoints.apiEndpoint}/v3/org/create`;
    return new ApiClient().httpPost(url, request);
  }
}
