import { TaskModule } from '@/store';
import { TaskTicket } from '@fgl/funfangle-sdk/dist/rest/task';

export class TaskUtils {
  taskStore: TaskModule;

  constructor(taskStore: TaskModule) {
    this.taskStore = taskStore;
  }

  public getTicket(ticketId: string): TaskTicket | null {
    if (this.taskStore.tickets !== undefined) {
      for (const ticket of this.taskStore.tickets) {
        if (ticket.ticketId === ticketId) {
          return ticket;
        }
      }
    }
    return null;
  }
}
