<template>
  <v-navigation-drawer v-model="menuShown" :permanent="menuShown" :temporary="!menuShown" app style="background-color: whitesmoke">
    <v-list-item class="active-tile">
      <template v-if="!showOrganizationSearch">
        <v-list-item-avatar>
          <v-img src="/img/icons/android-chrome-192x192.png" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-skeleton-loader v-if="currentOrganizationNameShort === ''" type="text" height="15" />
          <v-list-item-title class="text-h6">
            {{ currentOrganizationNameShort }}
          </v-list-item-title>
          <v-list-item-subtitle style="font-size: x-small"> FunFangle Console </v-list-item-subtitle>
          <!-- <mdb-navbar-nav right> -->
          <!-- </mdb-navbar-nav> -->
        </v-list-item-content>
        <v-icon v-if="organizations !== undefined && organizations.length > 1" class="float-right" style="cursor: pointer" @click.prevent.native="showOrganizationSearch = !showOrganizationSearch">{{
          mdiChevronDownCircle
        }}</v-icon>
      </template>
      <template v-else>
        <v-autocomplete
          v-model="selectedOrganization"
          :items="organizations"
          color="white"
          hide-no-data
          hide-selected
          item-text="organizationName"
          item-value="organizationId"
          label="Organizations"
          placeholder="Start typing to search"
          prepend-icon="mdi-magnify"
          return-object
          @blur="showOrganizationSearch = !showOrganizationSearch"
        ></v-autocomplete>
      </template>
    </v-list-item>

    <template v-if="hasMessages">
      <!-- <v-divider v-if="menuIndex > 0" :key="`${menuIndex}-divider-key`"></v-divider> -->
      <v-list :key="`message-list-key`" nav class="mt-2">
        <template v-for="(item, index) in messages">
          <v-list-item :key="index">
            <v-list-item>
              <v-alert :color="item.color" :type="item.type" dismissible>
                <div :style="item.to ? 'cursor: pointer; text-decoration: underline;' : ''" @click="goToMessage(item)">{{ item.text }}</div>
              </v-alert>
            </v-list-item>
          </v-list-item>
        </template>
      </v-list>
    </template>

    <!-- SideNav Menu -->
    <template v-if="currentOrganizationNameShort === ''">
      <v-list nav class="mt-2">
        <v-skeleton-loader class="mt-2" type="text" height="15" />
        <template v-for="(item, index) in menuBottom">
          <v-list-item :key="index" :href="item.href" :to="item.to" link>
            <v-list-item-icon style="min-width: 36px">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item>
              <v-list-item-content v-if="item.child === undefined">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item>
        </template>
      </v-list>
    </template>
    <template v-else>
      <template v-for="(menu, menuIndex) in menus">
        <v-divider v-if="menuIndex > 0" :key="`${menuIndex}-divider-key`"></v-divider>
        <v-list :key="`${menuIndex}-list-key`" nav class="mt-2">
          <template v-for="(item, index) in menu">
            <!-- if has children -->
            <v-list-group v-if="item.child !== undefined && item.child.length > 0" :key="index" :class="item.showChildren ? 'active-tile' : undefined" :value="item.showChildren">
              <template #activator>
                <v-list-item-icon style="min-width: 36px">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item :href="item.href" :to="item.to">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <v-list-item v-for="(subitem, i) in item.child" :key="i" :href="subitem.href" :to="subitem.to" link>
                <v-list-item-icon style="min-width: 36px">
                  <v-icon>{{ subitem.icon || mdiChevronDoubleRight }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ subitem.title }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
            <!-- else -->
            <v-list-item v-else :key="index" :href="item.href" :to="item.to" link>
              <v-list-item-icon style="min-width: 36px">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item>
                <v-list-item-content v-if="item.child === undefined">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item>
          </template>
        </v-list>
      </template>
    </template>
  </v-navigation-drawer>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  VAlert,
  VAutocomplete,
  VDivider,
  VIcon,
  VImg,
  VList,
  VListGroup,
  VListItem,
  VListItemAvatar,
  VListItemContent,
  VListItemIcon,
  VListItemSubtitle,
  VListItemTitle,
  VNavigationDrawer,
  VSkeletonLoader,
} from 'vuetify/lib';
import { mdiChevronDownCircle, mdiChevronDoubleRight, mdiChevronRight } from '@mdi/js';
import { getModule } from 'vuex-module-decorators';
import { NavModule, ProfileModule } from '@/store';
import { Organization, OrganizationConsoleSideNavSettings } from '@fgl/funfangle-sdk/dist/rest/profile';
import { AuthModule } from '@/shared/auth/auth-cognito';
import { LoginUtils } from '@/shared/auth/LoginUtils';
// import SideMenuHrComponent from './SideMenuHrComponent.vue';

interface MenuChild {
  href?: string;
  to?: string;
  title: string;
}

interface Message {
  color?: string;
  icon?: string;
  href?: string;
  to?: string;
  text?: string;
  type?: string;
}

@Component({
  components: {
    VAlert,
    VAutocomplete,
    VDivider,
    VIcon,
    VImg,
    VList,
    VListGroup,
    VListItem,
    VListItemAvatar,
    VListItemContent,
    VListItemIcon,
    VListItemSubtitle,
    VListItemTitle,
    VNavigationDrawer,
    VSkeletonLoader,
  },
})
export default class SideMenu extends Vue {
  mdiChevronDownCircle = mdiChevronDownCircle;
  mdiChevronDoubleRight = mdiChevronDoubleRight;
  mdiChevronRight = mdiChevronRight;

  @Prop({ default: false })
  showMenu!: boolean;
  menuShown = false;

  // @Prop({ default: false })
  // collapsed: Boolean = false;
  // Sidebar width (expanded)
  // @Prop({ default: '240px' })
  // width!: String;
  // Sidebar width (collapsed)
  // @Prop({ default: '50px' })
  // widthCollapsed!: String;

  mounted(): void {
    this.updateMenuShown();
  }

  // @Prop({ default: true })
  // showOneChild: Boolean = true;
  /*

    // Keep only one child opened at a time (first level only)
    showOneChild: {
      type: Boolean,
      default: false
    },

    // Keep all child open
    showChild: {
      type: Boolean,
      default: false
    },

    // Sidebar right to left
    rtl: {
      type: Boolean,
      default: false
    },

    // Make sidebar relative to the parent (by default the sidebar is relative to the viewport)
    relative: {
      type: Boolean,
      default: false
    },

    // Hide toggle collapse btn
    hideToggle: {
      type: Boolean,
      default: false
    },

    // Sidebar theme (available themes: 'white-theme')
    theme: {
      type: String,
      default: ''
    },

    // Disable hover on collapse mode
    disableHover: {
      type: Boolean,
      default: false
    }
  */

  selectedOrganization: Organization | null = null;
  showOrganizationSearch = false;

  authStore: AuthModule = getModule(AuthModule, this.$store);
  navStore: NavModule = getModule(NavModule, this.$store);
  profileStore: ProfileModule = getModule(ProfileModule, this.$store);

  /**
   * Menu Functions
   */

  get hasMenuConfig() {
    if (this.profileStore.currOrganization.consoleSettings && this.profileStore.currOrganization.consoleSettings.sidenav && this.profileStore.currOrganization.consoleSettings.sidenav.length > 0) {
      return true;
    }
    return false;
  }
  get menus(): any[][] {
    const arr: any[] = [];

    if (this.prefixOfUrl('/sys')) arr.push(this.menuOrganizationReturn);
    else arr.push(this.menuOrganizationActive);

    if (this.profileStore.isRoleSysAdmin) arr.push(this.menuSysAdmin);

    arr.push(this.menuBottom);
    return arr;
  }

  get menuOrganizationActive(): any[] {
    let menu: any[] = [];

    // console.log('menus orgType=', this.orgType);
    if (this.hasMenuConfig) {
      menu = this.menuOrganizationConfigured;
    } else if (this.orgType === 'rec-club') {
      menu = this.menuOrganizationActiveRecClub;
    } else if (this.orgType === 'camp') {
      menu = this.menuOrganizationActiveCamp;
    }
    return menu;
  }

  get menuOrganizationConfigured(): any[] {
    const menu: any[] = [];
    if (!this.profileStore.currOrganization.consoleSettings || !this.profileStore.currOrganization.consoleSettings.sidenav || this.profileStore.currOrganization.consoleSettings.sidenav.length < 1) {
      return [];
    }
    let nameIndex = 0;
    const nameMap = new Map<string, OrganizationConsoleSideNavSettings>();
    const nameOrder = new Map<string, number>();
    for (const item of this.profileStore.currOrganization.consoleSettings.sidenav) {
      if (item.name) {
        nameMap.set(item.name, item);
        nameOrder.set(item.name, nameIndex);
        nameIndex += 1;
      }
    }

    const menuRetailHome = nameMap.get('retail-home');
    if (menuRetailHome && menuRetailHome.display !== 'hidden') {
      menu.push({
        to: '/retail/home',
        title: menuRetailHome.label || 'Home',
        // icon: 'fas fa-user-friends',
        index: nameOrder.get('retail-home') || 0,
      });
    }

    const menuRetailEmployees = nameMap.get('retail-employees');
    if (menuRetailEmployees && menuRetailEmployees.display !== 'hidden') {
      menu.push({
        to: '/retail/employees',
        title: menuRetailEmployees.label || 'Staff',
        // icon: 'fas fa-user-friends',
        index: nameOrder.get('retail-employees') || 0,
      });
    }

    const menuRetailAccounts = nameMap.get('retail-accounts');
    if (menuRetailAccounts && menuRetailAccounts.display !== 'hidden') {
      menu.push({
        to: '/retail/accounts',
        title: menuRetailAccounts.label || 'Account Holders',
        // icon: 'fas fa-user-friends',
        index: nameOrder.get('retail-accounts') || 0,
      });
    }

    const menuRetailCampers = nameMap.get('retail-campers');
    if (menuRetailCampers && menuRetailCampers.display !== 'hidden') {
      menu.push({
        to: '/retail/campers',
        title: menuRetailCampers.label || 'Campers',
        // icon: 'fas fa-user-friends',
        index: nameOrder.get('retail-campers') || 0,
      });
    }

    const menuRetailTransactions = nameMap.get('retail-transactions');
    if (menuRetailTransactions && menuRetailTransactions.display !== 'hidden') {
      menu.push({
        to: '/retail/transactions',
        title: menuRetailTransactions.label || 'Transactions',
        // icon: 'fas fa-user-friends',
        index: nameOrder.get('retail-transactions') || 0,
      });
    }

    const menuRetailInventory = nameMap.get('retail-inventory');
    if (menuRetailInventory && menuRetailInventory.display !== 'hidden') {
      menu.push({
        to: '/retail/inventory/screen',
        title: menuRetailInventory.label || 'Inventory',
        // icon: 'fas fa-user-friends',
        index: nameOrder.get('retail-inventory') || 0,
      });
    }

    const menuRetailReports = nameMap.get('retail-reports');
    if (menuRetailReports && menuRetailReports.display !== 'hidden') {
      menu.push({
        to: '/retail/reports',
        title: menuRetailReports.label || 'Reports',
        // icon: 'fas fa-user-friends',
        index: nameOrder.get('retail-reports') || 0,
      });
    }

    const menuRetailSettings = nameMap.get('retail-settings');
    if (menuRetailSettings && menuRetailSettings.display !== 'hidden') {
      menu.push({
        to: '/retail/settings',
        title: menuRetailSettings.label || 'Settings',
        // icon: 'fas fa-user-friends',
        index: nameOrder.get('retail-settings') || 0,
      });
    }

    /**
     * Admissions Items
     */

    const menuDashboard = nameMap.get('dashboard');
    if (menuDashboard && menuDashboard.display !== 'hidden') {
      menu.push({
        to: '/dashboard',
        title: menuDashboard.label || 'Dashboard',
        // icon: 'fas fa-th-large',
        // showChildren: this.prefixOfUrl('/activity'),
        // child: [
        //   {
        //     to: '/activity/calendar/today',
        //     title: 'Today'
        //   }
        // ]
        index: nameOrder.get('dashboard') || 0,
      });
    }

    const menuActivities = nameMap.get('activities');
    if (menuActivities && menuActivities.display !== 'hidden') {
      menu.push({
        to: '/activity/list',
        title: menuActivities.label || 'Activities',
        // icon: 'far fa-calendar-alt',
        showChildren: this.prefixOfUrl('/activity'),
        child: [
          {
            to: '/activity/calendar/today',
            title: 'Today',
          },
        ],
        index: nameOrder.get('activities') || 0,
      });
    }

    const menuAccounts = nameMap.get('accounts');
    if (menuAccounts && menuAccounts.display !== 'hidden') {
      let memberChildren: MenuChild[] | undefined = undefined;

      const menuAccountVehicles = nameMap.get('account-vehicles');
      if (menuAccountVehicles && menuAccountVehicles.display !== 'hidden') {
        if (memberChildren === undefined) memberChildren = [];
        memberChildren.push({
          to: '/account/all/vehicles',
          title: menuAccountVehicles.label || 'Vehicles',
        });
      }
      menu.push({
        to: '/account/list',
        title: menuAccounts.label || 'Members',
        // icon: 'fas fa-user-friends',
        showChildren: this.prefixOfUrl('/account'),
        child: memberChildren,
        index: nameOrder.get('accounts') || 0,
        // child: [
        //   {
        //     href: '/accounts/staff',
        //     title: 'Staff'
        //   }
        // ]
        /*
      child: [
        {
          href: '/cashless/account/import',
          title: 'Import'
        }
      ]
      */
        // },
        // {
        //   href: '/settings',
        //   title: 'Settings',
        //   icon: 'fa fa-chart-area'
        //   /*
        //   child: [
        //     {
        //       href: '/cashless/account/import',
        //       title: 'Import'
        //     }
        //   ]
        //   */
      });
    }

    const menuLodgings = nameMap.get('lodgings');
    if (menuLodgings && menuLodgings.display !== 'hidden') {
      let lodgingChildren: MenuChild[] | undefined = undefined;

      const menuLodgingStays = nameMap.get('lodging-stays');
      if (menuLodgingStays && menuLodgingStays.display !== 'hidden') {
        if (lodgingChildren === undefined) lodgingChildren = [];
        lodgingChildren.push({
          to: '/lodging/all/stays',
          title: menuLodgingStays.label || 'Stays',
        });
      }
      menu.push({
        to: '/lodging',
        title: menuLodgings.label || 'Residences',
        // icon: 'fas fa-building',
        showChildren: this.prefixOfUrl('/lodging'),
        child: lodgingChildren,
        index: nameOrder.get('lodgings') || 0,
      });
    }
    // menu.push({
    //   href: '/reports',
    //   title: 'Reports',
    //   icon: 'fas fa-chart-bar'
    // });

    const menuTasks = nameMap.get('tasks');
    if (menuTasks && menuTasks.display !== 'hidden') {
      menu.push({
        to: '/tasks',
        title: menuTasks.label || 'Tasks',
        // icon: 'fas fa-clipboard-check',
        index: nameOrder.get('tasks') || 0,
      });
    }

    // if (this.navStore.displayTransactions) {
    //   menu.push({
    //     to: '/report/transaction/search',
    //     title: 'Search Transactions',
    //     icon: 'fas fa-th-large'
    //     // showChildren: this.prefixOfUrl('/activity'),
    //     // child: [
    //     //   {
    //     //     to: '/activity/calendar/today',
    //     //     title: 'Today'
    //     //   }
    //     // ]
    //   });
    // }

    const menuReports = nameMap.get('reports');
    if (menuReports && menuReports.display !== 'hidden') {
      const showChildren = false;
      const reportsChildren: MenuChild[] | undefined = undefined;
      // if (this.navStore.displayTransactions) {
      //   showChildren = true;
      //   if (reportsChildren === undefined) reportsChildren = [];
      //   reportsChildren.push({
      //     to: '/report/transactions',
      //     title: 'Transactions',
      //   });
      // }
      menu.push({
        to: '/report',
        title: menuReports.label || 'Reports',
        // icon: 'fas fa-file-csv',
        showChildren: showChildren && this.prefixOfUrl('/report'),
        child: reportsChildren,
        index: nameOrder.get('reports') || 0,
      });
    }

    menu.sort((a, b) => {
      if (a.index === undefined) return -1;
      if (b.index === undefined) return 1;
      return a.index - b.index;
    });

    return menu;
  }

  get menuOrganizationActiveCamp(): any[] {
    const menu: any[] = [];

    menu.push({
      to: '/retail/home',
      title: 'Home',
      // icon: 'fas fa-user-friends',
    });

    menu.push({
      to: '/retail/employees',
      title: 'Staff',
      // icon: 'fas fa-user-friends',
    });

    menu.push({
      to: '/retail/accounts',
      title: 'Account Holders',
      // icon: 'fas fa-user-friends',
    });

    menu.push({
      to: '/retail/campers',
      title: 'Campers',
      // icon: 'fas fa-user-friends',
    });

    menu.push({
      to: '/retail/transactions',
      title: 'Transactions',
      // icon: 'fas fa-user-friends',
    });

    menu.push({
      to: '/retail/inventory/screen',
      title: 'Inventory',
      // icon: 'fas fa-user-friends',
    });

    menu.push({
      to: '/retail/reports',
      title: 'Reports',
      // icon: 'fas fa-user-friends',
    });

    menu.push({
      to: '/retail/settings',
      title: 'Settings',
      // icon: 'fas fa-user-friends',
    });

    // menu.push({
    //   to: '/attendance',
    //   title: 'Attendance',
    //   icon: 'fas fa-user-friends',
    // });

    // menu.push({
    //   to: '/health',
    //   title: 'Health',
    //   icon: 'fas fa-user-friends',
    // });

    // menu = menu.concat(this.menuAdminMainReturn);

    // if (this.$route.path === '/') {
    //   menu.push({
    //     to: '/activities',
    //     title: 'Sessions',
    //     icon: 'fas fa-user-friends',
    //   });
    // }

    // if (this.prefixOfUrl('/accounts')) {
    //   menu.push({
    //     to: '/accounts',
    //     title: 'Families',
    //     icon: 'fas fa-user-friends',
    //   });
    // }

    // if (this.profileStore.organizationId === 'b0b7156e-d9d1-45c9-9144-c507ff9446fb') {
    //   menu.push({
    //     to: '/messages',
    //     title: 'Messages',
    //     icon: 'fas fa-user-friends',
    //   });
    // }

    // if (this.prefixOfUrl('/account/import/upload')) {
    //   menu.push({
    //     to: '/account/import/upload',
    //     title: 'Import',
    //     icon: 'fas fa-clipboard-check',
    //   });
    // }

    // if (this.navStore.displayTransactions) {
    //   menu.push({
    //     to: '/redirect/transactions',
    //     title: 'Transactions',
    //     icon: 'fas fa-clipboard-check',
    //   });
    // }
    // if (this.navStore.displayReports) {
    //   menu.push({
    //     to: '/redirect/reports',
    //     title: 'Reports',
    //     icon: 'fas fa-clipboard-check',
    //   });
    // }

    return menu;
  }

  get menuOrganizationActiveRecClub(): any[] {
    const menu: any[] = [];

    if (this.navStore.displayDashboard) {
      menu.push({
        to: '/dashboard',
        title: 'Dashboard',
        icon: 'fas fa-th-large',
        // showChildren: this.prefixOfUrl('/activity'),
        // child: [
        //   {
        //     to: '/activity/calendar/today',
        //     title: 'Today'
        //   }
        // ]
      });
    }

    menu.push({
      to: '/activity/list',
      title: 'Activities',
      icon: 'far fa-calendar-alt',
      showChildren: this.prefixOfUrl('/activity'),
      child: [
        {
          to: '/activity/calendar/today',
          title: 'Today',
        },
      ],
    });

    let memberChildren: MenuChild[] | undefined = undefined;
    if (this.navStore.displayVehicles) {
      if (memberChildren === undefined) memberChildren = [];
      memberChildren.push({
        to: '/account/all/vehicles',
        title: 'Vehicles',
      });
    }
    menu.push({
      to: '/account/list',
      title: 'Members',
      icon: 'fas fa-user-friends',
      showChildren: this.prefixOfUrl('/account'),
      child: memberChildren,
      // child: [
      //   {
      //     href: '/accounts/staff',
      //     title: 'Staff'
      //   }
      // ]
      /*
      child: [
        {
          href: '/cashless/account/import',
          title: 'Import'
        }
      ]
      */
      // },
      // {
      //   href: '/settings',
      //   title: 'Settings',
      //   icon: 'fa fa-chart-area'
      //   /*
      //   child: [
      //     {
      //       href: '/cashless/account/import',
      //       title: 'Import'
      //     }
      //   ]
      //   */
    });
    let lodgingChildren: MenuChild[] | undefined = undefined;
    if (this.navStore.displayLodgingStays) {
      if (lodgingChildren === undefined) lodgingChildren = [];
      lodgingChildren.push({
        to: '/lodging/all/stays',
        title: 'Stays',
      });
    }
    if (this.navStore.displayLodging) {
      menu.push({
        to: '/lodging',
        title: 'Residences',
        icon: 'fas fa-building',
        showChildren: this.prefixOfUrl('/lodging'),
        child: lodgingChildren,
      });
    }
    // menu.push({
    //   href: '/reports',
    //   title: 'Reports',
    //   icon: 'fas fa-chart-bar'
    // });
    if (this.navStore.displayTasks) {
      menu.push({
        to: '/tasks',
        title: 'Tasks',
        icon: 'fas fa-clipboard-check',
      });
    }

    // if (this.navStore.displayTransactions) {
    //   menu.push({
    //     to: '/report/transaction/search',
    //     title: 'Search Transactions',
    //     icon: 'fas fa-th-large'
    //     // showChildren: this.prefixOfUrl('/activity'),
    //     // child: [
    //     //   {
    //     //     to: '/activity/calendar/today',
    //     //     title: 'Today'
    //     //   }
    //     // ]
    //   });
    // }

    if (this.navStore.displayReports) {
      let showChildren = false;
      let reportsChildren: MenuChild[] | undefined = undefined;
      if (this.navStore.displayTransactions) {
        showChildren = true;
        if (reportsChildren === undefined) reportsChildren = [];
        reportsChildren.push({
          to: '/report/transactions',
          title: 'Transactions',
        });
      }
      menu.push({
        to: '/report',
        title: 'Reports',
        icon: 'fas fa-file-csv',
        showChildren: showChildren && this.prefixOfUrl('/report'),
        child: reportsChildren,
      });
    }

    return menu;
  }

  get menuBottom(): any[] {
    const menu: any[] = [];
    menu.push({
      to: '/signout',
      title: 'Signout',
      icon: 'fas fa-sign-out-alt',
    });
    // {
    //   href: `/go?url=${this.adminUrl}/organization-settings`,
    //   title: 'Settings',
    //   icon: 'fa fa-gear'
    // }
    /*
    {
      href: '/cashless/pointofsale',
      title: 'Point of Sale',
      icon: 'fa fa-chart-area',
      child: [
        {
          href: '/cashless/pointofsale/inventory',
          title: 'Inventory'
        }
      ]
    },
    */
    /*
    {
      href: '/cashless/reports/',
      title: 'Reports',
      icon: 'fa fa-chart-area',
      child: [
        {
          href: '/cashless/reports/ztape',
          title: 'Search'
        }
      ]
    }
    */
    // console.log('menu', menu);
    return menu;
  }

  get menuAdminMainReturn(): any[] {
    const menu: any[] = [];
    menu.push({
      href: this.profileStore.currOrganization.adminUrl,
      title: 'Return to Main',
      icon: 'fa fa-arrow-left',
    });
    return menu;
  }

  get menuOrganizationReturn(): any[] {
    const menu: any[] = [];
    menu.push({
      to: '/',
      title: 'Return to Organization',
      icon: 'fa fa-arrow-left',
    });
    return menu;
  }

  get menuSysAdmin(): any[] {
    const menu: any[] = [];
    menu.push({
      to: '/sys/organization/list',
      title: 'System',
      icon: 'fas fa-globe',
      showChildren: this.prefixOfUrl('/sys'),
      child: [
        {
          to: '/sys/reset',
          title: 'Reset',
        },
      ],
    });
    return menu;
  }

  updateMenuShown() {
    this.menuShown = this.showMenu || false;
  }

  @Watch('showMenu')
  onShowMenuChange() {
    this.updateMenuShown();
  }

  /**
   * Messages
   */

  get hasMessages(): boolean {
    return this.messages.length > 0;
  }

  get messages(): Message[] {
    const messages: Message[] = [];

    if (
      this.profileStore.currUser &&
      this.profileStore.currUser.role === 'organization-manager' &&
      this.profileStore.currOrganization !== undefined &&
      this.profileStore.currOrganization.squareSettings &&
      this.profileStore.currOrganization.squareSettings.permissionsUpdateRequired === true
    ) {
      messages.push({
        color: '#F9A825',
        text: 'Update Square Permissions',
        type: 'warning',
        to: 'settingsSquareAuthorize',
      });
    }

    return messages;
  }

  goToMessage(message: Message) {
    if (message.to) {
      this.$router.push({ name: message.to });
    }
  }

  /**
   * Organization Selector
   */

  get adminUrl() {
    if (!this.profileStore || this.profileStore === undefined || this.profileStore.currOrganization === undefined || this.profileStore.currOrganization.adminUrl === undefined) {
      return 'https://admin-portal.funfangle.com';
    }
    return this.profileStore.currOrganization.adminUrl;
  }

  get currentOrganizationNameShort() {
    const name = this.profileStore.currOrganization.organizationName;
    if (name === undefined) {
      return '';
    }
    let truncateLen = 26;
    if (this.organizations !== undefined && this.organizations.length > 1) {
      truncateLen = 20;
    }
    if (name.length > truncateLen) {
      return `${name.substring(0, truncateLen)}...`;
    }
    return name;
  }

  get items(): any[] {
    return [];
  }

  get organizations(): Organization[] {
    if (this.profileStore === undefined || this.profileStore.organizations === undefined) return [];
    return this.profileStore.organizations;
  }

  get orgType(): string {
    return this.profileStore.orgType;
  }

  pickOrg(index: number) {
    // console.log('pickOrg', index);
    this.profileStore.setCurrentByUsersIndex(index);
    if (this.profileStore.userIndex >= 0) {
      this.authStore.setUserId(this.profileStore.currUser.userId || 'UNSET');
      this.authStore.setOrganizationId(this.profileStore.currOrganization.organizationId || 'UNSET');
    }
    const utils = new LoginUtils(this.profileStore);
    // console.log('pickOrg redirect to', utils.getDomainHref(`https://${window.location.hostname}`, 'https://console.funfangle.com'));
    window.location.href = utils.getDomainHref(`https://${window.location.hostname}`, 'https://console.funfangle.com');
  }

  // get widthC() {
  //   return this.widthCollapsed;
  // }

  prefixOfUrl(url: string): boolean {
    return this.$route.path.indexOf(url) >= 0;
  }

  onToggleCollapse(e: boolean) {
    this.navStore.setSideToggleCollapse(e);
  }

  @Watch('selectedOrganization')
  onSelectedOrganizationChange(val?: Organization, oldVal?: Organization) {
    // console.log('selectedOrganization', val, oldVal);
    if (val === undefined || val == null || val.organizationId === undefined) return;
    const users = this.profileStore.users;
    let userIndex = 0;
    let found = false;
    while (userIndex < users.length && !found) {
      if (users[userIndex].organizationId === val.organizationId) {
        this.pickOrg(userIndex);
        found = true;
        this.showOrganizationSearch = false;
      }
      userIndex += 1;
    }
  }

  // mixins: [waves];
}
</script>

<style scoped>
.active-tile {
  background: white !important;
  /* background: #03a9f4 !important; */
  padding: 5px;
  border-radius: 4px;
}

.v-list--nav .v-list-item:not(:last-child):not(:only-child) {
  margin-top: 2px;
  margin-bottom: 2px;
}
</style>
