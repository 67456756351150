import Vue from 'vue';
import VueRouter from 'vue-router';
import { store } from '@/store/Store';
import { getModule } from 'vuex-module-decorators';
import { ProfileModule } from '@/store/ProfileModule';
import { AuthModule } from '../shared/auth/auth-cognito/AuthModule';
import { AuthCookies } from '../shared/auth/auth-cognito/AuthCookies';
import * as RouterNames from './RouterNames';
import { StoreUtils } from '@/store';

Vue.use(VueRouter);

const authStore = getModule(AuthModule, store);
const profileStore = getModule(ProfileModule, store);

const routes = [
  {
    path: '/',
    name: 'home',
    // component: Home
    component: () => import(/* webpackChunkName: "base" */ '@/views/Home.vue'),
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    // component: Home
    component: () => import(/* webpackChunkName: "base" */ '@/views/OnBoarding.vue'),
  },
  /* security routes */
  {
    path: '/signin',
    name: RouterNames.AUTH_LOGIN,
    component: () => import(/* webpackChunkName: "authx" */ '@/shared/auth/Login.vue'),
    meta: { guest: true },
  },
  {
    path: '/signout',
    name: RouterNames.AUTH_LOGOUT,
    component: () => import(/* webpackChunkName: "authx" */ '@/shared/auth/Logout.vue'),
    meta: { guest: true },
  },
  {
    path: '/start',
    name: RouterNames.AUTH_GETSTARTED,
    component: () => import(/* webpackChunkName: "authx" */ '@/shared/auth/GetStarted.vue'),
    meta: { guest: true },
  },
  {
    path: '/auth/newpassword',
    name: RouterNames.AUTH_NEWPASSWORD,
    component: () => import(/* webpackChunkName: "authx" */ '@/shared/auth/NewPassword.vue'),
    meta: { guest: true },
  },
  {
    path: '/auth/denied',
    name: RouterNames.AUTH_DENIED,
    component: () => import(/* webpackChunkName: "authx" */ '@/shared/auth/AccessDenied.vue'),
    meta: { guest: true },
  },
  {
    path: '/auth/recovery',
    name: RouterNames.AUTH_RECOVERY,
    component: () => import(/* webpackChunkName: "authx" */ '@/shared/auth/AccessRecovery.vue'),
    meta: { guest: true },
  },
  {
    path: '/auth/new',
    name: RouterNames.AUTH_CREATE,
    component: () => import(/* webpackChunkName: "authx" */ '@/shared/auth/NewAccount.vue'),
    meta: { guest: true },
  },
  {
    path: '/auth/request/invite',
    name: RouterNames.AUTH_INVITATION,
    component: () => import(/* webpackChunkName: "authx" */ '@/shared/auth/RequestInvitation.vue'),
    meta: { guest: true },
  },
  {
    path: '/auth/verify',
    name: RouterNames.AUTH_VERIFY,
    component: () => import(/* webpackChunkName: "authx" */ '@/shared/auth/AccessRecovery.vue'),
    meta: { guest: true },
  },
  {
    path: '/auth/select/organization',
    name: RouterNames.AUTH_SELECT_ORG,
    component: () => import(/* webpackChunkName: "authx" */ '@/shared/auth/SelectOrganization.vue'),
  },
  {
    path: '/account/all/vehicles',
    name: 'accountAllVehicles',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "settings" */ '@/views/console/account/AllVehicles.vue'),
  },
  {
    path: '/account/participant/:userId/:authId',
    name: 'accountParticipant',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "settings" */ '@/views/console/account/AccountParticipant.vue'),
  },
  {
    path: '/account/participant/:userId',
    name: 'accountParticipantList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "settings" */ '@/views/console/account/AccountParticipants.vue'),
  },
  {
    path: '/account/permissions/:userId',
    name: 'accountPermissions',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "settings" */ '@/views/console/account/AccountPermissions.vue'),
  },
  {
    path: '/account/bookings/:userId',
    name: 'accountBookings',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/console/account/Bookings.vue'),
  },
  {
    path: '/account/general/:userId',
    name: 'accountGeneral',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "settings" */ '@/views/console/account/Account.vue'),
  },
  {
    path: '/account/identifiers',
    name: 'accountIdentifiers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "settings" */ '@/views/console/identifier/Identifiers.vue'),
  },
  {
    path: '/account/new',
    name: 'accountInvite',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "settings" */ '@/views/console/account/AccountInvite.vue'),
  },
  {
    path: '/account/summary/:userId',
    name: 'accountSummary',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "settings" */ '@/views/console/account/AccountSummary.vue'),
  },
  {
    path: '/account/list',
    name: 'accounts',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "settings" */ '@/views/console/account/Accounts.vue'),
  },
  {
    path: '/accounts',
    name: 'accountsOld1',
    redirect: { name: 'accounts' },
  },
  {
    path: '/activate/:userId/:parentNum',
    name: RouterNames.AUTH_ACTIVATE,
    component: () => import(/* webpackChunkName: "activate" */ '@/shared/auth/Activate.vue'),
    meta: { guest: true },
  },
  {
    path: '/activate/activate.html',
    name: RouterNames.AUTH_ACTIVATE_QS,
    component: () => import(/* webpackChunkName: "activate" */ '@/shared/auth/Activate.vue'),
    meta: { guest: true },
  },
  {
    path: '/activity/calendar/:date',
    name: 'activityCalendarToday',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "profile" */ '@/views/console/session/ActivitySchedule.vue'),
  },
  {
    path: '/activity/reservation/account',
    name: RouterNames.ACTIVITY_BOOKING_HOUSEHOLD,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "profile" */ '@/views/console/session/ActivityBookingHousehold.vue'),
  },
  {
    path: '/activity/reservation/make',
    name: RouterNames.ACTIVITY_BOOKING_MAKE,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "profile" */ '@/views/console/session/ActivityBookingMaker.vue'),
  },
  {
    path: '/activity/attendance/:sessionId/:eventId/:startSeconds',
    name: 'activityAttendance',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/console/session/ActivityAttendance.vue'),
  },
  {
    path: '/activity/config/:sessionId',
    name: 'activityConfig',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "profile" */ '@/views/console/session/config/ConfigSession.vue'),
  },
  {
    path: '/activity/participants/:sessionId/:eventId',
    name: 'activityParticipants',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/console/session/ActivityParticipants.vue'),
  },
  {
    path: '/activity/list',
    name: 'activities',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "profile" */ '@/views/console/session/MainList.vue'),
  },
  {
    path: '/activity/scheduler/:sessionId',
    name: 'activityEditor',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "profile" */ '@/views/console/session/ActivityEditor.vue'),
  },
  {
    path: '/activity/times/:sessionId',
    name: 'activityViewer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "profile" */ '@/views/console/session/ActivityTimes.vue'),
  },
  {
    path: '/account/import',
    name: 'accountImportStatus',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "pointofsale" */ '@/views/console/import/ImportAccountStatus.vue'),
  },
  {
    path: '/account/import/upload',
    name: 'accountImportUpload',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "pointofsale" */ '@/views/console/import/ImportAccountUpload.vue'),
  },
  /** Cashless Routes */
  {
    path: '/cashless',
    name: 'cashless',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "pointofsale" */ '@/views/console/pointofsale/HomePOS.vue'),
  },
  /** Cashless Routes */
  {
    path: '/dashboard',
    name: 'dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "pointofsale" */ '@/views/console/dashboard/Dashboard.vue'),
  },
  {
    path: '/inventory/item/catalog',
    name: 'inventoryList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "inventory" */ '@/views/console/inventory/Catalog.vue'),
  },
  {
    path: '/retail/inventory/catalog',
    name: 'retailInventoryCatalog',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "inventory" */ '@/views/console/inventory/Catalog.vue'),
  },
  {
    path: '/inventory/item/:itemId',
    name: 'inventoryItem',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "inventory" */ '@/views/console/inventory/InventoryItem.vue'),
  },
  {
    path: '/retail/inventory/item/:itemId',
    name: 'retailInventoryItem',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "inventory" */ '@/views/console/inventory/InventoryItem.vue'),
  },
  {
    path: '/inventory/lots',
    name: 'inventoryLots',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "inventory" */ '@/views/console/inventory/Lots.vue'),
  },
  {
    path: '/retail/inventory/histories',
    name: 'retailInventoryHistories',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "inventory" */ '@/views/console/inventory/InventoryHistories.vue'),
  },
  {
    path: '/retail/inventory/lots/:itemId',
    name: 'retailInventoryLots',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "inventory" */ '@/views/console/inventory/InventoryLots.vue'),
  },
  {
    path: '/retail/inventory/purchasedetail/:purchaseId/:lineno',
    name: 'retailInventoryPurchaseDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "inventory" */ '@/views/console/inventory/PurchaseDetailItem.vue'),
  },
  {
    path: '/retail/inventory/purchasedetails/:purchaseId',
    name: 'retailInventoryPurchaseDetails',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "inventory" */ '@/views/console/inventory/PurchaseDetailList.vue'),
  },
  {
    path: '/inventory/purchaseorders',
    name: 'inventoryPurchaseOrders',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "inventory" */ '@/views/console/inventory/PurchaseOrderList.vue'),
  },
  {
    path: '/retail/inventory/purchaseorder/:purchaseId',
    name: 'retailInventoryPurchaseOrder',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "inventory" */ '@/views/console/inventory/PurchaseOrderItem.vue'),
  },
  {
    path: '/retail/inventory/purchaseorders',
    name: 'retailInventoryPurchaseOrders',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "inventory" */ '@/views/console/inventory/PurchaseOrderList.vue'),
  },
  {
    path: '/retail/inventory/vendor/:vendorId',
    name: 'retailInventoryVendor',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "inventory" */ '@/views/console/inventory/VendorItem.vue'),
  },
  {
    path: '/retail/inventory/vendors',
    name: 'retailInventoryVendors',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "inventory" */ '@/views/console/inventory/VendorList.vue'),
  },
  {
    path: '/inventory/square/sync',
    name: 'inventorySquareSync',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "inventory" */ '@/views/console/inventory/InventorySquareSync.vue'),
  },
  {
    path: '/invoice/list',
    name: RouterNames.INVOICE_LIST,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "profile" */ '@/views/console/invoice/InvoiceList.vue'),
  },
  {
    path: '/invoice/pay/:invoiceId',
    name: RouterNames.INVOICE_MAKE_PAYMENT,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "profile" */ '@/views/console/invoice/PayInvoice.vue'),
  },
  {
    path: '/lodging',
    name: 'lodgingList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "lodgings" */ '@/views/console/lodging/Lodgings.vue'),
  },
  {
    path: '/lodging/all/stays',
    name: RouterNames.LODGING_ALL_STAYS,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "lodgings" */ '@/views/console/lodging/OrganizationStays.vue'),
  },
  {
    path: '/lodging/unit/details/:lodgingId',
    name: RouterNames.LODGING_UNIT_DETAILS,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "lodgings" */ '@/views/console/lodging/LodgingUnitBookings.vue'),
  },
  {
    path: '/lodging/unit/bookings/:lodgingId',
    name: RouterNames.LODGING_UNIT_BOOKINGS,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "lodgings" */ '@/views/console/lodging/LodgingUnitBookings.vue'),
  },
  {
    path: '/lodging/unit/stays/:lodgingId',
    name: RouterNames.LODGING_UNIT_STAYS,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "lodgings" */ '@/views/console/lodging/LodgingUnitStays.vue'),
  },
  {
    path: '/lodging/unit/users/:lodgingId',
    name: RouterNames.LODGING_UNIT_USERS,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "lodgings" */ '@/views/console/lodging/LodgingUnitUsers.vue'),
  },
  {
    path: '/profile',
    name: 'profile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "profile" */ '@/views/console/profile/HomeProfile.vue'),
  },
  {
    path: '/report/mealorder/:date',
    name: 'reportMealOrderToday',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "report" */ '@/views/console/report/mealorder/DailyMealOrderReport.vue'),
  },
  {
    path: '/report/offeringsheet',
    name: 'reportParticipantOfferingSheet',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "report" */ '@/views/console/report/mealorder/RemainingBalancesOfferingSheet.vue'),
  },
  {
    path: '/report/transactions',
    name: 'reportTransactions',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "report" */ '@/views/console/report/transaction/Transactions.vue'),
  },
  {
    path: '/session/list',
    name: 'sessions',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "session" */ '@/views/console/session/SessionList.vue'),
  },
  {
    path: '/session/editor',
    name: 'sessionEditor',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "session" */ '@/views/console/session/SessionEditor.vue'),
  },
  {
    path: '/session/details/:sessionId',
    name: 'session',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "session" */ '@/views/console/session/SessionViewer.vue'),
  },
  {
    path: '/setting',
    name: RouterNames.SETTINGS,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "setting" */ '@/views/console/setting/HomeSettings.vue'),
  },
  {
    path: '/setting/config-all',
    name: RouterNames.SETTING_CONFIG_ALL,
    component: () => import(/* webpackChunkName: "setting" */ '@/views/console/setting/config/ConfigAll.vue'),
  },
  {
    path: '/setting/credentials',
    name: RouterNames.ACCOUNT_CREDENTIALS,
    component: () => import(/* webpackChunkName: "setting" */ '@/shared/account/credentials/MainMenu.vue'),
    meta: {
      backbar: true,
      footerMenu: 'home',
    },
  },
  {
    path: '/setting/logo',
    name: RouterNames.SETTING_LOGO,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "setting" */ '@/views/console/setting/logo/LogoUploadMain.vue'),
  },
  {
    path: '/setting/processor/square/oauth',
    name: 'settingsSquareAuthorize',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "setting" */ '@/views/console/setting/processor/SquareAuthorize.vue'),
  },
  {
    path: '/setting/processor/square/locations',
    name: 'settingsSquareLocations',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "setting" */ '@/views/console/setting/processor/SquareLocations.vue'),
  },
  {
    path: '/setting/waiver',
    name: RouterNames.SETTING_WAIVER,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "setting" */ '@/views/console/setting/waiver/WaiverUploadMain.vue'),
  },
  {
    path: '/redirect/:slug',
    name: 'redirect',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "base" */ '@/views/console/Redirect.vue'),
  },
  {
    path: '/sys/organization/add',
    name: RouterNames.SYS_ORGANIZATION_ADD,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "syscon" */ '@/views/syscon/organizations/AddOrganization.vue'),
  },
  {
    path: '/sys/organization/list',
    name: RouterNames.SYS_ORGANIZATION_LIST,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "syscon" */ '@/views/syscon/organizations/OrganizationList.vue'),
  },
  {
    path: '/sys/reset',
    name: RouterNames.SYS_RESET,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "syscon" */ '@/views/syscon/reset/MainReset.vue'),
  },
  {
    path: '/tasks',
    name: RouterNames.TASKS,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tasks" */ '@/views/console/task/TasksMain.vue'),
  },
  {
    path: '/task/ticket/:mode/:ticketId',
    name: RouterNames.TASK_ACTION,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tasks" */ '@/views/console/task/TaskAction.vue'),
  },
  {
    path: '/welcome',
    name: 'welcome',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "base" */ '@/views/console/welcome/HomeWelcome.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

/*
 * Process before each router
 */
router.beforeEach(async (to, from, next) => {
  const containsIgnoredRoutes = false;

  // debug
  // console.log('route', to, from, next)
  // console.log('location', window.location.hostname, window.location)

  // check if a "guest" path
  if (to.matched.some((record) => record.meta.guest)) {
    // console.log('router: beforeEach guest ', to)
    // console.log.log('router: guest...');
    next();
  } else if (!containsIgnoredRoutes) {
    // check for token (jwt)
    // if not available, then attempt refresh
    // if still not available, then redirect to login
    // otherwise, pass-thru
    let idToken = authStore.userIdToken;
    if (!idToken || idToken === '' || idToken === 'undefined' || idToken === 'null') {
      // refresh the token
      await authStore
        .refreshToken()
        .then(async () => {
          // save token
          idToken = authStore.userIdToken;
        })
        .catch(() => {
          // if token refresh was not possible, then so be it
        });
      // reload data if needed
      if (idToken && idToken !== '' && idToken !== undefined && idToken !== 'undefined' && idToken !== 'null') {
        // console.log('fetch for user ', authStore.username);
        await new StoreUtils().onLoginActions(authStore.username).catch(() => {
          // something
        });
      }
    }

    // console.log.log('idToken ', idToken);
    if ((!idToken || idToken === '' || idToken === 'undefined' || idToken === 'null') && !containsIgnoredRoutes) {
      // redirect to login page
      // console.log.log('router: jwt not found. redirect to auth_login, query r:', to.name);
      next({ name: RouterNames.AUTH_LOGIN, query: { r: to.name } });
    } else {
      // user is logged in
      // so, now get user
      // check if logged in
      const username = authStore.username;
      // console.log('username ', username);
      if (!username || username === '') {
        // console.log.log('router: no username. redirect to auth ');
        next({
          name: RouterNames.AUTH_LOGIN,
          query: { r: to.name },
        });
      }

      // redirect if not an admin of some sort
      if (
        profileStore.currUser === undefined ||
        (profileStore.currUser.role !== 'organization-manager' &&
          profileStore.currUser.role !== 'organization-employee' &&
          profileStore.currUser.role !== 'employee' &&
          profileStore.currUser.role !== 'store-manager')
      ) {
        // console.log('router.beforeEach | ', profileStore.currUser.role, ' redirect to ', profileStore.currOrganization.domainUrl || 'https://portal.funfangle.com');
        window.location.href = profileStore.currOrganization.domainUrl || 'https://portal.funfangle.com';
        AuthCookies.resetCookies();
        authStore.init();
        return;
      }

      // console.log('router: get user')
      // let user = JSON.parse(localStorage.getItem('user'));
      if (to.matched.some((record) => record.meta.is_admin)) {
        //if (user.is_admin === 1) {
        // page requires admin and user admin so proceed
        // console.log('router.beforeEach next() #1')
        //  next();
        //} else {
        // console.log.log('router: user is not admin. deny.');
        next({ name: RouterNames.AUTH_DENIED });
        //}
      } else {
        // page does not require admin so proceed
        // console.log.log('router.beforeEach next() #2');
        next();
      }
    }
  }
  // console.log('router.beforeEach end')
});

/**
 * Hook after each route.
 */
//router.afterEach((to, from, next) => {
//  // console.log('router:afterEach ', to, from)
//});

// helper functions
/* eslint-disable */
/*
function setCookie(cname, cvalue, exseconds) {
  var d = new Date()
  d.setTime(d.getTime() + (exseconds * 1000))
  var expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

function getParameterByName(name, url) {
  if (!url) url = window.location.href
  name = name.replace(/[\[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}
*/
export default router;
