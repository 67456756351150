// import { ApiEndpoints } from '../constants';
import { store } from '@/store/Store';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { getModule } from 'vuex-module-decorators';
import { NavModule } from './NavModule';
import { ProfileModule } from './ProfileModule';
import { Location } from '@fgl/funfangle-sdk/dist/rest/profile';

@Module({ dynamic: true, store, name: 'location' })
export class LocationModule extends VuexModule {
  profileStore: ProfileModule = getModule(ProfileModule);
  navStore: NavModule = getModule(NavModule);

  _locations: Location[] | null | undefined;

  get countLocations(): number {
    return this._locations ? this._locations.length : 0;
  }

  get locations(): Location[] | null | undefined {
    return this._locations;
  }

  @Mutation
  public clearLocations(): void {
    this._locations = [];
  }

  @Mutation
  public setLocations(locations: Location[]): void {
    this._locations = locations;
  }
}
